import React from 'react';
import styled from 'styled-components/macro';
import RawHeader from './header';
import RawSidebar from './sidebar';
import Modal from './modal';
import Popup from './popup';
import Navigation from './navigation';

const LayoutContainer = styled.div`
    position: relative;
    width: 100vw;
    min-height: 100vh;
    display: flex;
`;

interface NavigationProps {
    showNavigation: boolean;
}

const Content = styled.div`
    flex: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const MainContent = styled.div<NavigationProps>`
    flex; 1;
    min-height: ${({ theme }) => `calc(100vh - ${theme.layout.headerHeight} - 1rem)`};
    //height: 100%;
    background-color: ${({ theme }) => theme.colors.uiColor1};
    border-top-left-radius: ${({ showNavigation }) => showNavigation ? '1rem' : '0rem'};
    border-bottom-left-radius: ${({ showNavigation }) => showNavigation ? '1rem' : '0rem'};
    transition: all 0.25s ${({ theme }) => theme.transitions.main};
`;

const Header = styled(RawHeader)`
    background-color: ${({ theme }) => theme.colors.uiColor1};
`;

const Sidebar = styled(RawSidebar) <NavigationProps>`
    background-color: ${({ theme }) => theme.colors.uiColor1};
    position: sticky;
    top: 0;
    width: 100%;
    height: 100vh;
    border-right: 1px solid ${({ theme }) => theme.colors.uiColor3};
    max-width: ${({ showNavigation }) => showNavigation ? '16.5rem' : '5.25rem'};
    //z-index: 10;
    
    transition: all 0.25s ${({ theme }) => theme.transitions.main};
    
    ${({ theme }) => theme.media.small} {
        display: none;
    }
`;

/* const MobileSideBarContainer = styled.div<NavigationProps>`
    display: block;
    width: 50%;
    height: 100vh;
    max-width: ${({ showNavigation }) => showNavigation ? '100%' : '0%'};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    background-color: ${({ theme }) => theme.colors.evenDarkerBackgroundGray};
    transition: all .6s ease;
`;

const CloseSideBar = styled.h1<NavigationProps>`
    display: ${({ showNavigation }) => showNavigation ? 'block' : 'none'};
    position: fixed;
    top: 1%;
    left: 45%;
    cursor: pointer;
    color: white;
    opacity: 0;
    animation: ${closeFade} .5s ease forwards;
    animation-delay: .5s;
`;

const NavBar = styled.nav`
    position: relative;
    height: 70%;
    top: 45%;
    transform: translateY(-50%);
    text-align: center;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface ButtonProps {
    enabled?: boolean;
}


const NavLink = styled.a<ButtonProps>`
    display:  ${({ enabled }) => enabled ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    width: 100%;
    
    cursor: pointer;
`;

const NavButton = styled.div`
    display: grid;
    grid-template-columns: 2rem 6rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    background-color: transparent;
    opacity: 0;
    animation: ${fadeInRight} .5s ease forwards;
    animation-delay: .5s;

    &:hover {
        background-color: #707070;
    }

    &:active {
        background-color: #707070;
    }
     & span {
        text-align: left;
     }


    padding: 1rem;

    & svg {
        height: 1.25rem;
        width: 1.25rem;
    }
`;

const HeroModal = styled.div<{ enabled: boolean }>`
font-size: 1rem;;
--animation-duration: 300ms;
font-weight: 400;
box-sizing: border-box;
transition: opacity 0.1s ease-in-out 0s;
text-align: center;
position: fixed;
width: 100vw;;
height: 100vh;
margin-left: -50vw;
top: 0px;
left: 50%;
z-index: 10;
will-change: opacity;
background: rgba(11, 14, 19, 0.69);
backdrop-filter: blur(11px);
opacity: ${({ enabled }) => enabled ? '1' : '0'};
visibility: visible;
pointer-events: auto;
display: ${({ enabled }) => enabled ? 'flex' : 'none'};
-webkit-box-pack: center;
justify-content: center;
-webkit-box-align: center;
align-items: center;

` */

interface Props {
    children: React.ReactNode;
}

interface State {
    showNavigation: boolean;
    showMobileNavigation: boolean;
    modal: boolean;
    popup: boolean;
    playani: boolean;
}

export default class Layout extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            showNavigation: document.documentElement.clientWidth > 1350,
            showMobileNavigation: false,
            modal: false,
            popup: false,
            playani: false
        }
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.handleResize)
        const visited = localStorage.getItem('visited')
        if (visited !== 'true') {
            this.setState({ popup: true });
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleResize);

    }

    handleResize = () => {
        const width = document.documentElement.clientWidth;
        if (width < 1350) {
            if (this.state.showNavigation) {
                this.setState({ showNavigation: false });
                this.setState(prevState => ({
                    playani: !prevState.playani,
                }));

            }
        } else {
            if (!this.state.showNavigation) {
                this.setState({ showNavigation: true });
                this.setState(prevState => ({
                    playani: !prevState.playani,
                }));
            }
        }
        if (width > 1080) {
            if (this.state.showMobileNavigation) {
                this.handleMobileNavigation()
            }
        }

    }

    handleModal = () => {
        if (this.state.modal) {
            this.setState({ modal: false });
        }
        else {
            this.setState({ modal: true });
        }
    }

    handlePopup = () => {
        if (this.state.popup) {
            this.setState({ popup: false });
            localStorage.setItem('visited', JSON.stringify(true))
        }
        else {
            this.setState({ popup: true });
        }
    }

    handleNavigation = () => {

        const width = document.documentElement.clientWidth;
        if (width < 1080) {
            this.setState(
                prevState => ({
                    showMobileNavigation: !prevState.showMobileNavigation
                })
                , () => {
                    if (this.state.showNavigation) {
                        document.body.style.overflowY = 'hidden';
                    } else {
                        document.body.style.overflowY = 'scroll';
                    }
                }
            )
        }

        this.setState(
            prevState => ({
                showNavigation: !prevState.showNavigation,
            })
            , () => {
                /*                 if (this.state.showNavigation) {
                                    document.body.style.overflowY = 'hidden';
                                } else {
                                    document.body.style.overflowY = 'scroll';
                                } */
            }
        )
    }

    handleMobileNavigation = () => {

        this.setState(
            prevState => ({
                showMobileNavigation: !prevState.showMobileNavigation
            })
            , () => {
                if (this.state.showNavigation) {
                    document.body.style.overflowY = 'hidden';
                } else {
                    document.body.style.overflowY = 'scroll';
                }
            }
        )
    }

    closeMobileNavigation = () => {
        this.setState({ showMobileNavigation: false });
    }

    render() {

        const { children } = this.props;
        const { showNavigation } = this.state;
        const { showMobileNavigation } = this.state;
        const { modal } = this.state;
        const { popup } = this.state;

        return (
            <LayoutContainer>
                <Popup enabled={popup} handlePopup={this.handlePopup}></Popup>
                <Modal enabled={modal} handleModal={this.handleModal}></Modal>
                <Sidebar
                    showNavigation={showNavigation}
                    handleNavigation={this.handleNavigation}
                    enabled={showNavigation}
                    playani={this.state.playani}
                />

                <Content>
                    <Header
                        enabled={showMobileNavigation}
                        handleNavigation={this.handleNavigation}
                        handleModal={this.handleModal}
                    />
                    <Navigation
                        enabled={this.state.showMobileNavigation}
                        handleClose={this.handleMobileNavigation}
                        handleModal={this.handleModal}
                    />

                    <MainContent showNavigation={showNavigation}>
                        {children}
                    </MainContent>
                    <div />
                </Content>
            </LayoutContainer>
        )
    }
}