import * as React from 'react';

type Props = {
    timestamp: number;
}

type State = {
    createdAt: string;
}

export default class Timestamp extends React.Component<Props, State> {
    private interval: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            createdAt: this.getCreatedAtString(props.timestamp)
        }
    }
    componentDidMount = () => {
        this.interval = setInterval(this.setCreatedAtString, 60000);
    }

    componentWillUnmount = () => {
        clearInterval(this.interval);
    }

    setCreatedAtString = () => {
        this.setState({ createdAt: this.getCreatedAtString(this.props.timestamp) });
    }

    getCreatedAtString = (unix?: number): string => {
        if (!unix) {
            return '';
        }
        const old = new Date(unix * 1000);
        const now = new Date();
        var seconds = Math.floor((now.getTime() - old.getTime()) / 1000);
        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            const amount = Math.floor(interval);
            return `${amount} hour${amount === 1 ? '' : 's'}`;
        }
        interval = seconds / 60;
        if (interval > 1) {
            const amount = Math.floor(interval);
            return `${amount} min${amount === 1 ? '' : ''}`;
        } else {
            return `just now`;
        }
    }

    render() {
        return (
            <span>{this.state.createdAt}</span>
        )
    }
}