import { combineEpics } from 'redux-observable';

import * as pairsEpics from './pairs/epics';
import * as walletEpics from './wallet/epics';
import * as interfaceEpics from './interface/epics';
import * as analyzerEpics from './analyze/epics';
//import * as socketEpics from './socket/epics';

export default combineEpics(
    ...Object.values(analyzerEpics),
    ...Object.values(pairsEpics),
    ...Object.values(walletEpics),
    ...Object.values(interfaceEpics),
    //...Object.values(socketEpics),
);