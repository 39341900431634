import React from 'react';
import styled from 'styled-components/macro';
import BN from 'bn.js';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions'
import {
    fromWeiCustomDecimals,
    toWeiCustomDecimals,
    simplify,
} from '../../../utils/numbers';

import { BUSDAddress, WBNBAddress } from '../../../constants/addresses';
import * as walletActions from '../../../store/wallet/actions';
import { preferredNetwork } from '../../../constants/network';
import TokenInput from './token-input';

import { ReactComponent as Refresh } from '../../svg/refresh.svg';
import { ReactComponent as Card } from '../../svg/creditcard.svg';
import { ReactComponent as SellCard } from '../../svg/creditcard.svg';
import { ReactComponent as Verified } from '../../svg/verified.svg';
import { ReactComponent as SettingsWheel } from '../../svg/settings.svg';
import { ReactComponent as XIcon } from '../../svg/x.svg';

import SlippageInput from './slippage-input';
import Rocketani from '../../rocketani';
import { PairInterface } from '../../../types/pairs';

const SwapContainer = styled.div`
    display: grid;
    grid-gap: 1.5rem;
`;

const LoadingContainer = styled.div<{ enabled: boolean }>`
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(0) !important;
    opacity: 1 !important;
    pointer-events: auto !important;

    & span {
        position: absolute;
        bottom: 3rem;
        font-size: 1.1rem;
        font-weight: 500;
    }
`;

const LoadingHeader = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.uiColor2};
    transition: border-bottom 0.2s ease;
`;

const LoadingBody = styled.div<{ center: boolean; }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: ${({ center }) => center ? 'flex-start' : 'center'};
    align-items: center; 
`;

const ErrorSpan = styled.div`
    margin: 0;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.triggerColor1};
`;

interface TriggerProps {
    trigger?: boolean;
    enabled?: boolean;
    error?: boolean;
}

const TradeContainer = styled.div<TriggerProps>`
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 1.5rem;
    border-radius: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    ${({ theme, enabled, error }) => enabled && `
        & > * {
            transition: all 0.2s ${theme.transitions.main};
            filter: blur(5px);
            opacity: ${error ? '0.2' : '0.35'};
            pointer-events: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none; 
            -khtml-user-select: none; 
              -moz-user-select: none; 
               -ms-user-select: none; 
                   user-select: none;
        }
    `}
    transition: all 0.2s ${({ theme }) => theme.transitions.main};
    //border: ${({ theme, trigger }) => `1px solid ${trigger ? theme.colors.uiColor5 : theme.colors.uiColor2}`};
    box-shadow: ${({ theme, trigger }) => `${trigger ? theme.colors.shadow1 : "none"}`};
    transition: box-shadow 0.4s ease;
`;

const SwapHeader = styled.div<TriggerProps>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.uiColor2};
    transition: border-bottom 0.2s ease;
`;

const HeaderTitle = styled.div`
    font-weight: 500;
    font-size: 1.25rem;
    height: 1.5rem;
    line-height: 1.5rem;
`;

const BalanceContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.uiColor2};
    padding: 0.375rem 0.563rem;
    border-radius: 0.25rem;
`;

const RefreshButton = styled.div`
    cursor: pointer;
    & svg {
        height: 0.8rem;
        width: 0.85rem;
    }
    height: 1rem;
    margin-top: 0.2rem;
`;

const TokenInputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const SlippageInputContainer = styled.div`
    position: absolute;
    padding: 1.5rem;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & span {
        font-size: 0.9rem;
    }
`;

const HideContainer = styled.div<{ enabled: boolean }>`
    width: 100%;
    opacity: ${({ enabled }) => enabled ? 0 : 1};
    pointer-events: ${({ enabled }) => enabled ? 'none' : 'default'};
    //transition: all 0.2s ${({ theme }) => theme.transitions.main};
`;

const CancelButtonContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.uiColor2};
    padding: 0.375rem 0.563rem;
    border-radius: 0.25rem;
    cursor: pointer;
`;

const CancelButton = styled.div`
    & svg {
        height: 0.8rem;
        width: 0.85rem;
    }
    height: 1rem;
    margin-top: 0.2rem;
`;

const CallToAction = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    & > * {
        padding: 0 1.5rem;
    }
`;

const SlippageCallToAction = styled.div<{ enabled: boolean }>`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: ${({ enabled }) => enabled ? 1 : 0};
    pointer-events: ${({ enabled }) => enabled ? 'default' : 'none'};
`;

const SlippageBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.8rem;
`;

const SlippageTitle = styled.div`
    display: flex;
    align-items: center;

    & span {
        font-size: 0.8rem;
        font-weight: 450;
        margin-right: 0.5rem;
    }
`;

const SlippageButton = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.75rem;
    background-color: ${({ theme }) => theme.colors.uiColor2};
    padding: 0.375rem 0.563rem;
    border-radius: 0.25rem;
    cursor: pointer;
    text-transform: uppercase;

    & svg {
        margin-left: 0.5rem;
        color: ${({ theme }) => theme.colors.brandColor1};
    }
`;

const BuyButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
`;

interface BuyButtonProps {
    enabled?: boolean;
    approved?: boolean
}

const BuyButton = styled.div<BuyButtonProps>`
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: center;
   background-color: ${({ enabled, theme }) => enabled ? theme.colors.brandColor1 : theme.colors.uiColor2};
   border-radius: 0.5rem;
   padding: 0.875rem 1.5rem 0.875rem 1.5rem;
   margin-top: 1.5rem;
   color: ${({ theme }) => theme.colors.uiColor1};
   font-weight: 500;
   font-size: 0.875rem;
   text-transform: uppercase;
   & svg {
       margin-right: 0.625rem;
   }
   cursor: ${({ enabled }) => enabled ? 'pointer' : 'default'};
   transition: all 0.2s ${({ theme }) => theme.transitions.main};
   ${({ enabled }) => enabled && `
    &:active {
        transform: translateY(2px);
    }
   `}
}
`;


const ApproveBuyButton = styled.div<BuyButtonProps>`
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: center;
   background-color: ${({ enabled, approved, theme }) => approved ? theme.colors.uiColor1 : enabled ? theme.colors.brandColor1 : theme.colors.uiColor2};
   border-radius: 0.5rem;
   padding: 0.875rem 1.5rem 0.875rem 1.5rem;
   margin-top: 1.5rem;
   color: ${({ approved, theme }) => approved ? theme.colors.triggerColor5 : theme.colors.uiColor1};
   //color: ${({ theme }) => theme.colors.uiColor1};
   border: ${({ approved, theme }) => approved ? `1px solid ${theme.colors.triggerColor5}` : 'none'};
   font-weight: 500;
   font-size: 0.875rem;
   text-transform: uppercase;
   & svg {
       margin-right: 0.625rem;
   }
   cursor: ${({ enabled }) => enabled ? 'pointer' : 'default'};
   transition: all 0.2s ${({ theme }) => theme.transitions.main};
   ${({ enabled }) => enabled && `
    &:active {
        transform: translateY(2px);
    }
   `}
}
`;

const dispatchProps = {
    getBalance: walletActions.getBalance.request,
    getTokenBalance: walletActions.getTokenBalance.request,
    getTokenAllowance: walletActions.getTokenAllowance.request,
    connectWallet: walletActions.connectWallet.request,
    disconnectWallet: walletActions.disconnectWallet.request,
    switchNetwork: walletActions.switchNetwork.request,
    buy: walletActions.buy.request,
    sell: walletActions.sell.request,
    approve: walletActions.approve.request,
    clearBuyError: walletActions.clearBuyError,
    clearSellError: walletActions.clearSellError,
}

type OwnProps = {
    address: string;
    trigger: boolean;
} & Pick<PairInterface, 'isToken0' | 'token' | 'baseToken' | 'currentReserves'>;
type Props = OwnProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

interface State {
    buyAmount: string;
    buyAmountReal: BN;
    sellAmount: string;
    sellAmountReal: BN;
    buySlippageEnabled: boolean;
    interimBuySlippage: string;
    buySlippage: string;
    sellSlippageEnabled: boolean;
    interimSellSlippage: string;
    sellSlippage: string;
}

class Swap extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            buyAmount: '',
            buyAmountReal: new BN(0),
            sellAmount: '',
            sellAmountReal: new BN(0),
            buySlippageEnabled: false,
            interimBuySlippage: 'auto',
            buySlippage: 'auto',
            sellSlippageEnabled: false,
            interimSellSlippage: 'auto',
            sellSlippage: 'auto',
        }
    }

    componentDidMount = () => {
        this.props.getTokenBalance(this.props.token.address);
        this.props.getTokenAllowance(this.props.token.address);
    }

    componentDidUpdate = (prevProps: Props) => {

        const prevTokenBalance = prevProps.tokenBalance;
        const tokenBalance = this.props.tokenBalance;
        const buySuccessful = tokenBalance.gt(prevTokenBalance);
        const sellSuccessful = prevTokenBalance.gt(tokenBalance);
        if (buySuccessful) {
            this.setState({
                buyAmount: '',
                sellAmount: fromWeiCustomDecimals(tokenBalance.toString(), this.props.token.decimals),
                sellAmountReal: tokenBalance,
            })
        } else if (sellSuccessful) {
            this.setState({
                buyAmount: '',
                sellAmount: '',
            })
        }
        /*         const prevBuyError = !!prevProps.buyErrorMessage;
                const buyError = !!this.props.buyErrorMessage;
                const prevSellError = !!prevProps.sellErrorMessage;
                const sellError = !!this.props.sellErrorMessage; */
    }

    refreshBalance = () => {
        this.props.getBalance();
    }

    refreshTokenBalance = () => {
        this.props.getTokenBalance(this.props.token.address);
    }

    getBuyButtonName = () => {
        if (!this.props.account) {
            return 'Connect wallet';
        } else if (this.props.networkType !== preferredNetwork.networkType) {
            return 'Switch network';
        } else {
            return 'Buy';
        }
    }

    canApprove = () => {
        return !!this.props.account && !this.isApproved();
    }

    isApproved = () => {
        const { allowance, tokenBalance } = this.props;
        return allowance.gt(tokenBalance.add(new BN(1)));
    }

    getSellButtonName = () => {
        const sufficientBalance = this.props.tokenBalance.gte(new BN(toWeiCustomDecimals(this.state.sellAmount || '0', this.props.token.decimals)));
        const sufficientInput = new BN(toWeiCustomDecimals(this.state.sellAmount || '0', this.props.token.decimals) || '0').gt(new BN(0));
        if (!sufficientInput) {
            return 'Enter Amount';
        } else if (!sufficientBalance) {
            return 'Insufficient balance';
        } else {
            return 'Sell';
        }
    }

    handleBuyButton = (action: string) => {
        if (this.props.buyLoading) {
            return;
        } else if (action === 'Buy') {
            return this.buy();
        } else if (action === 'Connect wallet') {
            this.props.connectWallet();
        } else if (action === 'Switch network') {
            this.props.switchNetwork();
        }
    }

    canBuy = () => {
        const walletConnected = !!this.props.account && this.props.networkType === preferredNetwork.networkType;
        const sufficientBalance = this.props.bnbBalance.sub(new BN('10000000000000000')).gte(this.state.buyAmountReal);
        const sufficientInput = new BN(toWeiCustomDecimals(this.state.buyAmount || '0', 18) || '0').gt(new BN(0));
        return walletConnected && sufficientBalance && sufficientInput && !this.props.buyLoading;
    }

    buy = () => {
        const { buyAmountReal, buySlippage } = this.state;
        const { bnbBalance } = this.props;
        if (buyAmountReal.isZero() || buyAmountReal.isNeg() || buyAmountReal.gt(bnbBalance)) {
            return;
        }
        this.props.buy({
            path: this.props.path,
            amount: buyAmountReal,
            slippage: buySlippage,
        });
    }

    handleSellButton = (action: string) => {
        if (!this.canSell()) {
            return;
        }
        if (this.props.sellLoading) {
            return;
        } else if (action === 'Connect wallet') {
            this.props.connectWallet();
        } else if (action === 'Switch network') {
            this.props.switchNetwork();
        } else if (action === 'Sell') {
            return this.sell();
        }
    }

    handleConfirmBuySlippage = () => {
        let buySlippage = this.state.buySlippage;
        const { interimBuySlippage } = this.state;
        if (interimBuySlippage === 'auto' || parseFloat(interimBuySlippage) > 0) {
            buySlippage = interimBuySlippage;
        }
        this.setState({
            buySlippage,
            buySlippageEnabled: false
        })
    }

    handleConfirmSellSlippage = () => {
        let sellSlippage = this.state.sellSlippage;
        const { interimSellSlippage } = this.state;
        if (interimSellSlippage === 'auto' || parseFloat(interimSellSlippage) > 0) {
            sellSlippage = interimSellSlippage;
        }
        this.setState({
            sellSlippage,
            sellSlippageEnabled: false
        })
    }

    approve = () => {
        if (this.isApproved() || this.props.approveLoading) {
            return;
        } else {
            this.props.approve({ address: this.props.token.address });
        }
    }

    canSell = () => {
        const walletConnected = !!this.props.account && this.props.networkType === preferredNetwork.networkType;
        const sufficientBalance = this.props.tokenBalance.gte(new BN(toWeiCustomDecimals(this.state.sellAmount || '0', this.props.token.decimals)));
        const sufficientInput = new BN(toWeiCustomDecimals(this.state.sellAmount || '0', this.props.token.decimals) || '0').gt(new BN(0));
        return walletConnected && sufficientBalance && sufficientInput && !this.props.sellLoading && this.isApproved();
    }

    sell = async () => {
        const { tokenBalance } = this.props;
        const { sellAmountReal, sellSlippage } = this.state;
        if (sellAmountReal.isZero() || sellAmountReal.isNeg() || sellAmountReal.gt(tokenBalance)) {
            return;
        }
        this.props.sell({
            path: this.props.path.reverse(),
            amount: sellAmountReal,
            slippage: sellSlippage,
        });
    }

    closeError = (buy: boolean) => {
        if (buy) {
            this.props.clearBuyError();
        } else {
            this.props.clearSellError();
        }
    }

    render() {
        const bnbBalance = fromWeiCustomDecimals(this.props.bnbBalance.toString(), 18);
        const tokenBalance = fromWeiCustomDecimals(this.props.tokenBalance.toString(), this.props.token.decimals);
        const buyButtonName = this.getBuyButtonName();
        const sellButtonName = this.getSellButtonName();
        return (
            <SwapContainer id='swap'>

                <TradeContainer
                    enabled={this.props.buyLoading || !!this.props.buyErrorMessage}
                    trigger={this.props.trigger}

                    error={!!this.props.buyErrorMessage}
                >
                    <SwapHeader trigger={this.props.trigger}>
                        <HeaderTitle>{this.state.buySlippageEnabled ? 'Slippage settings' : 'Buy token'}</HeaderTitle>
                        {
                            this.state.buySlippageEnabled ? (
                                <CancelButtonContainer onClick={() => this.setState({ buySlippageEnabled: false, interimBuySlippage: this.state.buySlippage })}>
                                    CANCEL&nbsp;&nbsp;
                                    <CancelButton >
                                        <XIcon />
                                    </CancelButton>
                                </CancelButtonContainer>
                            ) : (
                                <BalanceContainer>
                                    Balance: &nbsp;{Math.floor(1000 * parseFloat(bnbBalance)) / 1000}&nbsp;
                                    <RefreshButton onClick={this.refreshBalance}>
                                        <Refresh />
                                    </RefreshButton>
                                </BalanceContainer>
                            )
                        }
                    </SwapHeader>
                    <TokenInputContainer>
                        <HideContainer enabled={!this.state.buySlippageEnabled}>
                            <SlippageInputContainer>
                                <span>Setting a high slippage tolerance can help transactions succeed but your transaction may be frontrun. Use with caution.</span>
                                <SlippageInput
                                    slippage={this.state.interimBuySlippage}
                                    onChange={(slippage) => this.setState({ interimBuySlippage: slippage })}
                                >

                                </SlippageInput>
                            </SlippageInputContainer>
                        </HideContainer>
                        <HideContainer enabled={this.state.buySlippageEnabled}>
                            <TokenInput
                                address={this.props.token.address}
                                token={this.props.token}
                                baseToken={this.props.baseToken}
                                isToken0={this.props.isToken0}
                                currentReserves={this.props.currentReserves}
                                amount={this.state.buyAmount}
                                onChange={(buyAmount: string, buyAmountReal: BN) => this.setState({ buyAmount, buyAmountReal })}
                                buy={true}
                            />
                        </HideContainer>
                    </TokenInputContainer>
                    <CallToAction>
                        <SlippageCallToAction enabled={this.state.buySlippageEnabled}>
                            <div />
                            <BuyButton
                                enabled={true}
                                onClick={this.handleConfirmBuySlippage}
                            >
                                <Card /> Confirm
                            </BuyButton>
                        </SlippageCallToAction>
                        <HideContainer enabled={this.state.buySlippageEnabled}>
                            <BuyButton
                                enabled={['Connect wallet', 'Switch network'].includes(buyButtonName) || this.canBuy()}
                                onClick={() => this.handleBuyButton(buyButtonName)}
                            >
                                {buyButtonName === 'Buy' ? (<Card />) : undefined} {buyButtonName}
                            </BuyButton>
                            <SlippageBar>
                                <SlippageTitle>
                                    <span>
                                        SLIPPAGE TOLERANCE
                                    </span>
                                </SlippageTitle>
                                <SlippageButton onClick={() => this.setState({ buySlippageEnabled: true })}>
                                    {this.state.buySlippage} {this.state.buySlippage !== 'auto' && ('%')}<SettingsWheel />
                                </SlippageButton>
                            </SlippageBar>
                        </HideContainer>
                    </CallToAction>
                    {
                        (this.props.buyLoading || this.props.buyErrorMessage) && (
                            <LoadingContainer enabled={this.props.buyLoading || !!this.props.buyErrorMessage}>
                                <LoadingHeader>
                                    <CancelButtonContainer onClick={() => this.closeError(true)}>
                                        CLOSE&nbsp;&nbsp;
                                        <CancelButton >
                                            <XIcon />
                                        </CancelButton>
                                    </CancelButtonContainer>
                                </LoadingHeader>
                                <LoadingBody center={!this.props.buyErrorMessage}>
                                    {
                                        !this.props.buyErrorMessage ? (
                                            <>
                                                <Rocketani />
                                                <span>Waiting for wallet confirmation...</span>
                                            </>
                                        ) : (
                                            <ErrorSpan>{this.props.buyErrorMessage}</ErrorSpan>
                                        )
                                    }
                                </LoadingBody>
                            </LoadingContainer>
                        )
                    }
                </TradeContainer>
                <TradeContainer
                    enabled={this.props.sellLoading || !!this.props.sellErrorMessage || this.props.approveLoading}
                    trigger={this.props.trigger}
                    error={!!this.props.sellErrorMessage}
                >
                    <SwapHeader trigger={this.props.trigger}>
                        <HeaderTitle>{this.state.sellSlippageEnabled ? 'Slippage settings' : 'Sell token'}</HeaderTitle>
                        {
                            this.state.sellSlippageEnabled ? (
                                <CancelButtonContainer onClick={() => this.setState({ sellSlippageEnabled: false, interimSellSlippage: this.state.sellSlippage })}>
                                    CANCEL&nbsp;&nbsp;
                                    <CancelButton >
                                        <XIcon />
                                    </CancelButton>
                                </CancelButtonContainer>
                            ) : (
                                <BalanceContainer>
                                    Balance: &nbsp;{simplify(tokenBalance)}&nbsp;
                                    <RefreshButton onClick={this.refreshTokenBalance}>
                                        <Refresh />
                                    </RefreshButton>
                                </BalanceContainer>
                            )
                        }
                    </SwapHeader>
                    <TokenInputContainer>
                        <HideContainer enabled={!this.state.sellSlippageEnabled}>
                            <SlippageInputContainer>
                                <span>Setting a high slippage tolerance can help transactions succeed but your transaction may be frontrun. Use with caution.</span>
                                <SlippageInput
                                    slippage={this.state.interimSellSlippage}
                                    onChange={(slippage) => this.setState({ interimSellSlippage: slippage })}
                                >

                                </SlippageInput>
                            </SlippageInputContainer>
                        </HideContainer>
                        <HideContainer enabled={this.state.sellSlippageEnabled}>
                            <TokenInput
                                address={this.props.token.address}
                                token={this.props.token}
                                baseToken={this.props.baseToken}
                                isToken0={this.props.isToken0}
                                currentReserves={this.props.currentReserves}
                                amount={this.state.sellAmount}
                                onChange={(sellAmount: string, sellAmountReal: BN) => this.setState({ sellAmount, sellAmountReal })}
                                buy={false}
                            />
                        </HideContainer>
                    </TokenInputContainer>
                    <CallToAction>
                        <SlippageCallToAction enabled={this.state.sellSlippageEnabled}>
                            <div />
                            <BuyButton
                                enabled={true}
                                onClick={this.handleConfirmSellSlippage}
                            >
                                <Card /> Confirm
                            </BuyButton>
                        </SlippageCallToAction>
                        <HideContainer enabled={this.state.sellSlippageEnabled}>
                            <BuyButtonsContainer>
                                <ApproveBuyButton
                                    enabled={this.canApprove() && !this.props.approveLoading}
                                    approved={this.isApproved()}
                                    onClick={this.approve}
                                >
                                    <Verified />
                                    {this.isApproved() ? 'Approved' : 'Approve'}
                                </ApproveBuyButton>
                                <BuyButton
                                    enabled={this.canSell()}
                                    onClick={() => this.handleSellButton(sellButtonName)}
                                >
                                    <SellCard /> Sell
                                </BuyButton>
                            </BuyButtonsContainer>
                            <SlippageBar>
                                <SlippageTitle>
                                    <span>
                                        SLIPPAGE TOLERANCE
                                    </span>
                                </SlippageTitle>
                                <SlippageButton onClick={() => this.setState({ sellSlippageEnabled: true })}>
                                    {this.state.sellSlippage} {this.state.sellSlippage !== 'auto' && ('%')}<SettingsWheel />
                                </SlippageButton>
                            </SlippageBar>
                        </HideContainer>

                    </CallToAction>
                    {
                        (this.props.sellLoading || this.props.approveLoading) && (
                            <LoadingContainer enabled={this.props.sellLoading || this.props.approveLoading || !!this.props.sellErrorMessage}>
                                <LoadingHeader>
                                    <CancelButtonContainer onClick={() => this.closeError(false)}>
                                        CLOSE&nbsp;&nbsp;
                                        <CancelButton >
                                            <XIcon />
                                        </CancelButton>
                                    </CancelButtonContainer>
                                </LoadingHeader>
                                <LoadingBody center={!this.props.sellErrorMessage}>
                                    {
                                        !this.props.sellErrorMessage ? (
                                            <>
                                                <Rocketani />
                                                <span>Waiting for wallet confirmation...</span>
                                            </>
                                        ) : (
                                            <ErrorSpan>{this.props.sellErrorMessage}</ErrorSpan>
                                        )
                                    }
                                </LoadingBody>
                            </LoadingContainer>
                        )
                    }
                </TradeContainer>
            </SwapContainer >
        )
    }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
    const tokenAddress = ownProps.token.address;
    const tokenBalance = state.wallet.balances[tokenAddress] || new BN(0);
    const bnbPrice = state.interface.BNBPrice;
    const bnbBalance = state.wallet.balances.bnb || new BN(0);
    const allowance = state.wallet.allowances[tokenAddress] || new BN(0);
    const { baseToken } = ownProps;

    const path = [];
    path.push(WBNBAddress);
    if (baseToken.address === BUSDAddress) {
        path.push(BUSDAddress);
    }
    path.push(tokenAddress);

    const currentReserves = ownProps.currentReserves;
    const hasSimulations = Object.keys(state.analyze.tokens).includes(tokenAddress);
    const simulations = hasSimulations ? state.analyze.tokens[tokenAddress].result : undefined;
    const buyTax = simulations ? simulations.buy.success ? simulations.buy.tax : new BN(0) : new BN(0);
    const sellTax = simulations ? simulations.sell.success ? simulations.sell.tax : new BN(0) : new BN(0);
    const autoBuySlippage = buyTax.divRound(new BN(100)).add(new BN(4)).toString();
    const autoSellSlippage = sellTax.divRound(new BN(100)).add(new BN(4)).toString();

    return {
        account: state.wallet.account,
        autoBuySlippage,
        autoSellSlippage,
        allowance,
        tokenBalance,
        reserve0: new BN(currentReserves.reserve0),
        reserve1: new BN(currentReserves.reserve1),
        buyLoading: state.wallet.buyLoading || !!state.wallet.buyErrorMessage,
        sellLoading: state.wallet.sellLoading || !!state.wallet.sellErrorMessage,
        approveLoading: state.wallet.approveLoading,
        bnbPrice,
        bnbBalance,
        path,
        networkType: state.wallet.networkType,
        buyErrorMessage: state.wallet.buyErrorMessage,
        sellErrorMessage: state.wallet.sellErrorMessage,
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(Swap);