import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { simplify } from '../../utils/numbers';
import { Simulation, SwapLimit } from '../../types/simulations';

import { ReactComponent as Gas } from '../svg/gas.svg';
import { ReactComponent as Attention } from '../svg/attention.svg';
import { ReactComponent as Cancel } from '../svg/cancel.svg';
import { ReactComponent as Check } from '../svg/check.svg';

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const GridContainer = styled.div<{ active?: boolean }>`
    grid-column: 1 / ${({ active }) => active ? 2 : 3};

    ${({ theme }) => theme.media.small} {
        grid-column: 1 / 2;
    }
`;

const SimulationsContainer = styled.div<{ active?: boolean }>`
    height: 100%;
    display: grid;
    width: 100%;
    grid-template-columns: auto min-content;
    justify-items: stretch;
    white-space: nowrap;
    opacity: ${({ active }) => active ? 1 : 0};
    transition: all 0.3s ${({ theme }) => theme.transitions.main};
`;

const SimulationHeader = styled.span<{ right?: boolean }>`
    
    padding: 1rem 0;
    height: 100%;
    text-align: ${({ right }) => right ? 'right' : 'left'};
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.uiColor3};
`;

const SimulationRowContainer = styled.div`
    display: contents;

    & > * {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 0.875rem;
        height: 3.5rem;
    }
`;

const ActionContainer = styled.div`
    & span {
        font-weight: 500;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors.brandColor1};
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        
    }
    & svg {
        
    }
`;

const ResultContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    & span {
        font-weight: 500;
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors.brandColor1};
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
`;

const GasSpan = styled.div`
    font-size: 0.7rem;
    font-weight: 400;
    margin-left: 0.5rem;
    visibility: hidden;
`

const FailSpan = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
`

const GasPumpDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
border: 1px solid ${({ theme }) => theme.colors.uiColor2};
height: 2rem;
width: 2rem;
border-radius: 3.75rem;

& svg {
    color: ${({ theme }) => theme.colors.brandColor1};
    height: 0.9rem:
    width: 0.9rem;
}
&:hover {
    + div {
        visibility: visible;
    }
}


`

const CheckBox = styled.div<{ warning?: boolean; success: boolean; }>`
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${({ theme, warning, success }) => warning ? theme.colors.triggerColor3 : success ? theme.colors.triggerColor5 : theme.colors.triggerColor1};
    border-radius: 0.8rem;
    width: 100%;
    height: 100%;
    & svg {
        height: 1.5rem;
        width: 1.5rem;
    }
`;

const CheckBoxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
`;

const NoMobile = styled.div`
    margin-right: 0.25rem;
    ${({ theme }) => theme.media.small} {
        display: none;
    }
`;

type Props = {
    active: boolean;
    bnbPrice: number | undefined;
} & ReturnType<typeof mapStateToProps>;

interface State {

}

class Simulations extends React.Component<Props, State> {
    private displayInterval!: NodeJS.Timeout;
    constructor(props: Props) {
        super(props);

        this.state = {

        }
    }

    componentDidMount = () => {
        this.displayInterval = setInterval(this.handleRender, 350);
    }

    componentWillUnmount = () => {
        clearInterval(this.displayInterval);
    }

    handleRender = () => {

    }

    renderMaxLimit = ({
        type,
        low,
        high,
        fiatAmount,
    }: SwapLimit, i: number) => {
        return (
            <SimulationRowContainer key={i}>
                <ActionContainer>
                    Maximum <span>{type === 'Sell' ? 'sell' : 'buy'}</span> limit between <span>{simplify(low, 3)}</span> and <span>{simplify(high, 3)}</span> {type === 'Sell' ? 'tokens' : 'BNB'} {type === 'Sell' && (<>(<span>{parseFloat(fiatAmount).toFixed(3)} BNB</span>)</>)}
                </ActionContainer>
                <ResultContainer>
                    <CheckBoxContainer>
                        <CheckBox warning success={false}>
                            <Attention />
                        </CheckBox>
                    </CheckBoxContainer>

                </ResultContainer>
            </SimulationRowContainer>
        );
    }

    renderSimulation = ({
        type,
        amount,
        symbol,
        actual,
        expected,
        success,
        gasUsed,
        tax,
    }: Simulation, i: number) => {

        const gasDollars = ((gasUsed.toNumber() * 0.00000001) * (this.props.bnbPrice || 0)).toFixed(2)
        const warning = (tax.toNumber() / 100 > 20 && tax.toNumber() / 100 < 100);

        const parsedAmount = type === 'Buy' ? parseFloat(amount).toFixed(3) : simplify(amount.toString());
        const expectedAmount = type === 'Buy' ? simplify(expected) : parseFloat(expected).toFixed(3);
        const actualAmount = type === 'Sell' ? parseFloat(actual).toFixed(3) : simplify(actual);
        return (
            <SimulationRowContainer key={i}>
                <ActionContainer>
                    {
                        type === 'Buy' ? (
                            <React.Fragment>
                                Buy<span>{expectedAmount === '0' ? '?' : expectedAmount}</span>{type === 'Buy' ? 'tokens' : 'BNB'} for<span>{parsedAmount}&nbsp; {symbol}</span> <GasPumpDiv><Gas /></GasPumpDiv><GasSpan>Gas: ${gasDollars}</GasSpan>
                            </React.Fragment>
                        ) : type === 'Transfer' ? (
                            <React.Fragment>
                                Transfer <span>{simplify(amount)}</span> tokens
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {type} <span>{parsedAmount}</span> {symbol} for <span>{expectedAmount === '0' ? '?' : expectedAmount}{type === 'Buy' ? 'tokens' : 'BNB'}</span> <GasPumpDiv><Gas /></GasPumpDiv><GasSpan>Gas: ${gasDollars}</GasSpan>
                            </React.Fragment>
                        )

                    }
                </ActionContainer>
                <ResultContainer>
                    {
                        success ? (
                            <React.Fragment>
                                <NoMobile>Received<span>{actualAmount}</span>{type === 'Sell' ? 'BNB' : 'tokens'}</NoMobile>{`(${(tax.toNumber() / 100).toFixed(2)}% tax)`}


                            </React.Fragment>
                        ) :
                            (<React.Fragment>
                                <FailSpan>Fail</FailSpan>


                            </React.Fragment>)
                    }
                    <CheckBoxContainer>
                        <CheckBox warning={warning} success={success}>
                            {warning ? <Attention /> : success ? <Check /> : <Cancel />}
                        </CheckBox>
                    </CheckBoxContainer>
                </ResultContainer>
            </SimulationRowContainer>
        )
    }

    transformSimulations = (): React.ReactNode[] => {
        const { result } = this.props;
        if (!result) {
            return [];
        }
        const { buy, transfer, sell, buyLimit, sellLimit } = result;

        const rows = [buy, transfer, sell].map(
            (sim, i) => this.renderSimulation(sim, i)
        );

        if (buyLimit) {
            rows.splice(1, 0, this.renderMaxLimit(buyLimit, 10));
        }
        if (sellLimit) {
            rows.push(this.renderMaxLimit(sellLimit, 15));
        }
        return rows;
    }

    render() {
        const rendered = this.transformSimulations();
        if (this.props.error.toString().includes("execution reverted")) {
            return (
                <div>Execution reverted by contract!</div>
            );
        }
        if (this.props.error === 'No pool found') {
            return (
                <div>No pool found!</div>
            );
        }
        else {

            return (
                this.props.loading
                    ?
                    <span>
                        <Loader
                            type="Oval"
                            color="#ffffff"
                            height={'1.5rem'}
                            width={'1.5rem'}
                            visible={true}
                        />
                    </span>
                    :
                    <GridContainer active={!!this.props.active}>
                        <SimulationsContainer active={!!this.props.result}>
                            <SimulationHeader>Simulated action</SimulationHeader>
                            <SimulationHeader right>Result</SimulationHeader>
                            {
                                rendered.map((node) => node)
                            }
                        </SimulationsContainer>
                    </GridContainer>
            );

        }

    }
}

const mapStateToProps = (state: RootState) => {
    const simulations = state.analyze.tokens[state.analyze.address];

    return {
        loading: simulations?.loading || false,
        result: simulations?.result,
        error: simulations?.error || '',
    }
};

export default connect(
    mapStateToProps,
)(Simulations);