import React from 'react';
import styled from 'styled-components/macro';
import Web3 from 'web3';

import { getWeb3 } from '../../utils/web3';

const ImportTokenContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TokenContainer = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.lightBlue};
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
`;

const TextInput = styled.input.attrs({
    type: 'text',
}) <{ tokenAddressError: boolean } >` 
    outline: none;
    border: none;
    width: 100%;
    height: 2.813rem;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.uiColor5};
    font-family: ${({ theme }) => theme.fonts.main};
    padding: 1rem;
    font-size:  0.875rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};

    &:hover {
        border: 2px solid ${({ tokenAddressError, theme }) => tokenAddressError ? theme.colors.lightRed : 'white'};
    }

    ${({ tokenAddressError, theme }) => tokenAddressError && `
        border: 2px solid ${theme.colors.lightRed};
    `}

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme }) => theme.colors.uiColor4};
        opacity: 1; /* Firefox */
        font-size: 0.875rem;
        font-family: ${({ theme }) => theme.fonts.main}
      }

    transition: all 0.2s ${({ theme }) => theme.transitions.main};
`;

const AmountContainer = styled.div` 
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

interface Props {
    address: string;
    onToken: (address: string) => void;
}

interface State {
    bottomEnabled: boolean;
    tokenAddress: string;
    tokenAddressError: boolean;
    name: string;
}

export default class ImportToken extends React.Component<Props, State> {
    private web3!: Web3;
    constructor(props: Props) {
        super(props);

        this.state = {
            bottomEnabled: true,
            tokenAddress: '',
            tokenAddressError: false,
            name: '',
        }
    }

    componentDidUpdate = (prevProps: Props) => {
        if (this.props.address && !prevProps.address) {
            this.setState({ tokenAddress: this.props.address });
        }
    }

    getWeb3 = async () => {
        if (!this.web3) {
            this.web3 = await getWeb3();
            return this.web3;
        } else {
            return this.web3;
        }
    }

    ontokenAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const address = e.currentTarget.value;
        const regexAddress = address.match(/0x[a-fA-F0-9]{40}/);
        const tokenAddress = regexAddress ? regexAddress[0] : address;
        this.setState({
            tokenAddress,
            tokenAddressError: false
        });
        if (!!tokenAddress) {
            this.tryTokenAddress(tokenAddress);
        } else {
            this.setState({
                name: '',
            });
            this.props.onToken('');
        }
    }

    tryTokenAddress = async (address: string) => {
        this.setState({ tokenAddress: address });
        try {
            const web3 = await this.getWeb3();
            const checkSumAddress = web3.utils.toChecksumAddress(address);
            if (this.web3.utils.isAddress(checkSumAddress)) {
                this.props.onToken(checkSumAddress);
                this.setState({ tokenAddress: checkSumAddress })
            } else {
                this.setState({ tokenAddressError: true });
                this.props.onToken('');
            }
        } catch (error) {
            this.setState({ tokenAddressError: true });
            this.props.onToken('');
        }
    }

    render() {
        return (
            <ImportTokenContainer>
                <TokenContainer>
                    <AmountContainer>
                        <TextInput
                            onChange={this.ontokenAddressChange}
                            placeholder={'Enter token address'}
                            value={this.state.tokenAddress}
                            tokenAddressError={this.state.tokenAddressError}
                        />
                    </AmountContainer>
                </TokenContainer>
            </ImportTokenContainer >
        )
    }
}