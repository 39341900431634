import * as React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Lock } from '../svg/lock.svg';
import { ReactComponent as Verified } from '../svg/verified.svg';
import { ReactComponent as Socials } from '../svg/socials.svg';

const IndicatorsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
        margin: 0 0.25rem;
    }
`;

interface IconContainerProps {
    enabled: boolean;
    message: string;
    red?: boolean;
}

const IconContainer = styled.div<IconContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme, enabled, red }) => !enabled ? theme.colors.uiColor2 : red ? theme.colors.triggerColor3 : 'inherit'};
    height: 1.35rem;
    width: 1.35rem;

    position: relative;
    & >span {
        font-size: 0.5rem;
        font-weight: 500;
        border-radius: 0.25rem;
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        z-index: 1;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.uiColor2};
        left: -${({ message }) => message.length * 13}%;
        bottom: 110%;
        opacity: 0;
        color: white;
        text-decoration: none;
        white-space: nowrap;
        transition: all 0.15s ${({ theme }) => theme.transitions.main};
        &:after {
            content: '${({ message }) => message}';
        }
        pointer-events: none;
        text-transform: uppercase;
        padding: 0.375rem 0.5rem;
    }

    &:hover {
        & >span {
            opacity: 1;
            transition: all 0.15s ${({ theme }) => theme.transitions.main};
            transition-delay: 0;
        }
    }
`;

type Props = {
    contractVerified: boolean;
    locked: boolean;
    taxable: boolean;
    socials: boolean;
};

export default class Indicators extends React.Component<Props> {

    render() {
        return (
            <IndicatorsContainer>
                <IconContainer
                    enabled={!!this.props.contractVerified}
                    message='Contract verified'
                >
                    <Verified />
                    <span></span>
                </IconContainer>
                <IconContainer
                    enabled={this.props.locked}
                    message='LP lock'
                >
                    <Lock />
                    <span></span>
                </IconContainer>
                <IconContainer
                    enabled={this.props.socials}
                    message='Socials'
                >
                    <Socials />
                    <span></span>
                </IconContainer>
            </IndicatorsContainer>
        )
    }
}