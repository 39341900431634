import { merge, from, of } from 'rxjs';
import { filter, switchMap, mergeMap, map, catchError } from 'rxjs/operators';
import { EpicWithTypes, isActionOf } from 'typesafe-actions';
import * as analyzerActions from './actions';

export const analyzeTokenEpic: EpicWithTypes = (action$, state$, { api }) =>
        action$.pipe(
            filter(isActionOf(analyzerActions.analyzeToken.request)),
            mergeMap((action) => {
                const tokenInfo = api.analyzer.getTokenInfo(action.payload);
                const tokenInfoResult = from(tokenInfo).pipe(
                    map(analyzerActions.getTokenInfo.success),
                    catchError((message: string) => of(analyzerActions.getTokenInfo.failure(message)))
                );

                const simulateResult = from(tokenInfo)
                    .pipe(
                        mergeMap(
                            ({ decimals }) => {
                                return from(api.analyzer.simulate({ address: action.payload, decimals }))
                                    .pipe(
                                        map(analyzerActions.simulate.success),
                                        catchError(({ address, error }) => of(analyzerActions.simulate.failure({ address, error })))
                                    )
                            }
                        )
                    )
                return merge(tokenInfoResult, simulateResult);
            })
        );

export const simulateEpic: EpicWithTypes = (action$, state$, { api }) =>
        action$.pipe(
            filter(isActionOf(analyzerActions.simulate.request)),
            switchMap((action) =>
                from(api.analyzer.simulate(action.payload)).pipe(
                    map(analyzerActions.simulate.success),
                    catchError(({ address, error }) => of(analyzerActions.simulate.failure({ address, error })))
                )
            )
        );


export const getTokenInfoEpi: EpicWithTypes = (action$, state$, { api }) =>
        action$.pipe(
            filter(isActionOf(analyzerActions.getTokenInfo.request)),
            switchMap((action) =>
                from(api.analyzer.getTokenInfo(action.payload)).pipe(
                    map((result) => {
                        return analyzerActions.getTokenInfo.success(result)
                    }),
                    catchError((message: string) => of(analyzerActions.getTokenInfo.failure(message)))
                )
            )
        );