import * as React from 'react';
import styled from 'styled-components/macro';
import isMalicious from '../../selectors/is-malicious';

import { ReactComponent as Warning } from '../svg/warning.svg';

const IndicatorsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
   
`;

interface IconContainerProps {
    enabled: boolean;
    message: string;
    red?: boolean;
}

const IconContainer = styled.div<IconContainerProps>`
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.triggerColor3};
    height: 1.35rem;
    width: 1.35rem;

    position: relative;
    & >span {
        font-size: 0.5rem;
        font-weight: 500;
        border-radius: 0.25rem;
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        z-index: 1;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.uiColor2};
        left: -${({ message }) => message.length * 13}%;
        bottom: 110%;
        opacity: 0;
        color: white;
        text-decoration: none;
        white-space: nowrap;
        transition: all 0.15s ${({ theme }) => theme.transitions.main};
        &:after {
            content: '${({ message }) => message}';
        }
        pointer-events: none;
        text-transform: uppercase;
        padding: 0.375rem 0.5rem;
    }

    &:hover {
        & >span {
            opacity: 1;
            transition: all 0.15s ${({ theme }) => theme.transitions.main};
            transition-delay: 0;
        }
    }
`;

type Props = {
    contractAnalysis?: string[];
};

export default class MaliciousWarning extends React.Component<Props> {

    render() {
        const { contractAnalysis } = this.props;
        const malicious = (contractAnalysis || []).reduce((acc: boolean, type) => acc || isMalicious(type), false);
        return (
            <IndicatorsContainer>
                <IconContainer
                    enabled={malicious}
                    message='Malicious contract'
                    red
                >
                    <Warning />
                    <span></span>
                </IconContainer>
            </IndicatorsContainer>
        )
    }
}