import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import ImportToken from './import-token';
import { simulate, resetAnalyzer, setAddress } from '../../store/analyze/actions';
import * as interfaceActions from '../../store/interface/actions';

import Simulations from './simulations-list';

/* import { ReactComponent as Zap } from '../svg/zap.svg'; */
import { ReactComponent as Analyze } from '../svg/analyze.svg';
/* import { ReactComponent as Verified } from '../svg/verified.svg';
import { ReactComponent as Wallet } from '../svg/wallet.svg';
import { ReactComponent as Lock } from '../svg/lock.svg';
import { ReactComponent as Socials } from '../svg/socials.svg';
import { ReactComponent as Poocoin } from '../svg/poocoin.svg';
import { ReactComponent as BscScan } from '../svg/etherscan.svg';
import { ReactComponent as Pancake } from '../svg/pancake.svg'; */

const AnalyzeGridContainer = styled.div<{ active?: boolean }>`
    margin-top: 1.5rem;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    column-gap: 5rem;
    margin-bottom: 5rem;
    ${({ theme }) => theme.media.small} {
       display: flex;
       flex-direction: column;
       align-items: center;
    }
`;

const TitleContainer = styled.div`
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 2rem;
    width: 100%;
`;

const AddressContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    ${({ theme }) => theme.media.small} {
        flex-direction: column;
    }
`;

const AddressAndButton = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ theme }) => theme.media.small} {
        margin-bottom: 1rem;
    }
`;

const SimulateButton = styled.div<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.875rem 1.5rem;
    height: 2.8rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    width: 8.75rem;
    margin-left: 1rem;
    background-color: ${({ theme, disabled }) => theme.colors[disabled ? 'uiColor2' : 'brandColor1']};
    & svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.625rem;
        margin-top: 0.1rem;
    }

    cursor: ${({ disabled }) => disabled ? 'initial' : 'pointer'};
    color: ${({ theme }) => theme.colors.uiColor1};


    
    &:active {
        transform: translateY(${({ disabled }) => disabled ? '0px' : '3px'});
    }

    transition: all 0.2s ${({ theme }) => theme.transitions.main};

    ${({ theme }) => theme.media.small} {
        margin-left: 0;
        width: 100%;
    }
`;

const ParagraphContainer = styled.div`
    margin-bottom: 2rem;
    width: 43.5rem;
    ${({ theme }) => theme.media.small} {
       width: 100%;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;
/* 
const RightContainer = styled.div`
    width: 100%;
    height: 21.5rem;
    max-width: 21.5rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.media.small} {
       max-width: 100%;
       margin-top: 4rem;
    }
`;

const LogosButtons = styled.div`
    width: 100%;
    height: 100%;
    padding: 1.25rem 1.25rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.colors.uiColor2};
`;

const Information = styled.div`
    width: 100%;
    height: 100%;
    padding: 0rem 0rem 0rem 1.25rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({ theme }) => theme.colors.uiColor2};
    ${({ theme }) => theme.media.small} {
        margin-left: 1rem;
    }
`;

const Expresstrade = styled.div`
    width: 100%;
    height: min-content;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: column;
`; */
const Subtitle = styled.p`
    
    font-size: 1rem;
    font-weight: 400;
    line-height: 170%;
    margin-top: 0;
    & span {
        color: ${({ theme }) => theme.colors.brandColor1};
        font-weight: 700;
    }
`;
/* 
const LogoContainer = styled.div<{ active?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
   
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 2rem;
    border: ${({ theme, active }) => active ? `1px solid ${theme.colors.uiColor2}` : 'none'};
    background-color: ${({ theme, active }) => active ? 'none' : theme.colors.uiColor6};
    
    & svg {
        width: 1.875rem;
        height: 1.875rem;
    }
`;

const OnlyPrice = styled.div`
    
`;

const ButtonsIndicators = styled.div`
    margin-top: 1rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Indicators = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

const Indicator = styled.div<{ active?: boolean }>`
    margin-right: 0.875rem;
    color: ${({ theme, active }) => active ? 'white' : theme.colors.uiColor6};
    & svg {
        width: 1rem;
        height: 1rem;
    }
`;

const Buttons = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
`;

const Button = styled.div<{ active?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 3.75rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    border: ${({ theme, active }) => active ? `1px solid ${theme.colors.uiColor2}` : `1px solid ${theme.colors.uiColor6}`};
    color: ${({ theme, active }) => active ? 'white' : theme.colors.uiColor6};
    margin-left: 0.5rem;
`;

const NameLogo = styled.div`
    display: flex;
    
`;

const NameLogoPrice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    
`;

const NameContainer = styled.div<{ active?: boolean }>`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme, active }) => active ? 'none' : theme.colors.uiColor6};
    border-radius: 0.125rem;
    margin-left: 0.8rem;
    width: 12rem;
`;

const Title = styled.div`
    
    font-weight: 500;
    font-size: 1.25rem;    
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    `;

const PriceChangeContainer = styled.span<{ active?: boolean }>`
    width: 3.25rem;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;    
    text-align: center;
    
    background-color: ${({ theme, active }) => theme.colors[active ? 'triggerColor5' : 'uiColor6']};
    padding: 4px 9px;
    color: ${({ theme }) => theme.colors.uiColor1};
    border-radius: 4px;
    letter-spacing: 0.03em;
`;

const InformationContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
`;

const Info = styled.div<{ active?: boolean }>`
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    font-weight: 500;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.uiColor5};
    & span {
        width: 5rem;
        height: 100%;
        background-color: ${({ theme, active }) => active ? 'none' : theme.colors.uiColor6};
        text-indent: ${({ active }) => active ? '0%' : '400%'};
        white-space: ${({ active }) => active ? 'normal' : 'nowrap'};
        overflow: ${({ active }) => active ? 'visible' : 'hidden'};
        margin-top: ${({ active }) => active ? '0rem' : '0.2rem'};
    }
`;

const InfoTitle = styled.div<{ active?: boolean }>`
    display: flex;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.5rem;
    color: ${({ theme }) => theme.colors.brandColor1};
    background-color: ${({ theme, active }) => active ? 'none' : theme.colors.uiColor6};
    width: 4.2rem;
    height: 100%;
    text-indent: ${({ active }) => active ? '0%' : '400%'};
    white-space: ${({ active }) => active ? 'normal' : 'nowrap'};
    overflow: ${({ active }) => active ? 'visible' : 'hidden'};
`

const ExpressButton = styled.div<{ active?: boolean }>`
    margin: 0 0.25rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    height: 2.8rem;
    cursor: ${({ active }) => active ? 'pointer' : 'initial'};
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.uiColor1};

    & svg {
        width: 1rem;
        height: 1rem;
        margin-top: 0.1rem;
    }

    background-color: ${({ theme }) => theme.colors.brandColor1};
    background-color: ${({ theme, active }) => theme.colors[active ? 'brandColor1' : 'uiColor2']};
    ${({ theme }) => theme.media.small} {
        width: 100%;
    }
`;

const TradeText = styled.div`
    margin-left: 0.6rem;
    line-height: 1.05rem;
    & span {
        font-weight: 400;
        margin-left: 0.2rem;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    
`;
 */



const dispatchProps = {
    setAddress: setAddress,
    reset: resetAnalyzer,
    simulate: simulate.request,
    getBNBPrice: interfaceActions.getBNBPrice.request

};

type Props = {

} & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

class AnalyzeGrid extends React.Component<Props> {
    private gridRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    componentDidMount = () => {
        this.props.getBNBPrice();
    }

    componentWillUnmount = () => {
        this.props.reset('');
    }

    handleAddress = (address: string) => {
        this.props.setAddress(address);
        /*         if (!address) {
                    this.props.reset('');
                    history.replace('/analyze');
                } else {
                    history.replace(`/analyze?address=${address}`);
                } */
    }

    simulate = () => {
        if (!!this.props.address) {
            this.props.simulate({
                address: this.props.address,
                decimals: this.props.decimals
            });
        }
    }

    render() {
        const { bnbPrice } = this.props;
        return (
            <AnalyzeGridContainer ref={this.gridRef} active={!!this.props.name}>
                <LeftContainer>
                    <TitleContainer>
                        Token Analyzer
                    </TitleContainer>
                    <ParagraphContainer>
                        <Subtitle>
                            The token analyzer simulates swaps on tokens and calculates transfer fees.
                            <br /><br />
                            It catches honeypots and high-tax scams but does <span>not</span> account for the developers ability to rug-pull or change contract variables.
                        </Subtitle>
                    </ParagraphContainer>
                    <AddressContainer>
                        <AddressAndButton>
                            <ImportToken
                                address={this.props.address}
                                onToken={this.handleAddress}
                            />
                        </AddressAndButton>
                        <SimulateButton disabled={!this.props.address} onClick={this.simulate}>
                            <Analyze /> <span>Analyze</span>
                        </SimulateButton>
                    </AddressContainer>
                    {/*                 {
                        this.props.simulationsLoading && (
                            <LoaderContainer active={!!this.props.name}>
                                <Loader
                                    type="Oval"
                                    color="#ffffff"
                                    height={200}
                                    width={200}
                                    visible={true}
                                />
                            </LoaderContainer>
                        )
                    } */}
                    <Simulations bnbPrice={bnbPrice} active={!!this.props.name} />
                </LeftContainer>
                {/* <RightContainer>
                    <LogosButtons>
                        <NameLogoPrice>
                            <NameLogo>
                                <LogoContainer active={!!this.props.name}></LogoContainer>
                                <NameContainer active={!!this.props.name}>
                                    <Title>{!!this.props.name ? this.props.name : ''}</Title>
                                </NameContainer>
                            </NameLogo>
                            <OnlyPrice>
                                <PriceChangeContainer active={!!this.props.name}>{!!this.props.name ? '403x' : '0x'}</PriceChangeContainer>
                            </OnlyPrice>
                        </NameLogoPrice>
                        <ButtonsIndicators>
                            <Indicators>
                                <Indicator active={!!this.props.name}><Verified /></Indicator>
                                <Indicator active={!!this.props.name}><Wallet /></Indicator>
                                <Indicator active={!!this.props.name}><Lock /></Indicator>
                                <Indicator active={!!this.props.name}><Socials /></Indicator>
                            </Indicators>
                            <Buttons>
                                <Button active={!!this.props.name}><Pancake /></Button>
                                <Button active={!!this.props.name}><Poocoin /></Button>
                                <Button active={!!this.props.name}><BscScan /></Button>
                            </Buttons>
                        </ButtonsIndicators>
                    </LogosButtons>
                    <Information>
                        <InformationContainer>
                            <Info active={!!this.props.name}><InfoTitle active={!!this.props.name}>Time Live</InfoTitle><span>00 mins</span></Info>
                            <Info active={!!this.props.name}><InfoTitle active={!!this.props.name}>Ini. liquidity</InfoTitle><span>00.0 BNB</span></Info>
                            <Info active={!!this.props.name}><InfoTitle active={!!this.props.name}>Cur. Liquidity</InfoTitle><span>00.0 BNB</span></Info>
                        </InformationContainer>
                        <InformationContainer>
                            <Info active={!!this.props.name}><InfoTitle active={!!this.props.name}>Buys / Sells</InfoTitle><span>000/000</span></Info>
                            <Info active={!!this.props.name}><InfoTitle active={!!this.props.name}>Ini. holders</InfoTitle><span>0000</span></Info>
                            <Info active={!!this.props.name}><InfoTitle active={!!this.props.name}>New holders</InfoTitle><span>+0000</span></Info>
                        </InformationContainer>
                    </Information>
                    <Expresstrade>
                        <ButtonContainer>
                            {/* <MoonButton>
                                <Settings />
                            </MoonButton> 
                            <ExpressButton active={!!this.props.name}>
                                <Zap />
                                <TradeText>
                                    Express Trade<span>(0.1BNB)</span>
                                </TradeText>
                            </ExpressButton>
                        </ButtonContainer>
                    </Expresstrade>
                </RightContainer> */}
            </AnalyzeGridContainer>
        )
    }
}

const mapStateToProps = (state: RootState) => {
    const simulations = state.analyze.tokens[state.analyze.address];
    return {
        address: state.analyze.address,
        name: state.analyze.info.name,
        decimals: state.analyze.info.decimals,
        simulationsLoading: simulations?.loading || false,
        bnbPrice: state.interface.BNBPrice,
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(AnalyzeGrid);