import * as React from 'react';
import Web3 from 'web3';
import styled from 'styled-components/macro';

import Timestamp from './timestamp';
import { simplify } from '../../utils/numbers';
import { ReactComponent as QuestionSvg } from '../svg/question.svg';
import { PairInterface } from '../../types/pairs';
import { Question } from '../question';


const PairInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3rem;
    ${({ theme }) => theme.media.small} {
        margin-bottom: 0rem;
    } 
`;

const InformationContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0px;
    margin-top: 3.25rem;
    ${({ theme }) => theme.media.small} {
        grid-template-columns: 1fr 1fr; 
        grid-template-rows: 1fr 1fr 1fr; 
        gap: 0.75rem 1.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    } 
`;

const DesktopInformationContainer = styled(InformationContainer)`

${({ theme }) => theme.media.small} {
    display: none;
} 

`

const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    width: 100%;
    position: relative;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.uiColor1};
    font-weight: 500;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.uiColor5};
    border-radius: 0.75rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
`;

const HoverInfo = styled.div<{ message: string; }>`
    display: flex;

    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
    color: ${({ theme }) => theme.colors.uiColor3};
    & svg {
        width: 1rem;
        height: 1rem;
    }

    & >span {
        font-size: 0.7rem;
        font-weight: 500;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        z-index: 99;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.uiColor2};
        bottom: 1.2rem;
        opacity: 0;
        color: white;
        text-decoration: none;
        white-space: nowrap;
        transition: all 0.15s ${({ theme }) => theme.transitions.main};
        &:after {
            content: '${({ message }) => message}';
        }
        pointer-events: none;
        text-transform: uppercase;
        padding: 0.375rem 0.5rem;
    }

    &:hover {
        & >span {
            opacity: 1;
            transition: all 0.15s ${({ theme }) => theme.transitions.main};
            transition-delay: 0;
        }
    }

    ${({ theme }) => theme.media.small} {
        display: none;
    } 
    
`;


const InfoTitle = styled.span`
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.625rem;
    color: ${({ theme }) => theme.colors.brandColor1}
`

const SpecialHolder = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

type Props = Pick<PairInterface, 'address' | 'indicators' | 'currentReserves' | 'initialReserves' | 'timestamp' | 'baseToken' | 'isToken0'>;

export default class PairInfo extends React.Component<Props> {

    render() {
        const { baseToken, isToken0, currentReserves, initialReserves, indicators, timestamp } = this.props;
        const fractionDigits = baseToken.symbol === 'WBNB' ? 1 : 0;
        const baseSymbol = baseToken.symbol === 'WBNB' ? 'BNB' : baseToken.symbol;
        const initialLiquidity = parseFloat(
            Web3.utils.fromWei(initialReserves[isToken0 ? 'reserve1' : 'reserve0'])
        );
        const currentLiquidity = parseFloat(
            Web3.utils.fromWei(currentReserves[isToken0 ? 'reserve1' : 'reserve0'])
        );
        return (
            <PairInfoContainer>
                <Question title="Show info">
                <InformationContainer>
                    <Info><HoverInfo message='Time since trading opened'><QuestionSvg /><span></span></HoverInfo><InfoTitle>Time Live</InfoTitle><Timestamp timestamp={timestamp} /></Info>
                    <Info><HoverInfo message='Initial liquidity of base token'><QuestionSvg /><span></span></HoverInfo><InfoTitle>Ini. Liquidity</InfoTitle>
                        {
                            initialLiquidity > 10000
                                ? `${simplify(initialLiquidity.toString())} ${baseSymbol}`
                                : `${initialLiquidity.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: fractionDigits,
                                    maximumFractionDigits: fractionDigits,
                                }).replace('$', '')} ${baseSymbol}`
                        }
                    </Info>
                    <Info><HoverInfo message='Current liquidity of base token'><QuestionSvg /><span></span></HoverInfo><InfoTitle>cur. Liquidity</InfoTitle>
                        {
                            currentLiquidity > 10000
                                ? `${simplify(currentLiquidity.toString())} ${baseSymbol}`
                                : `${currentLiquidity.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: fractionDigits,
                                    maximumFractionDigits: fractionDigits,
                                }).replace('$', '')} ${baseSymbol}`
                        }
                    </Info>
                    <Info><HoverInfo message='Number of swaps made'><QuestionSvg /><span></span></HoverInfo><InfoTitle>Buys / Sells</InfoTitle>{indicators.buys}/{indicators.sells}</Info>
                    <Info><HoverInfo message='Holders at launch'><QuestionSvg /><span></span></HoverInfo><InfoTitle>Ini. holders</InfoTitle>{indicators.holders ? indicators.holders.initial : '-'}</Info>
                    <Info><HoverInfo message='Holder growth'><QuestionSvg /><span></span></HoverInfo><InfoTitle>New Holders</InfoTitle><SpecialHolder>
                        <>{indicators.holders ? `${indicators.holders.growth >= 0 ? '+' : '-'}${Math.abs(indicators.holders.growth)}` : '-'}</>
                    </SpecialHolder></Info>
                </InformationContainer>
                </Question>
                <DesktopInformationContainer>
                    <Info><HoverInfo message='Time since trading opened'><QuestionSvg /><span></span></HoverInfo><InfoTitle>Time Live</InfoTitle><Timestamp timestamp={timestamp} /></Info>
                    <Info><HoverInfo message='Initial liquidity of base token'><QuestionSvg /><span></span></HoverInfo><InfoTitle>Ini. Liquidity</InfoTitle>
                        {
                            initialLiquidity > 10000
                                ? `${simplify(initialLiquidity.toString())} ${baseSymbol}`
                                : `${initialLiquidity.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: fractionDigits,
                                    maximumFractionDigits: fractionDigits,
                                }).replace('$', '')} ${baseSymbol}`
                        }
                    </Info>
                    <Info><HoverInfo message='Current liquidity of base token'><QuestionSvg /><span></span></HoverInfo><InfoTitle>cur. Liquidity</InfoTitle>
                        {
                            currentLiquidity > 10000
                                ? `${simplify(currentLiquidity.toString())} ${baseSymbol}`
                                : `${currentLiquidity.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: fractionDigits,
                                    maximumFractionDigits: fractionDigits,
                                }).replace('$', '')} ${baseSymbol}`
                        }
                    </Info>
                    <Info><HoverInfo message='Number of swaps made'><QuestionSvg /><span></span></HoverInfo><InfoTitle>Buys / Sells</InfoTitle>{indicators.buys}/{indicators.sells}</Info>
                    <Info><HoverInfo message='Holders at launch'><QuestionSvg /><span></span></HoverInfo><InfoTitle>Ini. holders</InfoTitle>{indicators.holders ? indicators.holders.initial : '-'}</Info>
                    <Info><HoverInfo message='Holder growth'><QuestionSvg /><span></span></HoverInfo><InfoTitle>New Holders</InfoTitle><SpecialHolder>
                        <>{indicators.holders ? `${indicators.holders.growth >= 0 ? '+' : '-'}${Math.abs(indicators.holders.growth)}` : '-'}</>
                    </SpecialHolder></Info>
                </DesktopInformationContainer>
            </PairInfoContainer>
        )
    }
}