import { Observable, from } from 'rxjs';
import { filter, withLatestFrom, map, mergeMap } from 'rxjs/operators';
import { EpicWithTypes, isActionOf, RootState } from 'typesafe-actions';
import * as pairsActions from './actions';
import * as interfaceActions from '../interface/actions';
import { PageTab } from '../interface/state';
import { StateObservable } from 'redux-observable';
import { PairInterface } from '../../types/pairs';

const withMissingPageData = (state$: StateObservable<RootState>,) => (source$: Observable<PairInterface[]>) => {
    return source$.pipe(
        withLatestFrom(state$),
        map(([pairs, state]) => state.interface.openPages.reduce(
            (acc: PageTab[], tab) => {
                if (!!tab.symbol) {
                    return acc;
                } else {
                    const pair = pairs.find(({ address }) => tab.address === address);
                    if (pair) {
                        const newTab: PageTab = { ...tab, tokenAddress: pair.token.address, symbol: pair.token.symbol };
                        return acc.concat(newTab);
                    } else {
                        return acc;
                    }
                }
            },
            [])
        ),
    );
}

export const initDashboardEpic: EpicWithTypes = (action$, state$, { addressUtils }) =>
    action$.pipe(
        filter(isActionOf(pairsActions.initDashboard)),
        map(({ payload }) => payload),
        withMissingPageData(state$),
        mergeMap((tabs) => from(tabs).pipe(
            map(interfaceActions.openPage.success)
        ))
    );

export const updateDashboardEpic: EpicWithTypes = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(pairsActions.updateDashboard)),
        map(({ payload }) => payload.newPairs.concat(payload.updatedPairs).concat(payload.trending || [])),
        withMissingPageData(state$),
        mergeMap((tabs) => from(tabs).pipe(
            map(interfaceActions.openPage.success)
        ))
    );

export const updateCustomPairsEpic: EpicWithTypes = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(pairsActions.updateCustomPairs)),
        map(({ payload }) => payload),
        withMissingPageData(state$),
        mergeMap((tabs) => from(tabs).pipe(
            map(interfaceActions.openPage.success)
        ))
    );