import React from 'react';
import styled from 'styled-components/macro';
import Layout from '../components/layout/layout';

const BackgroundContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.lightBrown};
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const NotFoundPageContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
    padding: 1rem;
    background: url("/images/wave-bottom.svg"), url("/images/wave-top.svg");;
    background-position: 0% 0%, 0% 100%;
    background-repeat: no-repeat, no-repeat;
    background-size: contain, contain;
    padding-top: 20rem;
    color: white;
`;

interface Props {

}

interface State {

}

export default class NotFoundPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <Layout>
                <BackgroundContainer>

                <NotFoundPageContainer>
                    <h1>Page not found</h1>
                </NotFoundPageContainer>
                </BackgroundContainer>
            </Layout>
        );
    }
}