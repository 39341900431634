import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import BN from 'bn.js';
import { ethers } from 'ethers';
import ExpressV05Toaster from '../constants/contracts/toasterV05';
import { getWeb3 } from "../utils/web3";
import getWallet from '../utils/wallet';
import ERC20 from '../constants/contracts/ERC20';
import { decodeRawEventLog } from '../utils/coding';
import WBNB from '../constants/contracts/WBNB';
import { fromWeiCustomDecimals } from '../utils/numbers';
import { BUSDAddress, WBNBAddress, WBNBBUSDAddress } from '../constants/addresses';

import PancakePairV2 from '../constants/contracts/pancakePairV2';
export interface SwapProps {
    amount: BN;
    path: string[];
    slippage: string;
    gasPrice?: BN;
}

export interface SwapResult {
    isBuy: boolean;
    bnbIn: BN;
    bnbOut: BN;
    tokenIn: BN;
    tokenOut: BN;
    gasPrice: BN;
    gasUsed: BN;
    transactionHash: string;
    tokenAddress: string;
}

export interface ApproveProps {
    address: string;
    gasPrice?: BN;
}

export interface ApproveResult {
    address: string;
    allowance: BN;
    gasPrice: BN;
    gasUsed: BN;
}

class ToeRouter {
    private web3!: Web3;
    private routerContract!: Contract;
    private wallet = getWallet();

    getWeb3 = async (): Promise<Web3> => {
        if (!!this.web3) {
            return Promise.resolve(this.web3);
        } else {
            this.web3 = await getWeb3();
            return Promise.resolve(this.web3);
        }
    }

    router = async (): Promise<Contract> => {
        if (!!this.routerContract) {
            return Promise.resolve(this.routerContract);
        } else {
            const web3 = await this.wallet.getWeb3();
            this.routerContract = new web3.eth.Contract(ExpressV05Toaster.abi, ExpressV05Toaster.address);
            return Promise.resolve(this.routerContract);
        }
    }

    calculateAmountOut = async (props: SwapProps, isBuy: boolean): Promise<BN> => {
        const { path, amount, slippage } = props;
        const contract = await this.router();
        let withSlippage = new BN(0);
        try {
            if (slippage !== 'auto') {
                const amountsOut = await contract.methods.getAmountsOut(
                    amount,
                    path
                ).call();
                const amountOut = new BN(amountsOut[amountsOut.length - 1]);
                withSlippage = amountOut.mul(
                    new BN(100).sub(new BN(slippage))
                ).div(new BN(100));
            } else {
                let simAmountOut = new BN(0);
                if (isBuy) {
                    simAmountOut = await contract.methods.simulateSwapExactETHForTokensSupportingFeeOnTransferTokens(
                        new BN(0),
                        path,
                        this.wallet.address,
                        Date.now() + 20000
                    ).call({
                        from: this.wallet.address,
                        value: amount,
                        //gasPrice,
                    });
                } else {
                    simAmountOut = await contract.methods.simulateSwapExactTokensForETHSupportingFeeOnTransferTokens(
                        amount,
                        new BN(0),
                        path,
                        this.wallet.address,
                        Date.now() + 20000
                    ).call({
                        from: this.wallet.address,
                        value: new BN(0)
                        //gasPrice,
                    });
                }
                withSlippage = new BN(simAmountOut).mul(
                    new BN(100).sub(new BN(10))
                ).div(new BN(100));

            }
            return Promise.resolve(withSlippage);
        } catch (error) {
            console.debug('calculateAmountOut error');
            return Promise.reject('Unexpected error');
        }
    }

    parseError = (error: any): string => {
        let parsedError = error;
        try {
            const errorObject = error.message as string;
            parsedError = JSON.parse(errorObject.slice(errorObject.indexOf('{')));
        } catch { }

        try {
            const messageParts = parsedError.message.split(':');
            const errorMessage = messageParts[messageParts.length - 1];
            return errorMessage;
        } catch (error) {
            return 'Execution reverted';
        }
    }

    simulate = async (props: SwapProps, amountOut: BN, isBuy: boolean): Promise<void> => {
        try {
            const contract = await this.router();
            if (isBuy) {
                await contract.methods.simulateSwapExactETHForTokensSupportingFeeOnTransferTokens(
                    amountOut,
                    props.path,
                    this.wallet.address,
                    Date.now() + 20000
                ).call({
                    from: this.wallet.address,
                    value: props.amount,
                    //gasPrice,
                });
            } else {
                await contract.methods.simulateSwapExactTokensForETHSupportingFeeOnTransferTokens(
                    props.amount,
                    amountOut,
                    props.path,
                    this.wallet.address,
                    Date.now() + 20000
                ).call({
                    from: this.wallet.address,
                    value: new BN(0),
                    //gasPrice.
                });
            }
            return Promise.resolve();
        } catch (error: any) {
            const errorMessage = this.parseError(error);
            return Promise.reject(errorMessage);
        }
    }

    executeBuy = async (props: SwapProps, amountOut: BN): Promise<SwapResult> => {
        const tokenAddress = props.path[props.path.length - 1];
        const contract = await this.router();
        const account = this.wallet.address;
        try {
            const result = await contract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(
                amountOut,
                props.path,
                account,
                Date.now() + 20000
            ).send({
                from: account,
                value: props.amount,
                //gasPrice,
            });
            const events = Object.keys(result.events).map((key) => result.events[key]);

            const tokenTransfers = events.filter(
                (e: any) => e.raw.topics.includes(ERC20.events.Transfer.signature) && e.address === tokenAddress
            );

            const deposits = events.filter(
                (e: any) => e.raw.topics.includes(WBNB.events.Deposit.signature)
            );

            const { tokenIn, tokenOut }: { tokenIn: BN, tokenOut: BN } = tokenTransfers.reduce(
                (acc, e: any) => {
                    const { from, to, value } = decodeRawEventLog(e.raw, ERC20.events.Transfer);
                    if (from === account) {
                        return {
                            ...acc,
                            tokensOut: acc.tokenOut.add(new BN(value))
                        }
                    } else if (to === account) {
                        return {
                            ...acc,
                            tokenIn: acc.tokenIn.add(new BN(value))
                        }
                    } else {
                        return acc;
                    }
                },
                {
                    tokenIn: new BN(0),
                    tokenOut: new BN(0),
                }
            );
            const bnbOut = deposits.reduce(
                (acc, e: any) => {
                    const { dst, wad } = decodeRawEventLog(e.raw, WBNB.events.Deposit);
                    if (dst === ExpressV05Toaster.address) {
                        return acc.add(new BN(wad));
                    }
                    return acc;
                },
                new BN(0)
            );

            return Promise.resolve({
                isBuy: true,
                bnbIn: new BN(0),
                bnbOut,
                tokenIn,
                tokenOut,
                gasPrice: new BN(5000000000),
                gasUsed: new BN(result.gasUsed),
                transactionHash: result.transactionHash,
                tokenAddress,
            });
        } catch (error) {
            const errorMessage = this.parseError(error);
            return Promise.reject(errorMessage);
        }
    }

    executeSell = async (props: SwapProps, amountOut: BN): Promise<SwapResult> => {
        const tokenAddress = props.path[0];
        const contract = await this.router();
        const account = this.wallet.address;
        try {
            const result = await contract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(
                props.amount,
                amountOut,
                props.path,
                account,
                Date.now() + 20000
            ).send({
                from: account,
                value: new BN(0),
                //gasPrice,
            });
            const events = Object.keys(result.events).map((key) => result.events[key]);

            const tokenTransfers = events.filter(
                (e: any) => e.raw.topics.includes(ERC20.events.Transfer.signature) && e.address === tokenAddress
            );
            const withdrawals = events.filter(
                (e: any) => e.raw.topics.includes(WBNB.events.Withdrawal.signature)
            );

            const { tokenIn }: { tokenIn: BN, tokenOut: BN } = tokenTransfers.reduce(
                (acc, e: any) => {
                    const { from, to, value } = decodeRawEventLog(e.raw, ERC20.events.Transfer);
                    if (from === account) {
                        return {
                            ...acc,
                            tokenOut: acc.tokenOut.add(new BN(value))
                        }
                    } else if (to === account) {
                        return {
                            ...acc,
                            tokenIn: acc.tokenIn.add(new BN(value))
                        }
                    } else {
                        return acc;
                    }
                },
                {
                    tokenIn: new BN(0),
                    tokenOut: new BN(0),
                }
            );

            const bnbIn = withdrawals.reduce(
                (acc, e: any) => {
                    const { src, wad } = decodeRawEventLog(e.raw, WBNB.events.Withdrawal);
                    if (src === ExpressV05Toaster.address) {
                        return acc.add(new BN(wad));
                    }
                    return acc;
                },
                new BN(0)
            );
            return Promise.resolve({
                isBuy: false,
                bnbOut: new BN(0),
                bnbIn,
                tokenOut: props.amount,
                tokenIn,
                gasPrice: new BN(5000000000),
                gasUsed: new BN(result.gasUsed),
                transactionHash: result.transactionHash,
                tokenAddress,
            });
        } catch (error) {
            const errorMessage = this.parseError(error);
            return Promise.reject(errorMessage);
        }
    }

    buy = async (props: SwapProps): Promise<SwapResult> => {
        if (!this.wallet.address) {
            return Promise.reject('No wallet connected');
        }
        try {
            const amountOut = await this.calculateAmountOut(props, true);
            await this.simulate(props, amountOut, true); // Simulate before
            const swapResult = await this.executeBuy(props, amountOut);
            return Promise.resolve(swapResult);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    sell = async (props: SwapProps): Promise<SwapResult> => {
        if (!this.wallet.address) {
            return Promise.reject('No wallet connected');
        }
        try {
            const amountOut = await this.calculateAmountOut(props, false);
            await this.simulate(props, amountOut, false); // Simulate before
            const swapResult = await this.executeSell(props, amountOut);
            return Promise.resolve(swapResult);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    getTokenBalance = async (address: string) => {
        if (!this.wallet.address) {
            return Promise.reject('No wallet connected, getTokenBalance');
        }
        try {
            const web3 = await getWeb3();
            const contract = new web3.eth.Contract(ERC20.abi, address);
            const result = await contract.methods.balanceOf(this.wallet.address).call();
            return new BN(result);
        } catch (error: any) {
            return Promise.reject(error.message);
        }
    }

    getAllowance = async (address: string): Promise<BN> => {
        if (!this.wallet.address) {
            return Promise.reject('No wallet connected, getAllowance');
        }
        try {
            const web3 = await getWeb3();
            const contract = new web3.eth.Contract(ERC20.abi, address);
            // We should maybe simulate here
            const result = await contract.methods.allowance(this.wallet.address, ExpressV05Toaster.address).call();
            return Promise.resolve(new BN(result));
        } catch (error: any) {
            return Promise.reject(error.message);
        }
    }

    approve = async (props: ApproveProps): Promise<ApproveResult> => {
        const { address } = props;
        const wallet = getWallet();
        if (!wallet.address) {
            console.debug('No wallet connected, approve');
            return Promise.reject('No wallet connected, approve');
        }
        try {
            const web3 = await wallet.getWeb3() as Web3;
            const contract = new web3.eth.Contract(ERC20.abi, address);
            // We should maybe simulate here
            const result = await contract.methods
                .approve(ExpressV05Toaster.address, ethers.constants.MaxUint256)
                .send({
                    from: wallet.address,
                    //gasPrice,
                });
            return Promise.resolve({
                address,
                allowance: new BN(ethers.constants.MaxUint256.toString()),
                gasUsed: new BN(result.gasUsed),
                gasPrice: new BN(5000000000), //gasPrice
            });
        } catch (error: any) {
            return Promise.reject(error.message);
        }
    }

    getBNBPrice = async (): Promise<number> => {
        const web3 = await getWeb3();
        try {
            const isToken0 = WBNBAddress < BUSDAddress;
            const contract = new web3.eth.Contract(PancakePairV2.abi, WBNBBUSDAddress);
            const { _reserve0, _reserve1 } = await contract.methods.getReserves().call();
            const WETH = isToken0 ? _reserve0 : _reserve1;
            const BUSD = isToken0 ? _reserve1 : _reserve0;
            const currencyAmount = fromWeiCustomDecimals(WETH, 18);
            const fiatAmount = fromWeiCustomDecimals(BUSD, 18);
            const price = parseFloat(fiatAmount) / parseFloat(currencyAmount);
            return Promise.resolve(price);
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

const toeRouter = new ToeRouter();

export default toeRouter;