export interface Theme {
    fonts: {
        [key: string]: string;
    };
    colors: {
        [key: string]: string;
    };
    layout: {
        [key: string]: string;
    }
    media: {
        [key: string]: string;
    };
    transitions: {
        [key: string]: string;
    }
    ui: {
        icons: {
            sizes: {
                [key: string]: string;
            };
        };
    };
    z: {
        [key: string]: number;
    }
}

const breakpoints = {
    extrasmall: '660px',
    small: '1079px',
    medium: '1280px',
    large: '1440px',
}


// Theme used in ThemeProvider @ _app.tsx
export const theme: Theme = {
    fonts: {
        main: 'Graphik',
        title: "paralucent-text, sans-serif",
        money: "'Roboto Mono', monospace",
        numbers: "'Azeret Mono', monospace"
    },

    colors: {
        //NYJIR LITIR
        brandColor1: '#FFE1CD',
        brandColor2: '#CFBAF0',
        brandColor3: '#8EECF5',
        brandColor4: '#FBF8CC',
        uiColor1: '#0B0E13',
        uiColor2: '#29313E',
        uiColor3: '#4B586F',
        uiColor4: '#808B9F',
        uiColor5: '#FFFFFF',
        uiColor6: '#161C24',
        uiColor7: '#ECEEF1',
        triggerColor1: '#FF2951',
        triggerColor2: '#FFBFCB',
        triggerColor3: '#FF9959',
        triggerColor4: '#FFE1CD',
        triggerColor5: '#33EC7E',
        triggerColor6: '#C2F9D8',
        triggerColor7: '#347AFC',
        triggerColor8: '#C2D7FE',
        triggerColor9: '#FA245F',
        triggerColor10: '#1A5B21',
        shadow1: '0px 8px 30px rgba(251, 248, 204, 0.3)',

        //GAMLIR LITIR
        offWhite: '#FCFCFC',
        darkerBackgroundGray: '#141414',
        evenDarkerBackgroundGray: '#121212',
        backgroundGray: '#1a1a1a',
        platinumPurple: '#F0DFF6',
        borderGray: '#707070',
        darkGray: '#1F1F1F',
        lightRed: '#FF7070',
        red: '#D50000',
        jet: '#292929',
        darkRed: '#520000',
        lightGreen: '#B2FF59',
        green: '#64DD17',
        darkGreen: '#558B2F',
        darkYellow: '#ac9e27',
        pancakeOrange: '#faa13a',
        lightYellow: '#FFF176',
        gunmetal: '#474747',
    },
    layout: {
        desktopWidth: `
            max-width: 1400px;
            //max-width: 100%;
            margin: 0 auto;
        `,
        gameWidth: '60rem',
        hudHeight: '5rem',
        headerHeight: '6rem',
        headerWidth: '78rem',
        footerHeight: '6rem',
        footerWidth: '78rem',
    },
    ui: {
        icons: {
            sizes: {
                small: '40px',
                medium: '60px',
                large: '100px',
            },
        },
    },
    media: {
        extrasmall: `@media (max-width: ${breakpoints.extrasmall})`,
        extrasmallUp: `@media (min-width: ${breakpoints.extrasmall})`,
        small: `@media (max-width: ${breakpoints.small})`,
        smallUp: `@media (min-width: ${breakpoints.small})`,
        mediumDown: `@media (max-width: ${breakpoints.medium})`,
        mediumUp: `@media (min-width: ${breakpoints.medium})`,
        largeDown: `@media (max-width: ${breakpoints.large})`,
        largeUp: `@media (min-width: ${breakpoints.large})`,
    },
    transitions: {
        main: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    z: {
        hidden: 0,
        bottom: 1,
        middle: 2,
        top: 3,
        override: 4,
    }
}