import React from 'react';
import styled from 'styled-components/macro';
import Web3 from 'web3';
import BN from 'bn.js';

import FlipMove from 'react-flip-move';

import { PairInterface, Swap } from '../../types/pairs';

const SwapsContainer = styled.div`
    grid-area: Swaps;
    width: 100%;
    max-height: 50rem;
    min-width: 10rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 2rem;
`;

const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.uiColor2};
    margin-bottom: 0.8rem;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 1.5rem;
`;

const TimePassed = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    border-radius: 0.25rem;
    padding: 3px 9px;
    margin-top: 0.2rem;
    background-color: ${({ theme }) => theme.colors.uiColor2};
`;

const LeftSwapContainer = styled.div`
    display: grid;
    grid-template-columns: 4rem auto;
    align-items: center;
    column-gap: 1rem;
    & span {
        color: white !important;
        line-height: 1.3rem;
        font-family: ${({ theme }) => theme.fonts.numbers};
    }

`;

const BuySellContainer = styled.div<{ up: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, up }) => theme.colors[up ? 'triggerColor5' : 'triggerColor1']};
    padding: 0.35rem 0.6rem;
    color: ${({ theme }) => theme.colors.uiColor1};
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.875rem;
`;

type Props = {
    swaps: Swap[];
    buys: number;
    sells: number;
} & Pick<PairInterface, 'baseToken' | 'isToken0'>;

interface State {
    changed: boolean;
}

export default class Swaps extends React.Component<Props, State> {
    private nodeRef: React.RefObject<HTMLDivElement>;
    constructor(props: Props) {
        super(props);

        this.state = {
            changed: false,
        }

        this.nodeRef = React.createRef();
    }

    componentDidMount = () => {

    }

    render() {
        const { baseToken, isToken0, swaps, buys, sells } = this.props;
        const symbol = baseToken.symbol === 'WBNB' ? 'BNB' : 'BUSD';
        return (
            <SwapsContainer>

                <TitleContainer>
                    <Title>Swaps</Title>
                    <TimePassed> <span>{buys + sells} total</span></TimePassed>
                </TitleContainer>
                <FlipMove
                    staggerDurationBy="20"
                    duration={250}
                    enterAnimation="fade" leaveAnimation="fade"
                >
                    {
                        swaps.map((swap, i) =>
                            <div key={JSON.stringify(swap)}>
                                <SwapRow swap={swap} symbol={symbol} isToken0={isToken0} />
                            </div>)
                    }
                    {
                        swaps.length === 0 && (
                            <span>
                                No swaps
                            </span>
                        )
                    }
                </FlipMove>
            </SwapsContainer>
        )
    }
}

const SwapRowContainer = styled.a<{ isBuy: boolean }>`
    display: flex;
    font-size: 1rem;
    border-radius: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding: 1rem;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    & span:nth-child(1) {
        margin-right: 2rem;
    }
    & span {
        width: 100%;
        padding: 0.1 1rem;
        font-family: ${({ theme }) => theme.fonts.numbers};
        
    }

    &:hover {
            background-color: ${({ theme }) => theme.colors.uiColor6};
    }
    & span:nth-child(2) {
        color: ${({ theme, isBuy }) => theme.colors[isBuy ? 'triggerColor5' : 'triggerColor1']};
        text-align: right;
    }
`;

interface SwapProps {
    swap: Swap;
    symbol: string;
    isToken0: boolean;
}

const SwapRow: React.FunctionComponent<SwapProps> = ({
    swap,
    symbol,
    isToken0,
}) => {
    const WETH = Web3.utils.toBN(isToken0 ? swap.amount1In : swap.amount0In).sub(Web3.utils.toBN(isToken0 ? swap.amount1Out : swap.amount0Out));
    const isBuy = WETH.gt(new BN(0));
    const amount = parseFloat(Web3.utils.fromWei(WETH.abs().toString())).toFixed(symbol === 'BNB' ? 3 : 1);

    const getSwapTime = (timestamp: number): string => {
        const date = new Date(timestamp * 1000);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const hoursString = hours === 0 ? '00' : hours < 10 ? `0${hours}` : hours;
        const minutesString = minutes === 0 ? '00' : minutes < 10 ? `0${minutes}` : minutes;
        const secondsString = seconds === 0 ? '00' : seconds < 10 ? `0${seconds}` : seconds;
        return `${hoursString}:${minutesString}:${secondsString}`;
    }

    return (
        <SwapRowContainer
            href={`https://bscscan.com/tx/${swap.transactionHash}`}
            target={'_blank'}
            rel={'noreferrer'}
            isBuy={isBuy}
        >
            <LeftSwapContainer>
                <BuySellContainer up={isBuy}>{isBuy ? 'Buy' : 'Sell'}</BuySellContainer>
                <span>{getSwapTime(swap.timestamp)}</span>
            </LeftSwapContainer>

            <span>{amount} {symbol}</span>
        </SwapRowContainer>
    )
}