import * as React from 'react';
import styled from 'styled-components/macro';

const PoolStatusContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    //background-color: #ffffff20;
    justify-content: space-between;
    white-space: nowrap;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.ivory};
`;

const PriceChangeContainer = styled.span<{ up: boolean }>`
    font-size: 0.875rem;
    display: flex;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background-color: ${({ theme, up }) => theme.colors[up ? 'triggerColor5' : 'triggerColor1']};
    text-align: center;
    padding: 4px 9px;
    color: ${({ theme }) => theme.colors.uiColor1};
    border-radius: 4px;
    letter-spacing: 0.03em;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
`;

const Item = styled.span`
    background-color: ${({ theme }) => theme.colors.uiColor2};
    border-radius: 0.25rem;
    font-weight: 500;
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.1rem;
    font-weight: 500;
    font-size: 0.875rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding: 0.375rem 0.7rem;

    &>span {
        letter-spacing: 0.05em;
        
    }
`;

type Props = {
    isOld: boolean;
    className?: string;
    isRug: boolean;
    priceChange: number;
    ath: number;
}

export const PoolStatus: React.FC<Props> = ({ className, isRug, priceChange, isOld, ath }) => {
    return (
        <PoolStatusContainer className={className}>
            {
                isOld ? (
                    <div></div>
                ) : (
                    <Item>
                        <span>All time high:</span> {((ath - 1) * 100).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 1,
                        }).replace('$', '')}%
                    </Item>
                )
            }
            <PriceChangeContainer up={!isRug && priceChange >= 1}>
                {((priceChange - 1) * 100).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 1,
                }).replace('$', '')}%
            </PriceChangeContainer>
        </PoolStatusContainer>
    )
}

export default PoolStatus;