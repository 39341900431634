import * as React from 'react';
import Web3 from 'web3';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { RootState } from 'typesafe-actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as interfaceActions from '../../store/interface/actions';
import ContractAddress from './contract';
import { PairInterface } from '../../types/pairs';
import { simplify } from '../../utils/numbers';
import colorFromAddress from '../../utils/colors';

const TokenItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    border-radius: 1rem;
    height: 11rem;
    cursor: pointer;
`;

const PriceChangeContainer = styled.span`
   
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;    
    text-align: center;
    background-color: ${({ theme }) => theme.colors.triggerColor5};
    padding: 4px 9px;
    color: ${({ theme }) => theme.colors.uiColor1};
    border-radius: 4px;
    letter-spacing: 0.03em;
`;

const TokenContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const NameLogoPrice = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    ${({ theme }) => theme.media.small} {
        width: 25rem;
    }
    
`;

const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
`;

const Title = styled.div`
    
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.8rem;

    `;


const Volume = styled.div`
    display: flex;
    flex-direction: column;
    
    & span {
        
        margin-bottom: 0.3rem;
    }
`;

const Swaps = styled.div`
    display: flex;
    flex-direction: column;
    
    & span {
        
        margin-bottom: 0.3rem;
    }
`;

const Holders = styled.div`
    display: flex;
    flex-direction: column;
    
    & span {
        
        margin-bottom: 0.3rem;
    }
`;

const NameLogo = styled.div`
    display: flex;
    margin-top: 1.875rem;
    margin-left: 1.5rem;
`;

const OnlyPrice = styled.div`
    padding-left: 0.5rem;
    margin-top: 1.875rem;
    margin-right: 1.5rem;
`;


const Information = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 500;
    font-size: 1rem;
    & span {
        font-size: 0.625rem;
        font-weight: 500;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.uiColor3};
        letter-spacing: 0.03em;
        margin-top: 0.5rem;
    }
    
`;

const LogoContainer = styled.div<{ colorHex?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ colorHex }) => colorHex};
    text-transform: uppercase;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
    line-height: 1.8rem;
    padding-top: 0.15rem;

    color: ${({ theme }) => theme.colors.uiColor1};

`;

const dispatchProps = {
    openPage: interfaceActions.openPage.request,
};

type Props = {
    pair: PairInterface;
} & ReturnType<typeof mapStateToProps> & typeof dispatchProps & RouteComponentProps;

interface State {
    copied: boolean;
}

class TrendingItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            copied: false,
        }
    }

    handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, pathname: string) => {
        if (e.button === 0 && e.buttons === 1) {
            e.preventDefault();
            e.stopPropagation();
            return;
        } else if (e.button === 2 && e.buttons === 2) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (e.metaKey || e.shiftKey || (e.button === 1 && e.buttons === 4)) {
            e.preventDefault();
            e.stopPropagation();
            this.props.openPage(pathname);
        } else {
            this.props.history.push({
                pathname,
                state: { prevPath: '/' }
            });
        }
    }

    getTokenString = (token: string) => {
        const first = token.slice(0, 6);
        const second = token.slice(-3);

        return `${first}...${second}`;
    }

    copyAddress = (address: string) => {
        navigator.clipboard.writeText(address);
        this.setState({ copied: true }, () => setTimeout(() => this.setState({ copied: false }), 1500))
    }

    render() {
        const {
            address,
            token,
            baseToken,
            indicators: {
                buys,
                sells,
                holders,
            },
            data: {
                priceChanges,
            },
            isToken0,
            currentReserves,
        } = this.props.pair;

        const fractionDigits = baseToken.symbol === 'WBNB' ? 1 : 0;
        const priceChange = priceChanges[priceChanges.length - 1] || 0;
        const currentLiquidity = Web3.utils.fromWei(currentReserves[isToken0 ? 'reserve1' : 'reserve0']);
        const liquidity = parseFloat(currentLiquidity).toFixed(fractionDigits);
        const pathname = `/pair/${address}`;

        let fixedPriceChange = 'X';
        try {
            fixedPriceChange = priceChange.change.toFixed(2);
        } catch (error) { }
        const { symbol } = token;
        return (
            <TokenItem
                onClick={(e) => this.handleClick(e, pathname)}
                onMouseDown={(e) => this.handleClick(e, pathname)}
            >
                <TokenContainer>
                    <NameLogoPrice>
                        <NameLogo>
                            <LogoContainer colorHex={colorFromAddress(token.address)}>{token.symbol.trim().slice(0, 1)}</LogoContainer>
                            <NameContainer>
                                <Title>{`${symbol.length > 10 ? symbol.slice(0, 10) + '...' : symbol}`}</Title>
                                <ContractAddress address={token.address}></ContractAddress>
                            </NameContainer>
                        </NameLogo>
                        <OnlyPrice>
                            <PriceChangeContainer>{fixedPriceChange}X</PriceChangeContainer>
                        </OnlyPrice>
                    </NameLogoPrice>
                    <Information>
                        <Volume>
                            <span>Liquidity</span> {`${simplify(liquidity)} ${baseToken.symbol === 'WBNB' ? 'BNB' : baseToken.symbol}`}
                        </Volume>
                        <Swaps>
                            <span>Swaps</span> {buys + sells}
                        </Swaps>
                        <Holders>
                            <span>Holders</span>  {holders ? `${holders.growth >= 0 ? '+' : '-'}${Math.abs(holders.growth)}` : '-'}
                        </Holders>
                    </Information>
                </TokenContainer>
            </TokenItem>
        );
    }
}

const mapStateToProps = (state: RootState) => ({

});

export default connect(
    mapStateToProps,
    dispatchProps
)(withRouter(TrendingItem));