import Web3 from 'web3';
import { PageTab } from '../store/interface/state';
import { PairsState } from '../store/pairs/state';

export const pageTabFromPathname = ({ dashboardPairs, trendingPairs, customPairs }: PairsState, pathname: string): PageTab => {
    try {
        const checksumAddress = addressFromPathname(pathname);
        const pairs = dashboardPairs.concat(trendingPairs).concat(customPairs);
        const pair = pairs.find(({ address }) => address === checksumAddress);
        if (pair) {
            return {
                pathname,
                address: checksumAddress,
                tokenAddress: pair.token.address,
                symbol: pair.token.symbol,
            }
        } else {
            return {
                pathname,
                address: checksumAddress,
            }
        }
    } catch (error) {
        throw error;
    }
}

export const addressFromPathname = (pathname: string): string => {
    try {
        const address = pathname.split('/').slice(-1)[0];
        return toChecksumAddress(address);
    } catch (error) {
        throw Object.assign(
            new Error('invalidAddress'),
            { pathname }
        );
    }
}

export const toChecksumAddress = (address: string): string => {
    try {
        const checksum = Web3.utils.toChecksumAddress(address);
        return checksum;
    } catch (error) {
        throw Object.assign(
            new Error('invalidAddress'),
            { address }
        );
    }
}