import * as React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setHidden } from '../../store/interface/actions';

import { ReactComponent as Analyze } from '../svg/analyze.svg';
import { ReactComponent as Blind } from '../svg/blind.svg';
import { RootState } from 'typesafe-actions';

const ActionsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0.1rem;
`;

const MoonButton = styled.div<{ enabled: boolean }>`
    width: 2rem;
    height: 2rem;
    border-radius: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.uiColor5};
    font-size: 0.75rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.uiColor5};
    }

    & svg {
        color: ${({ theme, enabled }) => !enabled ? theme.colors.uiColor2 : 'inherit'};
    }
`;

const AnalyzeButton = styled.div`
    width: 2rem;
    height: 2rem;
    border-radius: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.uiColor5};
    font-size: 0.75rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.uiColor5};
    }
`;

interface Props {
    address: string;
    tokenAddress: string;
}

export const Actions: React.FunctionComponent<Props> = ({ address, tokenAddress }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const hiddenPairs = useSelector((state: RootState) => state.interface.hiddenPairs);
    const isHidden = hiddenPairs.includes(address);

    const handleHide = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setHidden(address))
    }

    const handleAnalyze = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(`/analyze/${tokenAddress}`);
        //history.go(0)
    }

    return (
        <ActionsContainer>
            <AnalyzeButton
                onClick={handleAnalyze}
            >
                <Analyze />
            </AnalyzeButton>
            <MoonButton
                onClick={handleHide}
                enabled={!isHidden}
            >
                <Blind />
            </MoonButton>
        </ActionsContainer>
    )
}

export default Actions;