import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Copy } from '../svg/copy.svg';

const Contract = styled.div<{ copied: boolean }>`
    font-size: 0.75rem;
    cursor: pointer;
    & svg {
        margin-left: 0.3rem;
        color: ${({ theme, copied }) => copied ? theme.colors.uiColor5 : theme.colors.uiColor3};
        height: 0.7rem;
        width: 0.6rem;
    }
    &:hover {
        text-decoration: underline;
    }
`;

interface Props {
    address: string;
}
interface State {
    copied: boolean;
}

export default class ContractAddress extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            copied: false,
        }
    }

    getTokenString = (token: string) => {
        const first = token.slice(0, 6);
        const second = token.slice(-3);

        return `${first}...${second}`;
    }

    copyAddress = (address: string) => {
        navigator.clipboard.writeText(address);
        this.setState({ copied: true }, () => setTimeout(() => this.setState({ copied: false }), 1500))
    }

    render() {
        return (
            <Contract copied={this.state.copied} onClick={() => {this.copyAddress(this.props.address)}}>{this.getTokenString(this.props.address)}
                                                <Copy /></Contract>
        )
    }
}
