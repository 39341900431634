import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { RootState } from 'typesafe-actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import TrendingItem from './trending-item';

const TokensContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.6rem;
    padding-left: 5rem;
    padding-right: 5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    ${({ theme }) => theme.media.small} {
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none;
          }
    }
`;

const GhostTokensContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.6rem;
    padding-left: 5rem;
    padding-right: 5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    ${({ theme }) => theme.media.small} {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            display: none;
          }
    }
`;

const GhostTokenItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    border-radius: 1rem;
    height: 11rem;
    
`;

const GhostPriceChangeContainer = styled.span`
   
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;    
    text-align: center;
    background-color: ${({ theme }) => theme.colors.uiColor6};
    padding: 4px 9px;
    color: ${({ theme }) => theme.colors.uiColor1};
    border-radius: 4px;
    letter-spacing: 0.03em;
`;

const GhostTokenContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const GhostNameLogoPrice = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    ${({ theme }) => theme.media.small} {
        width: 25rem;
    }
    
`;

const GhostNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
`;

const GhostTitle = styled.div`
    
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-indent: 400%;
    white-space: nowrap;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.uiColor6};

    `;

const GhostContract = styled.div<{ copied: boolean }>`
    font-size: 0.75rem;
    width: 5rem;
    margin-top: 0.3rem;
    text-indent: 200%;
    white-space: nowrap;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.uiColor6};
    & svg {
        margin-left: 0.3rem;
        color: ${({ theme, copied }) => copied ? theme.colors.uiColor5 : theme.colors.uiColor3};
        height: 0.7rem;
        width: 0.6rem;
    }
`;

const GhostVolume = styled.div`
    display: flex;
    flex-direction: column;
    & span {
        text-indent: 400%;
        white-space: nowrap;
        overflow: hidden;
        background-color: ${({ theme }) => theme.colors.uiColor6};
        width: 5rem;
    }

    & div {
        text-indent: 400%;
        white-space: nowrap;
        overflow: hidden;
        background-color: ${({ theme }) => theme.colors.uiColor6};
        width: 3.5rem;
        margin-top: 0.3rem;
    }
    
`;

const GhostSwaps = styled.div`
    display: flex;
    flex-direction: column;
    & span {
        text-indent: 400%;
        white-space: nowrap;
        overflow: hidden;
        background-color: ${({ theme }) => theme.colors.uiColor6};
        width: 5rem;
    }

    & div {
        text-indent: 400%;
        white-space: nowrap;
        overflow: hidden;
        background-color: ${({ theme }) => theme.colors.uiColor6};
        width: 3.5rem;
        margin-top: 0.3rem;
    }

    
`;

const GhostHolders = styled.div`
    display: flex;
    flex-direction: column;
    & span {
        text-indent: 400%;
        white-space: nowrap;
        overflow: hidden;
        background-color: ${({ theme }) => theme.colors.uiColor6};
        width: 5rem;
    }

    & div {
        text-indent: 400%;
        white-space: nowrap;
        overflow: hidden;
        background-color: ${({ theme }) => theme.colors.uiColor6};
        width: 3.5rem;
        margin-top: 0.3rem;
    }
`;

const GhostNameLogo = styled.div`
    display: flex;
    margin-top: 1.875rem;
    margin-left: 1.5rem;
`;

const GhostOnlyPrice = styled.div`
    padding-left: 0.5rem;
    margin-top: 1.875rem;
    margin-right: 1.5rem;
`;


const GhostInformation = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 500;
    font-size: 1rem;
    & span {
        font-size: 0.625rem;
        font-weight: 500;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.uiColor3};
        letter-spacing: 0.03em;
        margin-top: 0.5rem;
    }
    
`;

const GhostLogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
   
    width: 3rem;
    height: 3rem;
    border-radius: 3.75rem;
    
    background-color: ${({ theme }) => theme.colors.uiColor6};
    & svg {
        width: 1.4rem;
        height: 1.4rem;
    }
`;

const dispatchProps = {

};

type Props = {

} & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

interface State {
    copied: boolean;
}
class Tokens extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            copied: false,
        }
    }

    getTokenString = (token: string) => {
        const first = token.slice(0, 6);
        const second = token.slice(-3);

        return `${first}...${second}`;
    }

    copyAddress = (address: string) => {
        navigator.clipboard.writeText(address);
        this.setState({ copied: true }, () => setTimeout(() => this.setState({ copied: false }), 1500))
    }

    render() {
        const { trending } = this.props;
        if (!trending || trending.length < 3) {
            return (
                <GhostTokensContainer>
                    <GhostTokenItem>
                        <GhostTokenContainer>
                            <GhostNameLogoPrice>
                                <GhostNameLogo>
                                    <GhostLogoContainer></GhostLogoContainer>
                                    <GhostNameContainer>
                                        <GhostTitle>Loading...</GhostTitle>
                                        <GhostContract copied={this.state.copied}>0x9bo...17as2</GhostContract>
                                    </GhostNameContainer>
                                </GhostNameLogo>
                                <GhostOnlyPrice>
                                    <GhostPriceChangeContainer>0X</GhostPriceChangeContainer>
                                </GhostOnlyPrice>
                            </GhostNameLogoPrice>
                            <GhostInformation>
                                <GhostVolume>
                                    <span>Liquidity</span> <div> 45.0 BNB </div>
                                </GhostVolume>
                                <GhostSwaps>
                                    <span>Buys/Sells</span> <div> 2 / 23 </div>
                                </GhostSwaps>
                                <GhostHolders>
                                    <span>Holders</span> <div> 903 </div>
                                </GhostHolders>
                            </GhostInformation>
                        </GhostTokenContainer>
                    </GhostTokenItem>
                    <GhostTokenItem>
                        <GhostTokenContainer>
                            <GhostNameLogoPrice>
                                <GhostNameLogo>
                                    <GhostLogoContainer></GhostLogoContainer>
                                    <GhostNameContainer>
                                        <GhostTitle>Loading...</GhostTitle>
                                        <GhostContract copied={this.state.copied}>0x9bo...17as2</GhostContract>
                                    </GhostNameContainer>
                                </GhostNameLogo>
                                <GhostOnlyPrice>
                                    <GhostPriceChangeContainer>0X</GhostPriceChangeContainer>
                                </GhostOnlyPrice>
                            </GhostNameLogoPrice>
                            <GhostInformation>
                                <GhostVolume>
                                    <span>Liquidity</span> <div> 45.0 BNB </div>
                                </GhostVolume>
                                <GhostSwaps>
                                    <span>Buys/Sells</span> <div> 2 / 23 </div>
                                </GhostSwaps>
                                <GhostHolders>
                                    <span>Holders</span> <div> 903 </div>
                                </GhostHolders>
                            </GhostInformation>
                        </GhostTokenContainer>
                    </GhostTokenItem>
                    <GhostTokenItem>
                        <GhostTokenContainer>
                            <GhostNameLogoPrice>
                                <GhostNameLogo>
                                    <GhostLogoContainer></GhostLogoContainer>
                                    <GhostNameContainer>
                                        <GhostTitle>Loading...</GhostTitle>
                                        <GhostContract copied={this.state.copied}>0x9bo...17as2</GhostContract>
                                    </GhostNameContainer>
                                </GhostNameLogo>
                                <GhostOnlyPrice>
                                    <GhostPriceChangeContainer>0X</GhostPriceChangeContainer>
                                </GhostOnlyPrice>
                            </GhostNameLogoPrice>
                            <GhostInformation>
                                <GhostVolume>
                                    <span>Liquidity</span> <div> 45.0 BNB </div>
                                </GhostVolume>
                                <GhostSwaps>
                                    <span>Buys/Sells</span> <div> 2/23 </div>
                                </GhostSwaps>
                                <GhostHolders>
                                    <span>Holders</span> <div> 903 </div>
                                </GhostHolders>
                            </GhostInformation>
                        </GhostTokenContainer>
                    </GhostTokenItem>
                </GhostTokensContainer>
            );
        } else {
            return (
                <TokensContainer>
                    {
                        trending.map((pair) => (
                            <TrendingItem key={pair.address} pair={pair} />
                        ))
                    }
                </TokensContainer>
            );
        }

    }
}

const mapStateToProps = (state: RootState) => ({
    trending: state.pairs.trendingPairs
});

export default connect(
    mapStateToProps,
    dispatchProps
)(Tokens);