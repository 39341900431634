import Web3 from "web3";
import { DashboardFilters, DashboardSorters } from "../store/interface/state";
import { PairInterface } from "../types/pairs";

const sortDashboard = (
    pairs: PairInterface[],
    sorters: DashboardSorters,
    bnbPrice?: number,
): PairInterface[] => {
    switch (sorters.column) {
        case 'time':
            return pairs.sort(
                (a, b) => {
                    const isALess = a.timestamp < b.timestamp;
                    const [desc, asc] = [isALess ? 1 : -1, isALess ? -1 : 1];
                    return sorters.desc ? desc : asc;
                }
            );
        case 'symbol':
            return pairs.sort(
                (a, b) => {
                    const asc = a.token.symbol.localeCompare(b.token.symbol);
                    const desc = b.token.symbol.localeCompare(a.token.symbol);
                    return !sorters.desc ? desc : asc;
                }
            );
        case 'liquidity':
            return pairs.sort(
                (a, b) => {
                    const aLiquidity = getLiquidity(a, bnbPrice);
                    const bLiquidity = getLiquidity(b, bnbPrice);
                    const isALess = aLiquidity < bLiquidity;
                    const [desc, asc] = [isALess ? 1 : -1, isALess ? -1 : 1];
                    return sorters.desc ? desc : asc;
                }
            );
        case 'swaps':
            return pairs.sort(
                (a, b) => {
                    const isALess = a.indicators.buys + a.indicators.sells < b.indicators.buys + b.indicators.sells;
                    const [desc, asc] = [isALess ? 1 : -1, isALess ? -1 : 1];
                    return sorters.desc ? desc : asc;
                }
            );
        case 'holders':
            return pairs.sort(
                (a, b) => {
                    const aHolders = a.indicators.holders?.growth || 0;
                    const bHolders = b.indicators.holders?.growth || 0;
                    const isALess = aHolders < bHolders;
                    const [desc, asc] = [isALess ? 1 : -1, isALess ? -1 : 1];
                    return sorters.desc ? desc : asc;
                }
            );
        case 'priceChange':
            return pairs.sort(
                (a, b) => {
                    const aPriceChange = a.indicators.isRug ? 0 : a.indicators.priceChange;
                    const bPriceChange = b.indicators.isRug ? 0 : b.indicators.priceChange;
                    const isALess = a.indicators.isRug && b.indicators.isRug ? a.indicators.priceChange > b.indicators.priceChange : aPriceChange < bPriceChange;
                    const [desc, asc] = [isALess ? 1 : -1, isALess ? -1 : 1];
                    return sorters.desc ? desc : asc;
                }
            );
        default:
            return pairs;
    }
}

const getLiquidity = (pair: PairInterface, bnbPrice?: number) => {
    const { baseToken, isToken0, initialReserves } = pair;
    const liquidity = initialReserves[isToken0 ? 'reserve1' : 'reserve0'];
    const amount = parseFloat(Web3.utils.fromWei(liquidity));
    return !bnbPrice ? amount : baseToken.symbol === 'BUSD' ? amount / bnbPrice : amount;
}

export const filterDashboard = (
    pairs: PairInterface[],
    filters: DashboardFilters,
    sorters: DashboardSorters,
    hiddenPairs: string[],
    bnbPrice?: number,
): PairInterface[] => {
    const { searchParameter, showRugpulls, showSmallLiquidity, showHidden, showUnverified } = filters;

    const hasSmallLiquidity = (pair: PairInterface) => {
        const liquidity = getLiquidity(pair, bnbPrice)
        return !bnbPrice ? pair.baseToken.symbol === 'BUSD' ? liquidity < 250 : liquidity < 1 : liquidity < 1;
    }

    const filtered = pairs.filter(
        (pair) => {
            const { isRug, contractVerified } = pair.indicators;
            const { name, symbol } = pair.token;
            const fitsSmallLiquidity = showSmallLiquidity ? true : !hasSmallLiquidity(pair);
            const fitsRug = showRugpulls ? true : !isRug;
            const fitsHidden = showHidden ? true : !hiddenPairs.includes(pair.address);
            const fitsUnverified = showUnverified ? true : contractVerified;
            const fitsSearch = name.toLowerCase().includes(searchParameter.toLowerCase()) || symbol.toLowerCase().includes(searchParameter.toLowerCase());
            return fitsSearch && fitsRug && fitsUnverified && fitsSmallLiquidity && fitsHidden;
        }
    )

    return sortDashboard(filtered, sorters, bnbPrice);
}