export interface SocketState {
    requested: boolean;
    requestedPairs: string[];
    connected: boolean;
    errorMessage?: string;
    lastError?: Event;
}

export const initialState: SocketState = {
    requested: false,
    requestedPairs: [],
    connected: false,
}