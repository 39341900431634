import { createReducer } from 'typesafe-actions';
import { initialState } from './state';
import * as analyzerActions from './actions';

export default createReducer(initialState)
    .handleAction(
        analyzerActions.simulate.request,
        (state, action) => {
            const { address } = action.payload;
            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    [address]: {
                        ...state.tokens[address],
                        loading: true,
                    }
                }
            }
        }
    ).handleAction(
        analyzerActions.simulate.success,
        (state, action) => {
            const { address, result } = action.payload;
            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    [address]: {
                        loading: false,
                        result
                    }
                }
            }
        }
    ).handleAction(
        analyzerActions.simulate.failure,
        (state, action) => {
            const { address, error } = action.payload
            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    [address]: {
                        loading: false,
                        error
                    }
                }
            }
        }
    ).handleAction(
        analyzerActions.getTokenInfo.request,
        (state, action) => {
            return {
                ...state,
                info: {
                    ...state.info,
                    loading: true,
                }
            }
        }
    ).handleAction(
        analyzerActions.analyzeToken.request,
        (state, action) => {
            return {
                ...state,
                info: {
                    ...state.info,
                    loading: true,
                },
                simulations: {
                    ...state.simulations,
                    loading: true,
                }
            }
        }
    ).handleAction(
        analyzerActions.getTokenInfo.success,
        (state, action) => {
            return {
                ...state,
                info: {
                    loading: false,
                    ...action.payload
                }
            }
        }
    ).handleAction(
        analyzerActions.getTokenInfo.failure,
        (state, action) => {
            const message = action.payload;
            console.error('failure getting token info', message);
            return {
                ...state,
                info: {
                    ...state.info,
                    loading: false,
                }
            }
        }).handleAction(
            analyzerActions.setAddress,
            (state, action) => {
                return {
                    ...state,
                    address: action.payload,
                }
            }
        ).handleAction(
            analyzerActions.resetAnalyzer,
            (state, action) => ({
                ...initialState,
                address: action.payload,
            })
        );