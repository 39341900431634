import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import BN from 'bn.js';

import * as interfaceActions from '../../store/interface/actions';

import Address from './address';
import { ReactComponent as Zap } from '../svg/zap.svg';
import { ReactComponent as Verified } from '../svg/verified.svg';
import { ReactComponent as Lock } from '../svg/lock.svg';
import { ReactComponent as Socials } from '../svg/socials.svg';
import { ReactComponent as Globe } from '../svg/globe.svg';
import { ReactComponent as ArrowLeft } from '../svg/arrow-left.svg';
import { ReactComponent as TriangleDown } from '../svg/triangledown.svg';
import { ReactComponent as LinkIcon } from '../svg/link.svg';
import { ReactComponent as Analyze } from '../svg/analyze.svg';
import { ReactComponent as Poocoin } from '../svg/poocoin.svg';
import { ReactComponent as Twitter } from '../svg/twitter.svg';
import { ReactComponent as Discord } from '../svg/discord.svg';
import { ReactComponent as BscScan } from '../svg/etherscan.svg';
import { ReactComponent as Telegram } from '../svg/send.svg';
import { ReactComponent as Instagram } from '../svg/instagram.svg';
import { ReactComponent as Facebook } from '../svg/facebook.svg';
import { PairInterface, TokenLock } from '../../types/pairs';
import { RootState } from 'typesafe-actions';
import colorFromAddress from '../../utils/colors';

const TopHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    ${({ theme }) => theme.media.small} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } 
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
    
       

    ${({ theme }) => theme.media.small} {
        width: 100%;
        flex-direction: row;
        margin-top: 1.875rem;
        justify-content: space-between;
    } 
`;

const BackButton = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    height: 100%;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
    & svg {
        width: 1rem;
        height: 1rem;
        margin-top: 0.125rem;
        margin-right: 0.625rem;
    }
    ${({ theme }) => theme.media.smallUp} {
        display: none;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    ${({ theme }) => theme.media.small} {
        margin-top: 2rem;
        width: 100%;
        margin-left: 1rem;
        margin-right: 1rem;
        justify-content: space-between;
        display: none;

    } 
`;

const MobileButtonContainer = styled.div`
    display: flex;
    align-items: center;
    height: min-content;
    width: min-content;
    ${({ theme }) => theme.media.smallUp} {
        display: none;
    }
`;

const TopIndicator = styled.div`
    display: flex;
    ${({ theme }) => theme.media.small} {
        flex-direction: column;
        margin-top: 1rem;
    } 
`;

const IndicatorContainer = styled.div`
    display: flex;
    margin-top: 1rem;
    position: relative;
    color: ${({ theme }) => theme.colors.uiColor1};
    ${({ theme }) => theme.media.small} {
        width: 100%;
        justify-content: space-between;
    } 
`;

interface IndicatorProps {
    enabled?: boolean;
    active?: boolean;
    shadow?: boolean;
    totheright?: boolean;
}

/* const Indicator = styled.div<IndicatorProps>`
    
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    
    justify-content: center;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.colors.uiColor1};
    
    background-color: ${({ enabled, theme }) => enabled ? theme.colors.brandColor4 : theme.colors.uiColor3};

    & svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }
    

    ${({ theme }) => theme.media.small} {
        
        font-size: 0.65rem;
        line-height: 10px;
        & svg {
            width: 0.8rem;
            height: 0.8rem;
            margin-right: 0.25rem;
        }
        width: 100%;
        margin-right: 0rem;
        margin-left: 0.5rem;
    } 
`;
 */
const LeftIndicator = styled.div<IndicatorProps>`
    
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    
    justify-content: center;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-right: 0.5rem;
    cursor: ${({ enabled }) => enabled ? 'pointer' : 'initial'};

    color: ${({ theme }) => theme.colors.uiColor1};
    
    background-color: ${({ enabled, theme }) => enabled ? theme.colors.brandColor4 : theme.colors.uiColor3};
    box-shadow: ${({ active }) => active ? '0px 8px 30px rgba(251, 248, 204, 0.3)' : 'none'};
    & svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }
    & svg:nth-child(2) {
        margin-right: 0rem;
        margin-left: 0.6rem
    }

    ${({ theme }) => theme.media.small} {
        
        font-size: 0.65rem;
        line-height: 10px;
        & svg {
            width: 0.8rem;
            height: 0.8rem;
            margin-right: 0.25rem;
        }
        width: 100%;
        margin-right: 0rem;
        margin-left: 0rem;
    }
    pointer-events: ${({ shadow }) => shadow ? 'auto' : 'none'};

`;

const RightIndicator = styled.div<IndicatorProps>`  
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    display: flex;

    justify-content: center;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-right: 0.5rem;
    cursor: ${({ enabled }) => enabled ? 'pointer' : 'initial'};

    color: ${({ theme }) => theme.colors.uiColor1};

    background-color: ${({ enabled, theme }) => enabled ? theme.colors.brandColor4 : theme.colors.uiColor3};
    box-shadow: ${({ active }) => active ? '0px 8px 30px rgba(251, 248, 204, 0.3)' : 'none'};

    & svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }

    ${({ theme }) => theme.media.small} {
        
        font-size: 0.65rem;
        line-height: 10px;
        & svg {
            width: 0.8rem;
            height: 0.8rem;
            margin-right: 0.25rem;
        }
        width: 100%;
        margin-right: 0rem;
        margin-left: 0.5rem;
    } 
`;

const ContractIndicator = styled.a<IndicatorProps>`
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-right: 0.5rem;
    cursor: ${({ enabled }) => enabled ? 'pointer' : 'initial'};
    color: ${({ theme }) => theme.colors.uiColor1};
    
    background-color: ${({ enabled, theme }) => enabled ? theme.colors.brandColor4 : theme.colors.uiColor3};

    & svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }

    & svg:nth-child(2) {
        margin-right: 0rem;
        margin-left: 0.6rem;
    }

    &:hover {
        
        color: ${({ theme }) => theme.colors.uiColor1};
    }

    ${({ theme }) => theme.media.small} {
        
        font-size: 0.65rem;
        line-height: 10px;
        & svg {
            width: 0.8rem;
            height: 0.8rem;
            margin-right: 0.25rem;
        }
        width: 100%;
        display: none;
    } 
`;

const MobileIndicator = styled.a<IndicatorProps>`
    
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-right: 0.5rem;
    margin-left: ${({ totheright }) => totheright ? '0.5rem' : '0'};
    color: ${({ theme }) => theme.colors.uiColor1};
    cursor: ${({ enabled }) => enabled ? 'pointer' : 'initial'};
    background-color: ${({ enabled, theme }) => enabled ? theme.colors.brandColor4 : theme.colors.uiColor3};

    & svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }

    ${({ theme }) => theme.media.small} {
        
        font-size: 0.65rem;
        line-height: 10px;
        & svg {
            width: 0.8rem;
            height: 0.8rem;
            margin-right: 0.25rem;
        }
        margin-right: 0rem;
        width: 100%;
    } 

    ${({ theme }) => theme.media.smallUp} {
        
        font-size: 0.65rem;
        line-height: 10px;
        & svg {
            width: 0.8rem;
            height: 0.8rem;
            margin-right: 0.25rem;
        }
        margin-right: 0rem;
        display: none;
    } 
`;

const ExpressButton = styled.div`
   
    padding: 0.875rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-left: 1rem;
    color: ${({ theme }) => theme.colors.uiColor1};

    & svg {
        width: 1rem;
        height: 1rem;
        margin-top: 0.1rem;
    }

    background-color: ${({ theme }) => theme.colors.brandColor1};
    
    &:hover {
        background-color: ${({ theme }) => theme.colors.uiColor5};
        box-shadow: ${({ theme }) => theme.colors.shadow1};
      }
    
      ${({ theme }) => theme.media.small} {
        width: 100%; 
    }
`;

const MobileExpress = styled(ExpressButton)`
height: 3.5rem;
width: 10.5rem;
${({ theme }) => theme.media.smallUp} {
    display: none; 
}

`

const MobileAnalyze = styled(Link)`
display: flex;
align-items: center;
justify-content: center;
color: ${({ theme }) => theme.colors.brandColor1};
border: 1px solid ${({ theme }) => theme.colors.uiColor2};
width: 3.8rem;
height: 3.8rem;
border-radius: 50%;
& svg {
    width: 1.8rem;
    height: 1.8rem;
}
&:hover {
    border: 1px solid ${({ theme }) => theme.colors.uiColor5};
}
margin-left: 0.5rem;
`

const TradeText = styled.div`
    margin-left: 0.6rem;
    line-height: 1.05rem;
    & span {
        font-weight: 400;
        margin-left: 0.2rem;
    }
`;

const LinksDropDown = styled.div<{ enabled: boolean; }>`
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 10.6rem;
    position: absolute;
    top: 2.25rem;
    left: 5.2rem;
    z-index: 5;
    
    background-color: ${({ theme }) => theme.colors.uiColor1};
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.uiColor5};
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    line-height: 1rem;
    & a:nth-child(1) {
        margin-bottom: 1rem;
    }

    ${({ theme }) => theme.media.small} {
        left: 17rem;
       
    } 

    //transition: all 0.2s ${({ theme }) => theme.transitions.main};
`;

const SocialsDropDown = styled.div<{ enabled: boolean; }>`
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    padding-bottom: 0rem;
    width: 10.6rem;
    position: absolute;
    top: 2.25rem;
    z-index: 5;

    background-color: ${({ theme }) => theme.colors.uiColor1};
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.uiColor5};
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    line-height: 1rem;
    & a {
        margin-bottom: 1rem;
    }

    ${({ theme }) => theme.media.small} {
        left: 3.5rem;
    
    } 

    //transition: all 0.2s ${({ theme }) => theme.transitions.main};
`;

const DropDownLink = styled.a<{ enabled?: boolean; }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    & svg {
        width: 0.875rem;
        height: 0.875rem;
        color: ${({ theme }) => theme.colors.uiColor3};
        margin-right: 0.5rem;
    }
    cursor: pointer;
    pointer-events: ${({ enabled }) => enabled ? 'auto' : 'none'};
    &:hover {
        & div:nth-child(2) {
            & svg {
                color: ${({ theme }) => theme.colors.brandColor1};
            }
        }
    }

`;

const LeftDropDown = styled.div`
    display: flex;
    align-items: center;
`;

const RightDropDown = styled.div`
    & svg {
        width: 0.75rem;
        height: 0.75rem;
        color: ${({ theme }) => theme.colors.uiColor3};
    }
`;

const RightIcon = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   & svg {
       margin-right: 0rem;
       margin-left: 0.6rem;
   }

   ${({ theme }) => theme.media.small} {
        & svg {
            
            margin-left: 0.4rem;
        }
    } 
`;

const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
    ${({ theme }) => theme.media.small} {
       margin-left: 0.5rem;
    } 
`;

const Tokentitle = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2rem;
    & div:nth-child(2) {
        ${({ theme }) => theme.media.small} {
            display: none;
        } 
    }

    & div:nth-child(1) {
        ${({ theme }) => theme.media.smallUp} {
            display: none;
        } 
    }
    & span {
        font-size: 0.9rem;
        margin-left: 1rem;
        color: ${({ theme }) => theme.colors.uiColor3};
        text-transform: uppercase;
        letter-spacing: 0.05em;
        ${({ theme }) => theme.media.small} {
            display: none;
        } 
    }
        ${({ theme }) => theme.media.small} {
        font-size: 1.5rem;
        line-height: 2rem;
    } 
`;

const NameLogo = styled.div`
    display: flex;
    margin-top: 1.875rem;
    ${({ theme }) => theme.media.small} {
        margin-top: 0rem;
    } 
`;

const LogoContainer = styled.div<{ colorHex?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ colorHex }) => colorHex};
    color: ${({ theme }) => theme.colors.uiColor1};
    width: 3.8rem;
    height: 3.8rem;
    border-radius: 50%;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 500;
    padding-top: 0.15rem;
    & svg {
        width: 2.375rem;
        height: 2.375rem;
    }
`;

const AnalyzeButton = styled(Link)`
    padding: 0.875rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    line-height: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};

    & svg  {
        margin-right: 0.8rem;
        color: ${({ theme }) => theme.colors.brandColor1};
        //filter: drop-shadow(2px 2px 0px black);
    }
    
    &:active {
        transform: translateY(2px);
    }

    & svg {
        width: 1rem;
        height: 1rem;
        margin-top: 0.1rem;
    }

    &:hover {
        
        border: 1px solid ${({ theme }) => theme.colors.uiColor5};
    }

    ${({ theme }) => theme.media.small} {
        width: 100%;
       
    } 

`;

const OpenInProductionButton = styled.a`
    padding: 0.875rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    line-height: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    
    &:active {
        transform: translateY(2px);
    }
    margin-right: 1rem;

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.uiColor5};
    }

    ${({ theme }) => theme.media.small} {
        display: none;
    } 
`;

const dispatchProps = {
    closePage: interfaceActions.closePage.request,
}

type Props = {
    locks: TokenLock[];
    onTrade: () => void;
} & Pick<PairInterface, 'address' | 'token' | 'baseToken' | 'indicators'> & RouteComponentProps & typeof dispatchProps;

interface State {
    members?: number;
    online?: number;
    noerr: boolean;
    links: boolean;
    socials: boolean;
}

class PairHeader extends React.Component<Props, State> {
    private isDevelopment = process.env.NODE_ENV === 'development' || window.location.origin.includes('test');
    constructor(props: Props) {
        super(props);
        this.state = {
            noerr: true,
            links: false,
            socials: false,
        }
    }

    componentDidMount = async () => {
        /*         if (this.props.telegram) {
                    try {
                        const { members, online } = await getTelegramMembers(this.props.telegram);
                        this.setState({
                            members, online
                        });
        
                    } catch (error) {
                        this.setState({
                            noerr: false
                        });
                    }
                } */
    }

    calculateTimeLeft = (timestamp: number) => {
        let difference = +new Date(timestamp * 1000) - +new Date()

        const minute = 60 * 1000;
        const hour = minute * 60;
        const day = hour * 24;
        const month = day * 31;
        const year = month * 12;

        const timeLeft = {
            years: Math.floor(difference / year),
            months: Math.floor(difference / month),
            days: Math.floor(difference / day),
            hours: Math.floor(difference / hour),
            minutes: Math.floor(difference / minute),
        };

        if (timeLeft.minutes < 10) {
            return false;
        } else {
            return true;
        }
    }

    getLockerUrl = (locks: TokenLock[]) => {
        const pairLocks = locks.filter(({ address }) => address === this.props.address);
        const sortedLocks = pairLocks.sort((a, b) => new BN(a.amount).gt(new BN(b.amount)) ? -1 : 1);
        const biggestLock = sortedLocks.find(() => true);
        if (biggestLock) {
            return biggestLock.url;
        } else {
            return undefined;
        }
    }

    handleSocials = () => {
        this.setState(prevState => ({
            socials: !prevState.socials,
            links: !prevState.socials ? false : prevState.links,
        }));
    }

    handleLinks = () => {
        this.setState(prevState => ({
            links: !prevState.links,
            socials: !prevState.links ? false : prevState.socials,
        }));
    }

    renderSymbol = (symbol: string) => {
        const character = symbol.trim();
        const code = (character.codePointAt(0))
        console.log(code)
        return <span>&#128133;</span>
    }

    handleClose = () => {
        const pathname = this.props.location.pathname;
        this.props.closePage(pathname);
        this.props.history.push('/');
    }

    render() {
        const { address, token, indicators, locks } = this.props;
        const lockerUrl = this.getLockerUrl(locks);

        return (
            <TopHeaderContainer>
                <BackButton onClick={this.handleClose}><ArrowLeft /><span>Back To Dashboard</span></BackButton>
                <HeaderContainer>
                    <TitleContainer>
                        <NameLogo>
                            <LogoContainer colorHex={colorFromAddress(token.address)}>{token.symbol.trim().slice(0, 1)}</LogoContainer>
                            <NameContainer>
                                <Tokentitle>
                                    <div>

                                    {`${token.name.length > 13 ? token.name.slice(0, 10) + '...' : token.name}`}
                                    </div>
                                    <div>
                                    {token.name}
                                    </div>
                                    
                                    <span>
                                        ${token.symbol}
                                    </span>
                                </Tokentitle>
                                <Address address={token.address} />
                            </NameContainer>
                        </NameLogo>
                        <MobileButtonContainer>

                        <MobileExpress onClick={() => this.props.onTrade()}>
                            <Zap />
                            <TradeText>
                                Trade Now!
                            </TradeText>
                        </MobileExpress>
                        <MobileAnalyze to={{
                            pathname: `/analyze/${token.address}`,
                            state: { prevPath: this.props.location.pathname }
                        }}><Analyze /></MobileAnalyze>
                        </MobileButtonContainer>
                    </TitleContainer>
                    <ButtonContainer>
                        {
                            this.isDevelopment && (
                                <OpenInProductionButton
                                    href={`https://app.tokenexpress.io/pair/${address}`}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                >
                                    Production
                                </OpenInProductionButton>
                            )
                        }
                        <AnalyzeButton to={{
                            pathname: `/analyze/${token.address}`,
                            state: { prevPath: this.props.location.pathname }
                        }}>
                            <Analyze />Analyze Token
                        </AnalyzeButton>
                        <ExpressButton onClick={() => this.props.onTrade()}>
                            <Zap />
                            <TradeText>
                                Trade Now!
                            </TradeText>
                        </ExpressButton>
                    </ButtonContainer>

                </HeaderContainer>
                <TopIndicator>
                    <IndicatorContainer>
                        <ContractIndicator enabled={!!indicators.contractVerified} href={`https://bscscan.com/address/${token.address}#code`}
                            target={'_blank'}
                            rel={'noreferrer'} >
                            <Verified /><span>Contract Verified</span><RightIcon><LinkIcon /></RightIcon></ContractIndicator>
                        <MobileIndicator enabled={!!indicators.contractVerified} href={`https://bscscan.com/address/${token.address}#code`}
                            target={'_blank'}
                            rel={'noreferrer'} ><Verified /><span>Verified</span><RightIcon><LinkIcon /></RightIcon></MobileIndicator>
                        <ContractIndicator enabled={indicators.locked} href={lockerUrl}
                            target={'_blank'}
                            rel={'noreferrer'} >
                            <Lock /><span>LP Locked</span>
                            {lockerUrl && (
                                <RightIcon><LinkIcon /></RightIcon>
                            )}
                        </ContractIndicator>
                        <MobileIndicator enabled={indicators.locked} totheright href={lockerUrl}
                            target={'_blank'}
                            rel={'noreferrer'} >
                            <Lock /><span>LP Locked</span>
                            {lockerUrl && (
                                <RightIcon><LinkIcon /></RightIcon>
                            )}
                        </MobileIndicator>
                        
                    </IndicatorContainer>
                    <IndicatorContainer>
                        <LeftIndicator shadow={!!token.socials} active={this.state.socials} enabled={!!token.socials} onClick={this.handleSocials}><Socials /> <span>Socials</span> <RightIcon><TriangleDown></TriangleDown></RightIcon></LeftIndicator>
                        <RightIndicator active={this.state.links} enabled={true} onClick={() => this.handleLinks()}><Globe /><span>Links</span> <RightIcon><TriangleDown></TriangleDown></RightIcon></RightIndicator>
                        <SocialsDropDown enabled={this.state.socials}>
                            {
                                token.socials?.website && (
                                    <DropDownLink
                                        href={token.socials.website}
                                        target={'_blank'}
                                        rel={'noreferrer'} enabled={this.state.socials}
                                    >
                                        <LeftDropDown><Globe /><span>Website</span></LeftDropDown>
                                        <RightDropDown><LinkIcon /></RightDropDown>
                                    </DropDownLink>
                                )
                            }
                            {
                                token.socials?.twitter && (
                                    <DropDownLink
                                        href={token.socials.twitter}
                                        target={'_blank'}
                                        rel={'noreferrer'} enabled={this.state.socials}
                                    >
                                        <LeftDropDown><Twitter /><span>Twitter</span></LeftDropDown>
                                        <RightDropDown><LinkIcon /></RightDropDown>
                                    </DropDownLink>
                                )
                            }
                            {
                                token.socials?.telegram && (
                                    <DropDownLink
                                        href={token.socials.telegram}
                                        target={'_blank'}
                                        rel={'noreferrer'} enabled={this.state.socials}
                                    >
                                        <LeftDropDown><Telegram></Telegram><span>Telegram</span></LeftDropDown>
                                        <RightDropDown><LinkIcon /></RightDropDown>
                                    </DropDownLink>
                                )
                            }
                            {
                                token.socials?.discord && (
                                    <DropDownLink
                                        href={token.socials.discord}
                                        target={'_blank'}
                                        rel={'noreferrer'} enabled={this.state.socials}
                                    >
                                        <LeftDropDown><Discord /><span>Discord</span></LeftDropDown>
                                        <RightDropDown><LinkIcon /></RightDropDown>
                                    </DropDownLink>
                                )
                            }
                            {
                                token.socials?.instagram && (
                                    <DropDownLink
                                        href={token.socials.instagram}
                                        target={'_blank'}
                                        rel={'noreferrer'} enabled={this.state.socials}
                                    >
                                        <LeftDropDown><Instagram /><span>Instagram</span></LeftDropDown>
                                        <RightDropDown><LinkIcon /></RightDropDown>
                                    </DropDownLink>
                                )
                            }
                            {
                                token.socials?.facebook && (
                                    <DropDownLink
                                        href={token.socials.facebook}
                                        target={'_blank'}
                                        rel={'noreferrer'} enabled={this.state.socials}
                                    >
                                        <LeftDropDown><Facebook /><span>Facebook</span></LeftDropDown>
                                        <RightDropDown><LinkIcon /></RightDropDown>
                                    </DropDownLink>
                                )
                            }
                        </SocialsDropDown>
                        <LinksDropDown enabled={this.state.links}>
                            <DropDownLink href={`https://poocoin.app/tokens/${token.address}`}
                                target={'_blank'}
                                rel={'noreferrer'} enabled={this.state.links}><LeftDropDown><Poocoin></Poocoin>Poocoin</LeftDropDown><RightDropDown><LinkIcon /></RightDropDown></DropDownLink>
                            <DropDownLink href={`https://bscscan.com/token/${token.address}`}
                                target={'_blank'}
                                rel={'noreferrer'} enabled={this.state.links}><LeftDropDown><BscScan></BscScan>BSC Scan</LeftDropDown><RightDropDown><LinkIcon /></RightDropDown></DropDownLink>
                        </LinksDropDown>
                    </IndicatorContainer>
                </TopIndicator>
            </TopHeaderContainer >
        )
    }
}

const mapStateToProps = (state: RootState) => ({

});

export default connect(
    mapStateToProps,
    dispatchProps
)(withRouter(PairHeader));