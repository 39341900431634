import React, { MutableRefObject } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import styled from 'styled-components/macro';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as interfaceActions from '../../store/interface/actions';
import lottie from 'lottie-web/build/player/lottie_light';

//Svona importar maður!
import { ReactComponent as Dashboard } from '../svg/dashboard.svg';
import { ReactComponent as Analyze } from '../svg/analyze.svg';
import { ReactComponent as User } from '../svg/user.svg';
import { ReactComponent as Search } from '../svg/search.svg';
import { ReactComponent as Rocket } from '../svg/rocket.svg';
import { ReactComponent as Chart } from '../svg/chart.svg';
import { ReactComponent as Binance } from '../svg/chainbnb.svg';
import { ReactComponent as Ethereum } from '../svg/ethereumchain.svg';
import { ReactComponent as Polygon } from '../svg/polygonchain.svg';
import { ReactComponent as Avalanche } from '../svg/avalanchechain.svg';
import { ReactComponent as MobileLogo } from '../svg/smallerlogo.svg';
import { ReactComponent as Send } from '../svg/send.svg';
import { ReactComponent as Attention } from '../svg/attention.svg';
import { ReactComponent as Docs } from '../svg/docs.svg';
import { ReactComponent as OnlyText } from '../svg/newonlytext.svg';
import { ReactComponent as Twitter } from '../svg/twitter.svg';
import { ReactComponent as TriangleUp } from '../svg/triangleup.svg';
import { ReactComponent as TriangleDown } from '../svg/triangledown.svg';

import animationData from '../animations/white.json';

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    white-space: nowrap;
    position: relative;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: ${({ theme }) => theme.layout.headerHeight};
    padding-right: 1rem;
    & img {
        margin: 0 0.5rem;
        height: 1rem;
    }
`;

const Title = styled.div<{ sidebar?: boolean }>`

    display: flex;
    
    font-weight: 500;
    margin-top: 1rem;
    
    & svg {
        //width: ${({ sidebar }) => sidebar ? '11rem' : '2rem'};

    }
`;

const OnlyTextDiv = styled.div<{ sidebar?: boolean }>`

    display: flex;
    align-items: center;
    margin-left: 0.75rem;
    margin-top: 1rem;
    opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
    & svg {
        width: 9.488rem;
        height: 2.67rem;

    }


`;

const LogoDiv = styled.div<{ sidebar?: boolean }>`
    margin-left: 1.375rem;
    margin-top: 1rem;
    & svg {
        width: 2.5rem;
        height: 2.5rem;

    }
    
`;



const Subtitle = styled.div<{ sidebar?: boolean }>`
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: 0.875em;
    
    color: ${({ theme }) => theme.colors.uiColor4};
    opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.5rem;
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.main};
    
`;

/* const BorderDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 90%;
    margin-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.jet};
`; */

const MoonButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
   
    width: 2.8rem;
    height: 2.8rem;
    color:  ${({ theme }) => theme.colors.uiColor5};
    border-radius: 3.75rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor3};
    

    & svg {
        width: 1rem;
        height: 1rem;
    }
    &:hover {
        
        border: 1px solid ${({ theme }) => theme.colors.uiColor5};
    }
    position: absolute;
    top: -1.4rem;
    right: -1.4rem;
    background-color: ${({ theme }) => theme.colors.uiColor1};
`;

const BothButtonsContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 1rem;
    border-top: 1px solid ${({ theme }) => theme.colors.uiColor3};
`;


const BottomButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    
    
    margin-bottom: 1rem;
    padding-top: 2rem;
`;

const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    height: min-content;
    margin-left: 2rem;


    width: 100%;



    
`;

const NavButton = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: start;
    margin-left: 0.9rem;
    align-items: center;
    background-color: transparent;
    padding: 0.9rem 1rem;
    color: ${({ selected, theme }) => selected ? theme.colors.uiColor5 : theme.colors.uiColor4};
    font-weight: 500;
    font-size: 0.875rem;

    & svg {
        height: 1.25rem;
        width: 1.25rem;
        color: ${({ selected, theme }) => selected ? theme.colors.brandColor1 : theme.colors.uiColor4};
    }

    & a{
        display: flex;
        justify-content: center;
        line-height: 1.15rem;
        & svg {
            margin-right: 0.75rem;
        }
        &:hover {
            & svg {
                
                color: ${({ theme }) => theme.colors.uiColor5};
            }   
            
        }
    }
    
`;

const ComingNavButton = styled.div<{ sidebar?: boolean; message: string; }>`
    display: flex;
    justify-content: start;
    margin-left: 0.9rem;
    align-items: center;
    background-color: transparent;
    padding: 0.9rem 1rem;
    color: ${({ theme }) => theme.colors.uiColor2};
    font-weight: 500;
    font-size: 0.875rem;
    position: relative;

    & svg {
        height: 1.25rem;
        width: 1.25rem;
        color: ${({ theme }) => theme.colors.uiColor2};
    }

    & div {
        cursor: pointer;
        display: flex;
        justify-content: center;
        line-height: 1.15rem;
        & svg {
            margin-right: 0.75rem;
        }
        & >span {
            font-size: 0.5rem;
            font-weight: 500;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            z-index: 99;
            position: absolute;
            background-color: ${({ theme }) => theme.colors.uiColor3};
            top: -0.6rem;
            right: ${({ sidebar }) => sidebar ? '8.5rem' : '0.25rem'};
            opacity: 0;
            color: white;
            text-decoration: none;
            white-space: nowrap;
            transition: all 0.15s ${({ theme }) => theme.transitions.main};
            &:after {
                content: '${({ message }) => message}';
            }
            pointer-events: none;
            text-transform: uppercase;
            padding: 0.375rem 0.5rem;
            height: 1.125rem;
        }
    
        &:hover {
            & >span {
                opacity: 1;
                transition: all 0.15s ${({ theme }) => theme.transitions.main};
                transition-delay: 0;
            }
        }
    }
    
`;

/* const SpecialNavButton = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: start;
    margin-left: 0.9rem;
    align-items: center;
    background-color: transparent;
    padding: 0.9rem 1rem;
    cursor: pointer;
    color: ${({ selected, theme }) => selected ? theme.colors.uiColor5 : theme.colors.uiColor4};
    font-weight: 500;
    font-size: 0.875rem;
    width: min-content;
    
    & svg {
        margin-right: 0.75rem;
        height: 1.25rem;
        width: 1.25rem;
        color: ${({ selected, theme }) => selected ? theme.colors.brandColor1 : theme.colors.uiColor4};
    }

    & div{
        display: flex;
        justify-content: center;
        line-height: 1.15rem;
        & svg {
            margin-right: 0.75rem;
        }
        &:hover {
            & svg {
                
                color: ${({ theme }) => theme.colors.uiColor5};
            }   
            
        }
    }
    
`; */

const TextSpan = styled.div<{ sidebar?: boolean }>`
    opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
`

/* const BottomNavButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   
    &:hover {
        //background-color: #707070;
    }
    
    padding: 1rem;

    & svg {
        height: 1rem;
        width: 1rem;
    }
`; */

const BasicLink = styled.span<{ selected?: boolean; sidebar?: boolean; chain?: boolean; }>`
    
    border-radius: 5rem;
    font-weight: 400;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.9rem;
    
    color: ${({ selected, theme }) => selected ? theme.colors.brandColor1 : theme.colors.uiColor5};
    & svg {
        height: 1.1rem;
        width: 1.1rem;
        margin-right: 0.5rem;
    }

    
    & a{
        display: flex;
        align-items: center;
        transition: all 0.3s ease;

        &:hover {
            
            color: ${({ theme }) => theme.colors.brandColor1};
            transform: translateY(-2px);
        }
    
        &:active {
            transform: translateY(0px);
        }
    }

    pointer-events: ${({ chain }) => chain ? 'none' : 'auto'};



`;

const TopPriceContainer = styled.div<{ sidebar?: boolean; active: boolean; }>`
    display: flex;
    align-self: center;
    position: relative;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 1rem;
    & span {
        
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.875rem;
        line-height: 1rem;
    }
    background-color: ${({ theme }) => theme.colors.uiColor6};
    border-radius: ${({ sidebar }) => sidebar ? '0.5rem' : '0rem'};
    background-color: ${({ active, theme }) => active ? theme.colors.brandColor1 : theme.colors.uiColor6};
    color: ${({ active, theme }) => active ? theme.colors.uiColor1 : theme.colors.uiColor5};
    box-shadow: ${({ active }) => active ? '0px 8px 30px rgba(251, 248, 204, 0.3)' : 'none'};
    
    & svg {
        margin-right: ${({ sidebar }) => sidebar ? '0.5rem' : '0rem'};
        color: ${({ active, theme }) => active ? theme.colors.uiColor1 : theme.colors.brandColor4};
    }
    width: ${({ sidebar }) => sidebar ? '80%' : '100%'};
    transition: ${({ sidebar }) => sidebar ? 'none' : 'width 0.1s linear 0.1s'};

    height: 3.125rem;
    cursor: pointer;
    pointer-events: ${({ sidebar }) => sidebar ? 'auto' : 'none'};
    ${({ theme }) => theme.media.small} {
        display: none;
    }
`;

const BinanceContainer = styled.div<{ sidebar?: boolean; }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
   
    margin-left: ${({ sidebar }) => sidebar ? '1.5rem' : '1.8rem'};
`;

const ChainLogoContainer = styled.div`
display: flex;
    justify-content: flex-start;
    align-items: center;
& svg {
    width: 1.6rem !important;
    height: 1.9rem !important;

}
cursor: pointer;
pointer-events: auto !important;
    
`;

const PillContainer = styled.div<{ sidebar?: boolean; }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    & svg {
        color: inherit;
    }
    cursor: pointer;
    opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
    pointer-events: ${({ sidebar }) => sidebar ? 'auto' : 'none'};
    
`;


const PriceContainer = styled.div<{ sidebar?: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    color: ${({ theme }) => theme.colors.brandColor4};
    font-size: 0.875rem;
    font-weight: 400;
    text-transform: uppercase;

    &>* {
        font-family: ${({ theme }) => theme.fonts.main};
        
        
        line-height: 1.15rem;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
            margin-top: 0.25rem;
        }
    }

    ${({ theme }) => theme.media.small} {
        display: none;
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const AnimationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
   
    width: 1rem;
    height: 1rem;
    
`;

/* const SpecialLink = styled.div`
    cursor: pointer;
`; */

const BNBspan = styled.span<{ sidebar?: boolean; }>`
    display: ${({ sidebar }) => sidebar ? 'flex' : 'none'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
`;

const Chainspan = styled.span<{ sidebar?: boolean; }>`
    opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
`;

const ChainContainer = styled.div<{ sidebar?: boolean; active: boolean; }>`
    display:  ${({ active }) => active ? 'flex' : 'none'};
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    padding-bottom: 0rem;
    background-color: ${({ theme }) => theme.colors.uiColor1};
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    border-left: ${({ sidebar, theme }) => sidebar ? `1px solid ${theme.colors.uiColor2}` : 'none'};
    border-right: ${({ sidebar, theme }) => sidebar ? `1px solid ${theme.colors.uiColor2}` : 'none'};
    
    border-radius: ${({ sidebar }) => sidebar ? '0.5rem' : '0rem'};
    position: absolute;
    top: -13.5rem;
    width: 100%;
    pointer-events: none;
`;

const ChooseChain = styled.div<{ sidebar?: boolean; }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: 0.4rem;
    margin-left: ${({ sidebar }) => sidebar ? '0.4rem' : '0.7rem'};
    & svg {
        width: 1.6rem !important;
        height: 1.9rem !important;
        color: ${({ theme }) => theme.colors.brandColor4};
        
    }
    & div {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.875rem;
        line-height: 1rem;
    }
    color: ${({ theme }) => theme.colors.uiColor5};
    
    pointer-events: none;
    &:active {
        pointer-events: none;
    }
    

`;

const ChooseChainGrey = styled.div<{ sidebar?: boolean; message: string; }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    width: 7.5rem;
    margin-left: ${({ sidebar }) => sidebar ? '0.4rem' : '0.7rem'};
    & svg {
        width: 1.6rem !important;
        height: 1.9rem !important;
        color: ${({ theme }) => theme.colors.uiColor3};
        pointer-events: auto !important;
        &:active {
            pointer-events: none !important;
        }
    }
    & div {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.875rem;
        line-height: 1rem;
    }
    color: ${({ theme }) => theme.colors.uiColor3};
    &:active {
        pointer-events: none;
    }
    pointer-events: ${({ sidebar }) => sidebar ? 'auto' : 'none'};
    cursor: pointer;

    & >span {
        font-size: 0.5rem;
        font-weight: 500;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        z-index: 99;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.uiColor2};
        top: ${({ sidebar }) => sidebar ? '-0.8rem' : '-1.05rem'};
        right: ${({ sidebar }) => sidebar ? '0.7rem' : '4.2rem'};
        opacity: 0;
        color: white;
        text-decoration: none;
        white-space: nowrap;
        transition: all 0.15s ${({ theme }) => theme.transitions.main};
        &:after {
            content: '${({ message }) => message}';
        }
        pointer-events: none;
        text-transform: uppercase;
        padding: 0.375rem 0.5rem;
        height: 1.125rem;
    }

    &:hover {
        & >span {
            opacity: 1;
            transition: all 0.15s ${({ theme }) => theme.transitions.main};
            transition-delay: 0;
        }
    }


`;


const ChainText = styled.div<{ sidebar?: boolean; }>`
    opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
`;

const dispatchProps = {
    getBNBPrice: interfaceActions.getBNBPrice.request
}


type Props = {
    className?: string;
    handleNavigation: () => void;
    enabled: boolean;
    playani: boolean;
} & RouteComponentProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

interface State {
    open: boolean;
    chain: boolean;
}

class Sidebar extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
            chain: false
        }
    }

    private animationContainer = React.createRef<HTMLDivElement>();
    private anim = React.createRef<MutableRefObject<null>>();


    componentDidMount = () => {
        this.props.getBNBPrice();
        if (this.animationContainer.current) {
            //@ts-ignore
            this.anim.current = lottie.loadAnimation({
                container: this.animationContainer.current,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData,

            });

        }
    }

    componentDidUpdate = (prevProps: Props) => {
        if (this.props.playani !== prevProps.playani) {
            this.handleAni()
        }
    }

    componentWillUnmount = () => {
        //@ts-ignore
        this.anim.current?.destroy();
    }



    delay = (URL: Location) => {
        setTimeout(function () { window.location = URL }, 500);
    }

    handleChain = () => {
        if (this.state.chain) {
            this.setState({ chain: false });
        }
        else {
            if (!this.state.chain) {
                this.setState({ chain: true });
            }
        }
    }

    handleAni = () => {
        if (this.state.open) {
            this.setState({ open: false });
        }
        else {
            this.setState({ open: true });
        }
        //@ts-ignore
        this.anim.current?.setDirection(this.state.open ? -1 : 1);
        //@ts-ignore
        this.anim.current?.play();
    }

    render() {
        const { match, bnbPrice } = this.props;
        return (
            <SidebarContainer className={this.props.className}>
                <TitleContainer>
                    <Link to='/'>
                        <Title sidebar={this.props.enabled} >
                            <LogoDiv sidebar={this.props.enabled}>
                                <MobileLogo></MobileLogo>
                            </LogoDiv>
                            <OnlyTextDiv sidebar={this.props.enabled}>

                                <OnlyText></OnlyText>
                            </OnlyTextDiv>

                        </Title>
                    </Link>
                </TitleContainer>
                <BothButtonsContainer>
                    <MoonButton
                        onClick={() => {
                            this.handleAni()
                            this.props.handleNavigation()
                        }}>
                        <AnimationContainer ref={this.animationContainer}></AnimationContainer>
                    </MoonButton>
                    <ButtonsContainer>
                        <NavButton selected={match.path === '/' || match.path.startsWith('/pair')}>
                            <Link to="/">
                                <Dashboard />
                                <TextSpan sidebar={this.props.enabled}>
                                    Dashboard
                                </TextSpan>
                            </Link>
                        </NavButton>

                        <NavButton selected={match.path.startsWith('/analyze')}>
                            <Link to="/analyze" >
                                <Analyze />
                                <TextSpan sidebar={this.props.enabled}>
                                    Analyze
                                </TextSpan>
                            </Link>
                        </NavButton>

                        <ComingNavButton message="Coming Soon" sidebar={this.props.enabled}>
                            <div>
                                <User />
                                <TextSpan sidebar={this.props.enabled}>
                                    Profile
                                </TextSpan>
                                <span></span>
                            </div>
                        </ComingNavButton>

                        <ComingNavButton message="Coming Soon" sidebar={this.props.enabled}>
                            <div>
                                <Chart />
                                <TextSpan sidebar={this.props.enabled}>
                                    Presales
                                </TextSpan>
                                <span></span>
                            </div>
                        </ComingNavButton>

                        <ComingNavButton message="Coming Soon" sidebar={this.props.enabled}>
                            <div>
                                <Rocket />
                                <TextSpan sidebar={this.props.enabled}>
                                    Launchpad
                                </TextSpan>
                                <span></span>

                            </div>
                        </ComingNavButton>

                        <ComingNavButton message="Coming Soon" sidebar={this.props.enabled}>
                            <div>
                                <Search />
                                <TextSpan sidebar={this.props.enabled}>
                                    Auditing
                                </TextSpan>
                                <span></span>
                            </div>
                        </ComingNavButton>

                        {/* <NavButton selected={match.path.startsWith('/settings')}>
                        <a href="/" >
                            <Settings />
                            <TextSpan sidebar={this.props.enabled}>
                                Settings
                            </TextSpan>
                    </div>
                        </NavButton> */}


                        {/*                     <a href="/">
                        <NavButton selected={match.path.startsWith('/preferences')}>
                            <SettingsWheelIcon />
                            <span>
                                Settings
                            </span>
                        </NavButton>
                    </div> */}
                    </ButtonsContainer>

                    <BottomButtonsContainer>
                        {/*  <BorderDiv/> */}
                        <LinkContainer>
                            <BasicLink chain={this.state.chain} sidebar={this.props.enabled}>
                                <a href="https://docs.tokenexpress.io" target="_blank" rel={'noreferrer'}>
                                    {/* <TelegramIcon /> */}
                                    {
                                        !this.props.enabled &&
                                        <Docs></Docs>
                                    }
                                    <TextSpan sidebar={this.props.enabled}>
                                        Docs
                                    </TextSpan>
                                </a>
                            </BasicLink>
                            <BasicLink chain={this.state.chain} sidebar={this.props.enabled}>
                                <a href="https://t.me/TokenExpress" target="_blank" rel={'noreferrer'}>
                                    {/* <TelegramIcon /> */}
                                    {
                                        !this.props.enabled &&
                                        <Send></Send>
                                    }
                                    <TextSpan sidebar={this.props.enabled}>
                                        Telegram
                                    </TextSpan>
                                </a>
                            </BasicLink>

                            <BasicLink chain={this.state.chain} sidebar={this.props.enabled}>
                                <a href="https://twitter.com/thetokenexpress" target="_blank" rel={'noreferrer'}>
                                    {/* <TelegramIcon /> */}
                                    {
                                        !this.props.enabled &&
                                        <Twitter></Twitter>
                                    }
                                    <TextSpan sidebar={this.props.enabled}>
                                        Twitter
                                    </TextSpan>
                                </a>
                            </BasicLink>

                            <BasicLink chain={this.state.chain} sidebar={this.props.enabled} selected={match.path.startsWith('/disclaimer')}>
                                <a href='/disclaimer'>

                                    {/* <DisclaimerIcon /> */}
                                    {
                                        !this.props.enabled &&
                                        <Attention></Attention>
                                    }
                                    <TextSpan sidebar={this.props.enabled}>
                                        Disclaimer
                                    </TextSpan>
                                </a>
                            </BasicLink>
                            <Subtitle sidebar={true}>
                                {
                                    !this.props.enabled &&
                                    '1.0'
                                }
                                <TextSpan sidebar={this.props.enabled}>
                                    Version 1.0
                                </TextSpan>
                            </Subtitle>

                        </LinkContainer>
                        <TopPriceContainer onClick={() => this.handleChain()} active={this.state.chain} sidebar={this.props.enabled}>
                            <ChainContainer sidebar={this.props.enabled} active={this.state.chain}>
                                <ChooseChain sidebar={this.props.enabled}>
                                    <Binance></Binance>
                                    <ChainText sidebar={this.props.enabled}>BNB CHAIN</ChainText>
                                </ChooseChain>
                                <ChooseChainGrey message='Coming Soon' sidebar={this.props.enabled}>
                                    <Ethereum></Ethereum>
                                    <ChainText sidebar={this.props.enabled}>Ethereum</ChainText>
                                    <span></span>
                                </ChooseChainGrey>
                                <ChooseChainGrey message='Coming Soon' sidebar={this.props.enabled}>
                                    <Polygon></Polygon>
                                    <ChainText sidebar={this.props.enabled}>Polygon</ChainText>
                                    <span></span>
                                </ChooseChainGrey>
                                <ChooseChainGrey message='Coming Soon' sidebar={this.props.enabled}>
                                    <Avalanche></Avalanche>
                                    <ChainText sidebar={this.props.enabled}>Avalanche</ChainText>
                                    <span></span>
                                </ChooseChainGrey>
                            </ChainContainer>

                            <BinanceContainer sidebar={this.props.enabled}>
                                <ChainLogoContainer onClick={() => this.handleChain()}>

                                    <Binance />
                                </ChainLogoContainer>
                                <Chainspan sidebar={this.props.enabled}>BNB CHAIN</Chainspan>
                            </BinanceContainer>
                            <PillContainer sidebar={this.props.enabled}>
                                {
                                    this.state.chain ?
                                        <TriangleDown></TriangleDown>
                                        :
                                        <TriangleUp></TriangleUp>
                                }
                            </PillContainer>
                        </TopPriceContainer>
                        <PriceContainer sidebar={this.props.enabled}>
                            <BNBspan sidebar={this.props.enabled}>
                                BNB:&nbsp;
                            </BNBspan>
                            {
                                !!bnbPrice ? (
                                    <span>${bnbPrice.toFixed(0)}</span>
                                ) : (
                                    <span>
                                        <Loader
                                            type="Oval"
                                            color="#0B0E13"
                                            height={'0.9rem'}
                                            width={'0.9rem'}
                                            visible={true}
                                        />
                                    </span>
                                )
                            }
                        </PriceContainer>

                    </BottomButtonsContainer>
                </BothButtonsContainer>
            </SidebarContainer>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    bnbPrice: state.interface.BNBPrice
});

export default connect(
    mapStateToProps,
    dispatchProps
)(withRouter(Sidebar));