export interface ChainNetwork {
    backupRpc: string;
    chainId: string;
    networkType: string;
}

export const rinkeby: ChainNetwork = {
    backupRpc: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    networkType: 'rinkeby',
    chainId: '0x4'
};

export const ethereum: ChainNetwork = {
    backupRpc: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    chainId: '0x1',
    networkType: 'main',
};

export const bsc: ChainNetwork = {
    backupRpc: 'https://bsc-dataseed.binance.org/',
    chainId: '0x38',
    networkType: 'private',
};

export const preferredNetwork = bsc;