import Web3 from 'web3';
import getWallet, { Wallet } from '../utils/wallet';

export const connectWallet = async () => {
    const wallet: Wallet = getWallet();
    return wallet.connect();
}

export const switchNetwork = async () => {
    const wallet: Wallet = getWallet();
    await wallet.switchNetwork();
    return Promise.resolve();
}

export const disconnectWallet = async () => {
    const wallet: Wallet = getWallet();
    wallet.disconnect();
    return Promise.resolve();
}

export const getBalance = async () => {
    const wallet: Wallet = getWallet();
    try {
        const web3 = await wallet.getWeb3() as Web3;
        const balance = await web3.eth.getBalance(wallet.address);
        return Promise.resolve(Web3.utils.toBN(balance));
    } catch (error) {
        return Promise.reject(error);
    }
}

export interface SignOwnershipProps {
    ownerAddress: string;
    tokenAddress: string;
    tokenName: string;
    tokenSymbol: string;
    walletAddress: string;
}

export interface SignOwnershipResult {
    tokenAddress: string;
    ownerAddress: string;
    signature: string;
}

export const signOwnership = async (props: SignOwnershipProps): Promise<SignOwnershipResult> => {
    const {
        ownerAddress,
        tokenAddress,
        tokenName,
        tokenSymbol,
        walletAddress,
    } = props;

    const wallet: Wallet = getWallet();
    const web3 = await wallet.getWeb3();

    const data = JSON.stringify({
        domain: { name: "PolyHunter" },
        message: {
            Name: tokenName,
            Symbol: tokenSymbol,
            Owner: ownerAddress,
        },
        primaryType: "Token",
        types: {
            EIP712Domain: [
                { name: "name", type: "string" },
            ],
            Token: [
                { name: "Name", type: "string" },
                { name: "Symbol", type: "string" },
                { name: "Owner", type: "address" },
            ],
        },
    });

    const params = {
        method: 'eth_signTypedData_v4',
        params: [walletAddress, data],
        from: walletAddress,
    }

    return new Promise((resolve, reject) => {
        web3.currentProvider.sendAsync(params, (error: any, result: any) => {
            if (error) {
                reject(error);
            } else {
                const signature = result.result as string;
                resolve({
                    tokenAddress,
                    ownerAddress,
                    signature
                })

            }
        });
    })
}