import * as React from 'react';
import styled from 'styled-components/macro';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import { PriceChange } from '../../types/pairs';
import PriceChangeIndicator from './price-changes';

const Container = styled.span<{ up: boolean }>`
    display: flex;
    align-items: center;
    & svg {
        width: 7.5rem;
        height: 2.5rem;
        margin-right: 1rem;
        color: ${({ theme, up }) => theme.colors[up ? 'triggerColor5' : 'triggerColor1']};
    }
`;

type Props = {
    priceChanges: PriceChange[];
    isRed: boolean;
    priceChange: number;
    isRug: boolean;
}

interface State {
}

export default class MiniChart extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {

        }
    }

    shouldComponentUpdate = (nextProps: Props) => {
        const wasNotLoaded = this.props.priceChanges.length === 1;
        if (wasNotLoaded && nextProps.priceChanges.length > 1) {
            return true;
        } else {
            if (this.props.isRed !== nextProps.isRed) {
                return true;
            }
            const dataLength = this.props.priceChanges.length;
            const nextLength = nextProps.priceChanges.length;
            if (nextLength >= dataLength + 5) {
                return true;
            } else {
                return false;
            }
        }
    }

    render() {
        const { isRed, priceChanges, isRug, priceChange } = this.props;
        const changes = priceChanges.map(({ change }) => change);
        const data = changes.length === 1 ? [changes[0], changes[0]] : changes;
        return (
            <Container up={!isRed}>
                <Sparklines data={data}>
                    <SparklinesLine color={isRed ? 'red' : 'currentColor'} style={{ strokeWidth: 4, fill: "none" }} />
                </Sparklines>
                <PriceChangeIndicator
                    priceChange={priceChange}
                    isRug={isRug}
                />
            </Container>
        )
    }
}