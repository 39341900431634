import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { RootState } from 'typesafe-actions';

import Layout from '../components/layout/layout';
import TokenList from '../components/dashboard/token-list';
import Tokens from '../components/popular/tokens'
import * as interfaceActions from '../store/interface/actions';
import PancakeLoader from '../components/animations/pancake_loader_animation.json';
import lottie from 'lottie-web/build/player/lottie_light';
import SearchBar from '../components/search-bar';
import MobileSearchBar from '../components/mobile-search';

import { ReactComponent as Filter } from '../components/svg/filter.svg';
import { ReactComponent as Search } from '../components/svg/search.svg';
import { ReactComponent as ArrowLeft } from '../components/svg/arrow-left.svg';
import { ReactComponent as Cancel } from '../components/svg/cancel.svg';

import FlipMove from 'react-flip-move';
import { DashboardFilters } from '../store/interface/state';

const FrontPageContainer = styled.div`
    ${({ theme }) => theme.layout.desktopWidth}
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 1rem 0rem;

    ${({ theme }) => theme.media.small} {
        padding: 1rem;
        max-width: 100vw;

    }
`;

const TopList = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const NewPairs = styled.div<{ enabled: boolean }>`
${({ theme }) => theme.media.small} {
    
    display: ${({ enabled }) => enabled ? 'none' : 'block'};
}
`;

const MobileButtons = styled.div`
    display: flex;
`;


const SearchAndFilter = styled.div`
    display: flex;
    width: 100%;
    margin-right: 5rem;
    text-align: right;

    align-items: center;
    position: relative;
    ${({ theme }) => theme.media.small} {
        display: none;
    }
`;

const ActiveFilters = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 5rem;
    margin-bottom: 1rem;
    ${({ theme }) => theme.media.small} {
        padding-left: 0rem;
    }
    height: 1.5rem;
    & span {
        color: ${({ theme }) => theme.colors.brandColor1};
        font-weight: 500;
        font-size: 0.75rem;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-right: 0.5rem;
        width: 7rem;
        white-space: nowrap;
    }
`;

const CancelFilter = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
        & svg {

            color: ${({ theme }) => theme.colors.uiColor5};
        }

      }
`;

const ActiveFilter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.375rem 0.5rem;

    height: 1.5rem;
    white-space: nowrap;
    background: ${({ theme }) => theme.colors.uiColor2};
    border-radius: 0.25rem;
    margin-right: 1rem;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 0.75rem;

    letter-spacing: 0.03em;
    text-transform: uppercase;
    & svg {
        width: 0.625rem;
        height: 0.625rem;
        color: ${({ theme }) => theme.colors.uiColor3};
        margin-left: 0.5rem;
        margin-bottom: 1px;
    }
    ${({ theme }) => theme.media.small} {
        margin-right: 0.5rem;
        line-height: 0rem;
    }
`;

const FilterButton = styled.div<{ enabled: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 7.5rem;
    padding: 0.875rem 1.563rem 0.875rem 1.563rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    background-color: ${({ enabled, theme }) => enabled ? theme.colors.brandColor1 : theme.colors.uiColor1};
    border-radius: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.05rem;
    text-transform: uppercase;
    cursor: pointer;
    color: ${({ enabled, theme }) => enabled ? theme.colors.uiColor1 : theme.colors.uiColor5};
    margin-left: 1rem;
    & svg {
        margin-right: 0.625rem;
        color:  ${({ enabled, theme }) => enabled ? theme.colors.uiColor1 : theme.colors.uiColor3};
        height: 1rem;
        width: 1rem;
    }
`;

const MobileFilterButton = styled.div<{ enabled: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 3.4rem;
    height: 3.4rem;
    background-color: ${({ enabled, theme }) => enabled ? theme.colors.brandColor1 : theme.colors.uiColor1};
    cursor: pointer;

    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    border-radius: 3.75rem;
    & svg {
        color: ${({ enabled, theme }) => enabled ? theme.colors.uiColor1 : theme.colors.uiColor3};;
    }
    ${({ theme }) => theme.media.smallUp} {
        display: none;
    }
    ${({ theme }) => theme.media.small} {
        margin-right: 1rem;
        width: 2.8rem;
        height: 2.8rem;
    }

    
    ${({ theme }) => theme.media.extrasmall} {
        margin-right: 0rem;
        width: 3.4rem;
        height: 3.4rem;
    }
`;

const MobileSearchButton = styled.div<{ enabled: boolean }>`
    display: ${({ enabled }) => enabled ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    width: 3.4rem;
    height: 3.4rem;
    background-color: transparent;
    cursor: pointer;

    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    border-radius: 3.75rem;
    & svg {
        color: ${({ theme }) => theme.colors.uiColor3};
    }
    ${({ theme }) => theme.media.smallUp} {
        display: none;
    }
    ${({ theme }) => theme.media.small} {
        margin-right: 1rem;
        width: 2.8rem;
        height: 2.8rem;
    }

    
    ${({ theme }) => theme.media.extrasmall} {
        margin-right: 0.625rem;
        width: 3.4rem;
        height: 3.4rem;
    }
`;

const MobileLeftButton = styled.div<{ enabled: boolean }>`
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    height: 3.4rem;
    background-color: transparent;
    cursor: pointer;

    border-radius: 3.75rem;
    & svg {
        color: ${({ theme }) => theme.colors.uiColor5};
    }
    ${({ theme }) => theme.media.smallUp} {
        display: none;
    }
    ${({ theme }) => theme.media.small} {
        margin-right: 1rem;
    }

    
    ${({ theme }) => theme.media.extrasmall} {
        margin-right: 0.625rem;
    }
`;

const ChooseFilter = styled.div`
    display: flex;
`;

const Slider = styled.div<{ enabled: boolean; }>`
    width: 2.1rem;
    height: 1rem;
    background: ${({ enabled, theme }) => enabled ? 'linear-gradient(132.67deg, #FBF8CC 14.85%, #FDE4CF 82.67%)' : theme.colors.uiColor6};
    border-radius: 3.813rem;
    margin-right: 0.75rem;
    margin-bottom: 1rem;
    margin-top: 0.06rem;
    position: relative;
    cursor: pointer;
`;

const Sliderball = styled.div<{ enabled: boolean; }>`
    width: 0.75rem;
    height: 0.75rem;
    position: absolute;
    border-radius: 3.813rem;
    background-color: ${({ enabled, theme }) => enabled ? theme.colors.uiColor1 : theme.colors.uiColor2};
    top: 0.125rem;
    transform: ${({ enabled }) => enabled ? 'translateX(1.225rem)' : 'translateX(0.125rem)'};
    transition: transform 0.2s ease;
`;

const FiltersDropDown = styled.div<{ enabled: boolean; }>`
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    padding-bottom: 0rem;
    width: 17rem;
    position: absolute;
    top: 5rem;
    right: 0;
    z-index: 5;
    box-shadow: 0px 0px 93px 31px rgba(255, 255, 255, 0.04);
    background-color: ${({ theme }) => theme.colors.uiColor1};
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.uiColor5};
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    line-height: 1rem;
    //transition: all 0.2s ${({ theme }) => theme.transitions.main};
`;

const MobileFiltersDropDown = styled.div<{ enabled: boolean; }>`
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    padding-bottom: 0rem;
    width: 17rem;
    position: absolute;
    top: 4rem;
    right: 0;
    z-index: 5;
    box-shadow: 0px 0px 93px 31px rgba(255, 255, 255, 0.04);
    background-color: ${({ theme }) => theme.colors.uiColor1};
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.uiColor5};
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    line-height: 1rem;
    ${({ theme }) => theme.media.smallUp} {
        
        display: none;
    }
`;

const Title = styled.h2`
    display: flex;
    width: 100%;
    font-family: ${({ theme }) => theme.fonts.main};
    font-size: 2rem;
    font-weight: 500;
    text-align: left;
    padding-left: 5rem;
    height: 2.375rem;
    line-height: 2.375rem;
    position: relative;

    & span {
        font-size: 0.875rem;
        position: absolute;
        margin-left: 1.8rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.uiColor4};
        letter-spacing: 0.05em;
        text-transform: uppercase;
        height: 1rem;
        line-height: 1rem;
        margin-top: 0.2rem;
    }
    ${({ theme }) => theme.media.small} {
        padding-left: 0rem;
        justify-content: space-between;
        & span {
            margin-left: 0.5rem;
        }

    }
`;

const Popular = styled.div`
    width: 100%;
    ${({ theme }) => theme.media.small} {
        width: 100vw;
        & h2 {
            padding-left: 1rem;
        }
    }
`;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const PancakeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12.5rem;
    width: 12.5rem;
    margin-top: 10rem;
`;

/* const PresaleContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 5rem;
    padding-right: 5rem;
    width: 100%;
    ${({ theme }) => theme.media.small} {
        padding-left: 0rem;
        padding-right: 0rem;
    }
    
    
`;

const PresaleContainerHero = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.uiColor6};
    height: 6.625rem;
    border-radius: 1rem;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 1.2rem;
    ${({ theme }) => theme.media.small} {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 1.5rem;
        height: 100%;
    }
`;

const TitleAndText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
    ${({ theme }) => theme.media.small} {
        margin-left: 0rem;
        margin-bottom: 1.5rem;
    }
`;

const PresaleText = styled.div`
    font-weight: 400;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.uiColor7};
    margin-top: 0.2rem;
    
    ${({ theme }) => theme.media.small} {
        max-width: 25rem;
    }
    
`;

const PresaleTitle = styled.div`
    font-weight: 500;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.brandColor1};
`;

const TokenReward = styled.div`
${({ theme }) => theme.media.small} {
    display: none;
}
`;

const ExpressButton = styled.a`
   
    padding: 0.875rem 1.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-left: 1rem;
    min-width: 14rem;
    color: ${({ theme }) => theme.colors.uiColor1} !important;

    & svg {
        width: 1rem;
        height: 1rem;
        margin-top: 0.1rem;
    }

    background-color: ${({ theme }) => theme.colors.brandColor1};
   
    
    &:hover {
        background-color: ${({ theme }) => theme.colors.uiColor5};
        box-shadow: ${({ theme }) => theme.colors.shadow1};
        color: ${({ theme }) => theme.colors.uiColor1};

      }

      ${({ theme }) => theme.media.small} {
        margin-left: 0rem;
        width: 100%;
    }
`; */

const dispatchProps = {
    switchTab: interfaceActions.switchTab,
    setFilter: interfaceActions.setFilter,
    subscribeToDashboard: interfaceActions.subscribeToDashboard.request,
};

type Props = {

} & RouteComponentProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

interface State {
    enabled: boolean;
    isLoading: boolean;
    token: string;
    showFilters: boolean;
    showSearch: boolean;
}

class FrontPage extends React.Component<Props, State> {
    private blockInterval!: NodeJS.Timeout;
    constructor(props: Props) {
        super(props);

        this.state = {
            enabled: false,
            isLoading: true,
            token: '',
            showFilters: false,
            showSearch: false
        }
    }

    private myRef = React.createRef<HTMLDivElement>();
    private anim = React.createRef<HTMLDivElement>();

    componentDidMount = async () => {
        if (!this.props.connected) {
            this.props.subscribeToDashboard([]);
        }
        if (this.myRef.current) {
            //@ts-ignore
            this.anim.current = lottie.loadAnimation({
                container: this.myRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: PancakeLoader,
            });
        }
        window.addEventListener('keydown', this.handleTab, false)
    }

    handleTab = (e: KeyboardEvent) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            if (e.shiftKey) {
                this.props.switchTab(-1);
            } else {
                this.props.switchTab(1);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleTab, false);
    }


    handleFilters = () => {
        this.setState(prevState => ({
            showFilters: !prevState.showFilters,
        }));
    }

    handleSearch = () => {
        this.setState(prevState => ({
            showSearch: !prevState.showSearch,
        }));
    }

    handleFilter = (filter: 'showRugpulls' | 'showUnverified' | 'showSmallLiquidity' | 'showHidden') => {
        this.props.setFilter({ filter, value: !this.props.filters[filter] })
    }

    renderFilters = (filters: DashboardFilters) => {
        const getKeyName = (key: 'showRugpulls' | 'showUnverified' | 'showSmallLiquidity' | 'showHidden') => {
            switch (key) {
                case 'showHidden':
                    return 'Hidden';
                case 'showUnverified':
                    return 'Unverified';
                case 'showSmallLiquidity':
                    return 'Small';
                case 'showRugpulls':
                    return 'Rugpulls';
                default:
                    return 'Hide me';
            }
        }
        const copyFilters: any = Object.assign({}, filters);
        delete copyFilters['searchParameter'];
        const renderedFilters = Object.keys(copyFilters).filter((key) => !copyFilters[key]) as ('showRugpulls' | 'showUnverified' | 'showSmallLiquidity' | 'showHidden')[];
        return renderedFilters.map(
            (key) => (
                (
                    <ActiveFilter
                        key={key}
                    >
                        {getKeyName(key)}
                        <CancelFilter onClick={() => this.handleFilter(key)}>
                            <Cancel />
                        </CancelFilter>
                    </ActiveFilter>
                )
            )
        )
    }

    render() {
        const { filters } = this.props;
        // this.props.numPairs;
        return (
            <Layout>
                <FrontPageContainer>
                    {/*                     <PresaleContainer>
                        <PresaleContainerHero>
                            <TokenReward>
                                <img alt='Reward' src="/tokenreward.png" />
                            </TokenReward>
                            <TitleAndText>
                                <PresaleTitle>Upcoming presale</PresaleTitle>
                                <PresaleText>Join our community to get information and the latest updates on our upcoming presale!</PresaleText>
                            </TitleAndText>
                            <ExpressButton href="https://t.me/TokenExpress" target="_blank">
                                Join Our Telegram
                            </ExpressButton>
                        </PresaleContainerHero>
                    </PresaleContainer> */}
                    <Popular>
                        <Title>Trending pairs</Title>
                        <Tokens />
                    </Popular>
                    <ListContainer>
                        <TopList>
                            <Title>
                                <NewPairs enabled={this.state.showSearch}>New pairs
                                    <span>last 1 hour</span>
                                </NewPairs>
                                <MobileLeftButton enabled={this.state.showSearch} onClick={this.handleSearch}>
                                    <ArrowLeft></ArrowLeft>
                                </MobileLeftButton>
                                <MobileSearchBar enabled={this.state.showSearch}></MobileSearchBar>
                                <MobileButtons>
                                    <MobileSearchButton enabled={this.state.showSearch} onClick={this.handleSearch}> <Search /> </MobileSearchButton>
                                    <MobileFilterButton enabled={this.state.showFilters} onClick={this.handleFilters}>
                                        <Filter />
                                    </MobileFilterButton>
                                </MobileButtons>
                                <MobileFiltersDropDown enabled={this.state.showFilters}>
                                    <ChooseFilter><Slider enabled={filters.showRugpulls} onClick={() => this.handleFilter('showRugpulls')}><Sliderball enabled={filters.showRugpulls}></Sliderball></Slider>Show rugpulls</ChooseFilter>
                                    <ChooseFilter><Slider enabled={filters.showUnverified} onClick={() => this.handleFilter('showUnverified')}><Sliderball enabled={filters.showUnverified}></Sliderball></Slider>Show unverified contracts</ChooseFilter>
                                    <ChooseFilter><Slider enabled={filters.showSmallLiquidity} onClick={() => this.handleFilter('showSmallLiquidity')}><Sliderball enabled={filters.showSmallLiquidity}></Sliderball></Slider>Show small liquidity</ChooseFilter>
                                    <ChooseFilter><Slider enabled={filters.showHidden} onClick={() => this.handleFilter('showHidden')}><Sliderball enabled={filters.showHidden}></Sliderball></Slider>Show hidden</ChooseFilter>
                                </MobileFiltersDropDown>
                            </Title>
                            <SearchAndFilter>
                                <SearchBar />
                                <FilterButton enabled={this.state.showFilters} onClick={this.handleFilters}>
                                    <Filter />
                                    Show
                                </FilterButton>
                                <FiltersDropDown enabled={this.state.showFilters}>
                                    <ChooseFilter><Slider enabled={filters.showRugpulls} onClick={() => this.handleFilter('showRugpulls')}><Sliderball enabled={filters.showRugpulls}></Sliderball></Slider>Show rugpulls</ChooseFilter>
                                    <ChooseFilter><Slider enabled={filters.showUnverified} onClick={() => this.handleFilter('showUnverified')}><Sliderball enabled={filters.showUnverified}></Sliderball></Slider>Show unverified contracts</ChooseFilter>
                                    <ChooseFilter><Slider enabled={filters.showSmallLiquidity} onClick={() => this.handleFilter('showSmallLiquidity')}><Sliderball enabled={filters.showSmallLiquidity}></Sliderball></Slider>Show small liquidity</ChooseFilter>
                                    <ChooseFilter><Slider enabled={filters.showHidden} onClick={() => this.handleFilter('showHidden')}><Sliderball enabled={filters.showHidden}></Sliderball></Slider>Show hidden</ChooseFilter>
                                </FiltersDropDown>
                            </SearchAndFilter>

                        </TopList>
                        <ActiveFilters>
                            <FlipMove
                                maintainContainerHeight={true}
                                staggerDurationBy="20"
                                duration={250}
                                enterAnimation="accordionHorizontal"
                                leaveAnimation="accordionHorizontal"
                                typeName={null}
                            >
                                {
                                    !(filters.showRugpulls && filters.showUnverified && filters.showSmallLiquidity && filters.showHidden) && (
                                        <span key='1'>Active filters: </span>

                                    )
                                }
                                {this.renderFilters(filters)}
                            </FlipMove>
                        </ActiveFilters>
                        {
                            this.props.numPairs === 0 ? (
                                <PancakeContainer ref={this.myRef}></PancakeContainer>
                            ) : (
                                <TokenList />
                            )
                        }

                    </ListContainer>
                </FrontPageContainer>
            </Layout >
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    numPairs: state.pairs.dashboardPairs.length,
    connected: state.socket.connected,
    filters: state.interface.filters,
});

export default connect(
    mapStateToProps,
    dispatchProps
)(withRouter(FrontPage));