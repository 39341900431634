import { createAction, createAsyncAction } from 'typesafe-actions';
import { PairInterface } from '../../types/pairs';

export const createSocket = createAsyncAction(
    'CREATE_SOCKET_REQUEST',
    'CREATE_SOCKET_SUCCESS',
    'CREATE_SOCKET_FAILED'
)<undefined, undefined, string>();

//export const sendMessage = createAction('SEND_MESSAGE')<string>();

export const sendMessage = createAsyncAction(
    'SEND_MESSAGE_REQUEST',
    'SEND_MESSAGE_SUCCESS',
    'SEND_MESSAGE_FAILED'
)<{ type: string, data: any }[], undefined, string>();

export const addSubscription = createAsyncAction(
    'ADD_SUBSCRIPTION_REQUEST',
    'ADD_SUBSCRIPTION_SUCCESS',
    'ADD_SUBSCRIPTION_FAILED'
)<string, PairInterface, string>();

export const removeSubscription = createAsyncAction(
    'REMOVE_SUBSCRIPTION_REQUEST',
    'REMOVE_SUBSCRIPTION_SUCCESS',
    'REMOVE_SUBSCRIPTION_FAILED'
)<string, string, string>();

export const connectSocket = createAsyncAction(
    'CONNECT_SOCKET_REQUEST',
    'CONNECT_SOCKET_SUCCESS',
    'CONNECT_SOCKET_FAILED'
)<string[], undefined, Event>();

export const reconnectSocket = createAsyncAction(
    'RECONNECT_SOCKET_REQUEST',
    'RECONNECT_SOCKET_SUCCESS',
    'RECONNECT_SOCKET_FAILED'
)<undefined, undefined, string>();

export const disconnectSocket = createAsyncAction(
    'DISCONNECT_SOCKET_REQUEST',
    'DISCONNECT_SOCKET_SUCCESS',
    'DISCONNECT_SOCKET_FAILED'
)<undefined, undefined, string>();

export const handleMessage = createAction('HANDLE_MESSAGE_NEW')<{ type: string, data: any }[]>();
export const logMessage = createAction('LOG_MESSAGE')<{ type: string, data: any }[]>();