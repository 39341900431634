import React, { MutableRefObject } from 'react';
import styled from 'styled-components/macro';

import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';

import lottie from 'lottie-web/build/player/lottie_light';
import animationData from './animations/loader.json';

type Props = {
   
} & ReturnType<typeof mapStateToProps>;


interface State {

}

const AnimationContainer = styled.div`
    height: 15rem;
    width: 15rem;
    
`;

class RocketAni extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {

        }
    }

    private animationContainer = React.createRef<HTMLDivElement>();
    private anim = React.createRef<MutableRefObject<null>>();
    private interval: any;

    componentDidMount = () => {
        if (this.animationContainer.current) {
            //@ts-ignore
            this.anim.current = lottie.loadAnimation({
                container: this.animationContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData,
                
                
            });
            
        }
    }

    componentWillUnmount = () => {
        //@ts-ignore
        this.anim.current?.destroy();
        
    }

    

    render() {
        return (
            <AnimationContainer ref={this.animationContainer}>
               
            </AnimationContainer>
        )
    }
}


const mapStateToProps = (state: RootState) => {
    
    
    return {
        
    }
};

export default connect(
    mapStateToProps,
)(RocketAni);