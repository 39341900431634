import Web3 from 'web3';
import * as React from 'react';
import styled from 'styled-components/macro';
import { Sparklines, SparklinesCurve } from 'react-sparklines';

import PoolStatus from './pool';
import Timestamp from './timestamp';
import { ReactComponent as Warning } from '../svg/warning.svg';
import { PairInterface, PriceChange, TOETransaction } from '../../types/pairs';

const TopContainer = styled.div`
    grid-area: Chart;
    display: flex;
    flex-direction: column;
    width: 100%;
    ${({ theme }) => theme.media.small} {
       margin-top: 1rem;
    } 
`;

const Container = styled.span<{ up: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-top: 3.5rem;
    border-radius: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    & svg {
        margin-bottom: -3rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        width: 110%;
        height: 20rem;
        color: ${({ theme, up }) => theme.colors[up ? 'triggerColor5' : 'triggerColor1']};

        ${({ theme }) => theme.media.small} {
            height: 14rem;
        }
    }
`;

const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 2rem;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 1.5rem;
`;

const TimePassed = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    border-radius: 0.25rem;
    padding: 3px 9px;
    margin-top: 0.2rem;
    background-color: ${({ theme }) => theme.colors.uiColor2};
`;

const RugStamp = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.colors.triggerColor1};
    color: ${({ theme }) => theme.colors.uiColor5};
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    width: 18.125rem;
    height: 5.625rem;
    padding: 0.875rem 1.313rem;
    line-height: 55px;
    font-weight: 500;
    font-size: 2.864rem;
    margin-bottom: 1.9rem;
    & svg {
        position: relative;
        color: ${({ theme }) => theme.colors.triggerColor2};
        width: 2.5rem !important;
        height: 2.344rem !important;

        margin-left: 0rem;
        margin-right: 0rem;
        margin-bottom: 0rem;
    }
`;

/* const Buy = styled.div<{ x: number, y: number }>`
    background-color: white;
    position: absolute;
    margin-top: 3.6rem;
    margin-right: 0.4rem;
    top: ${({ y }) => Math.floor(y * 100)}%;
    left: ${({ x }) => Math.floor(x * 100)}%;
    
    font-size: 0.8rem;
    display: flex;
    font-weight: 500;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors['brandColor1']};

    text-align: center;
    padding: 3px 6px;
    color: ${({ theme }) => theme.colors.uiColor1};
    border-radius: 4px;
    letter-spacing: 0.03em;
`; */

const Pool = styled(PoolStatus)`
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
`;

const LinearGradientFill = () => {
    return (
        <linearGradient id="anothergradient" x1="0%" y1="0%" x2="0%" y2="100%">

            <stop offset="0%" stopColor="rgb(51, 236, 126)" stopOpacity="1" />
            <stop offset="70%" stopColor="rgb(51, 236, 126)" stopOpacity="0.3" />
            <stop offset="100%" stopColor="rgb(51, 236, 126)" stopOpacity="0" />
        </linearGradient>
    );
};

const AnotherLinearGradientFill = () => {
    return (
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">

            <stop offset="0%" stopColor="rgb(255, 0, 0)" stopOpacity="1" />
            <stop offset="70%" stopColor="rgb(255, 0, 0)" stopOpacity="0.3" />
            <stop offset="100%" stopColor="rgb(255, 0, 0)" stopOpacity="0" />
        </linearGradient>
    );
};

type Props = {
    priceChanges: PriceChange[];
    toeTransactions: TOETransaction[];
    priceChange: number;
    isRug: boolean;
} & Pick<PairInterface, 'timestamp'>;

export default class Chart extends React.Component<Props> {

    isOld = (timestamp: number) => {
        const now = Math.floor(new Date().getTime() / 1000);
        return now - timestamp > 86400; // 24 klst
    }

    extractBuyPoints = (
        swaps: TOETransaction[],
        priceChanges: PriceChange[]
    ): { type: string, x: number, y: number }[] => {
        return swaps.map((tx) => {
            const priceChange = priceChanges.reduce(
                (acc: { timestamp: number, change: number, index: number }, pc, i) => {
                    const diff = Math.abs(pc.timestamp - tx.timestamp);
                    const lastDiff = Math.abs(acc.timestamp - tx.timestamp);
                    if (i === 0 || diff < lastDiff) {
                        return {
                            timestamp: pc.timestamp,
                            change: pc.change,
                            index: i
                        }
                    } else {
                        return acc;
                    }
                },
                { timestamp: 0, change: 0, index: -1 }
            );
            const x = (priceChange.index + 1) / priceChanges.length;
            const changes = priceChanges.map(({ change }) => change);
            //const min = Math.min(...changes);
            const max = Math.max(...changes);
            const fromTop = max - priceChange.change;
            const y = fromTop / max;
            const baseIn = Web3.utils.toBN(tx.baseIn);
            const baseOut = Web3.utils.toBN(tx.baseOut);
            const type = baseOut.gt(baseIn) ? 'buy' : 'sell';
            return { type, x, y };
        });
    }

    render() {
        const { isRug, priceChange, priceChanges, timestamp } = this.props;
        const isOld = this.isOld(timestamp);
        const data = priceChanges.map(({ change }) => change);
        return (
            <TopContainer>
                <TitleContainer>
                    <Title>Price change</Title>
                    <TimePassed>
                        {
                            isOld ? (<>LAST 24H</>) : <Timestamp timestamp={timestamp} minimal={true} />
                        }
                    </TimePassed>
                </TitleContainer>
                <Container up={!isRug && priceChange >= 1}>
                    <Pool
                        isOld={isOld}
                        isRug={isRug}
                        priceChange={priceChange}
                        ath={Math.max(...data)}
                    />
                    {
                        isRug && (
                            <RugStamp>
                                <Warning />
                                RUGGED
                            </RugStamp>
                        )
                    }
                    <Sparklines data={data.length === 1 ? data.concat(data) : data}>
                        <svg>
                            <defs>
                                <LinearGradientFill />
                                <AnotherLinearGradientFill />
                            </defs>
                        </svg>
                        {/* SparklinesCurve vegna þess að annars eru svona heimskir fjandans punktar - þ.e.a.s ef hann vill gradient. */}
                        <SparklinesCurve
                            color={(!isRug && priceChange === 1) ? 'gray' : 'currentColor'}
                            style={{ strokeWidth: 1, fill: (isRug || priceChange < 1) ? 'url(#gradient)' : 'url(#anothergradient)' }}
                        />
                    </Sparklines>
                    {/* {
                        this.props.buyPoints.map(
                            (point, i) => (
                                <Buy x={point.x} y={point.y} key={i}>{point.type === 'buy' ? 'BUY' : 'SELL'}</Buy>
                            )
                        )
                    } */}
                </Container>
            </TopContainer>
        )
    }
}