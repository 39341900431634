import Web3 from 'web3';

import {
    archiveEndpoints,
    httpEndpoints,
    wsEndpoints
} from '../constants/rpc-endpoints';

const getProviderOptions = () => ({
    timeout: 30000, // ms
    clientConfig: {
        // Useful if requests are large
        maxReceivedFrameSize: 100000000, // bytes
        maxReceivedMessageSize: 100000000, // bytes
        // Useful to keep a connection alive
        keepalive: true,
        keepaliveInterval: 60000 // ms
    },
    // Enable auto reconnection
    reconnect: {
        auto: true,
        delay: 10000, // ms
        maxAttempts: 5,
        onTimeout: false
    }
});

const getRandNumOmitSome = (max: number, omit: number[]): number => {
    const random = Math.floor(Math.random() * max);
    if (omit.includes(random)) {
        return getRandNumOmitSome(max, omit)
    } else {
        return random;
    }
}

export const getWeb3 = async (omit: number[] = []): Promise<Web3> => {
    const random = getRandNumOmitSome(httpEndpoints.length - 1, omit);
    const endpoint = httpEndpoints[random];
    const web3 = new Web3(endpoint);
    if (await web3.eth.net.isListening()) {
        return web3;
    } else {
        console.debug(`HTTP ${endpoint} failed to connect`);
        return getWeb3(omit.concat(random));
    }
}

export const getWeb3Archive = async (omit: number[] = []): Promise<Web3> => {
    const web3 = new Web3(archiveEndpoints[0]);
    return web3;
}

export const getWeb3Test = async (): Promise<Web3> => {
    return new Web3('https://speedy-nodes-nyc.moralis.io/0f071ac00c7db7ebfe09ec52/bsc/testnet');
}

export const getWeb3WS = async (omit: number[] = []): Promise<Web3> => {
    const omitted = omit.length === wsEndpoints.length ? [] : omit;
    const random = getRandNumOmitSome(wsEndpoints.length - 1, omitted);
    const endpoint = wsEndpoints[random];
    console.debug(`Connecting to ws provider: ${endpoint}`);
    const provider = new Web3.providers.WebsocketProvider(
        endpoint,
        getProviderOptions()
    );
    const web3 = new Web3(provider);
    return testConnection(web3)
        .then(() => {
            console.debug('Connected!');
            return web3;
        }).catch(() => {
            console.debug(`Connection failed to ws provider: ${endpoint}`);
            return getWeb3WS(omitted.concat(random));
        });
}

const testConnection = async (web3: Web3) => {
    return new Promise((resolve, reject) => {
        setTimeout(reject, 10000);
        web3.eth.net.isListening().then(resolve).catch(reject);
    })
}