import { EMPTY, from, of, merge, timer, Observable } from 'rxjs';
import { filter, switchMap, mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { EpicWithTypes, isActionOf, PayloadAction, RootState } from 'typesafe-actions';
import * as interfaceActions from './actions';
import * as socketActions from '../socket/actions';
import { StateObservable } from 'redux-observable';

export const subscribeToDashboardEpic: EpicWithTypes = (action$) =>
    action$.pipe(
        filter(isActionOf(interfaceActions.subscribeToDashboard.request)),
        switchMap((action) =>
            of(
                interfaceActions.openPage.success({ pathname: '/', symbol: '...' }),
                socketActions.connectSocket.request(action.payload)
            )
        )
    );

export const createSocketSuccessEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(socketActions.connectSocket.success)),
        map(interfaceActions.subscribeToDashboard.success)
    );

const withOpenPageData = (state$: StateObservable<RootState>) => <T extends PayloadAction<any, string>>(source$: Observable<T>) => {
    return source$.pipe(
        withLatestFrom(state$),
        map(([action, state]) => ({
            pathname: action.payload,
            pairs: state.pairs,
            isOpen: !!state.interface.openPages.find((page) => page.pathname === action.payload),
            isPair: action.payload.startsWith('/pair'),
        }))
    )
}

export const openPageEpic: EpicWithTypes = (action$, state$, { addressUtils }) =>
    merge(
        action$.pipe(
            filter(isActionOf(interfaceActions.openPage.request)),
            withOpenPageData(state$),
            filter(({ isOpen }) => isOpen),
            mergeMap(({ pathname }) => merge(
                of(interfaceActions.updatePage({ pathname, notification: 'isOpen' })),
                timer(1500).pipe(
                    map(() => interfaceActions.updatePage({ pathname, notification: '' }))
                )
            ))
        ),
        action$.pipe(
            filter(isActionOf(interfaceActions.openPage.request)),
            withOpenPageData(state$),
            map((p) => ({ ...p, pageTab: addressUtils.pageTabFromPathname(p.pairs, p.pathname) })),
            filter(({ isOpen, isPair }) => !isOpen && isPair),
            mergeMap(({ pageTab, pathname }) => of(
                interfaceActions.openPage.success(pageTab),
                interfaceActions.openPairPage.request(pathname),
            )),
            catchError((error) => of(interfaceActions.openPage.failure({ pathname: error.pathname, error: error.message })))
        )
    );

export const openPairPageEpic: EpicWithTypes = (action$, state$, { addressUtils }) =>
    action$.pipe(
        filter(isActionOf(interfaceActions.openPairPage.request)),
        map(({ payload }) => addressUtils.addressFromPathname(payload)),
        withLatestFrom(state$),
        mergeMap(
            ([address, state]) => !state.socket.connected
                ? of(interfaceActions.subscribeToDashboard.request([address]))
                : of(socketActions.addSubscription.request(address))
        )
    );

export const closePageEpic: EpicWithTypes = (action$, state, { history }) =>
    action$.pipe(
        filter(isActionOf(interfaceActions.closePage.request)),
        mergeMap(
            (action) => {
                const actions: PayloadAction<any, any>[] = [
                    interfaceActions.closePage.success(action.payload),
                ];
                if (action.payload.includes('/pair/')) {
                    actions.push(interfaceActions.closePairPage.request(action.payload))
                }
                if (history.location.pathname === action.payload) {
                    actions.push(interfaceActions.switchTab(-1))
                }

                return of(...actions);
            }
        )
    );

export const closePairPageEpic: EpicWithTypes = (action$, state$, { addressUtils }) =>
    action$.pipe(
        filter(isActionOf(interfaceActions.closePairPage.request)),
        map(({ payload }) => addressUtils.addressFromPathname(payload)),
        map((checksumAddress) => socketActions.removeSubscription.request(checksumAddress))
    );

export const switchTabEpic: EpicWithTypes = (action$, state, { api, history, addressUtils }) =>
    action$.pipe(
        filter(isActionOf(interfaceActions.switchTab)),
        mergeMap(
            (action) => {
                const increment = action.payload;
                const pathname = history.location.pathname;
                const openPages = state.value.interface.openPages;
                const index = openPages.findIndex((page) => page.pathname === pathname);
                const nextIndex = index + increment;
                const nextPageIndex = nextIndex < 0 ? openPages.length - 1 : nextIndex > openPages.length - 1 ? 0 : nextIndex;
                const nextTab = openPages[nextPageIndex] || '/';
                history.push(nextTab.pathname);
                return EMPTY;
            }
        )
    );

export const switchPairEpic: EpicWithTypes = (action$, state$, { api, history, addressUtils }) =>
    action$.pipe(
        filter(isActionOf(interfaceActions.switchPair.request)),
        withLatestFrom(state$),
        map(
            ([{ payload }, state]) => {
                const pathname = `/pair/${payload.to}`;
                history.push(pathname);
                const page = addressUtils.pageTabFromPathname(state.pairs, pathname);
                return interfaceActions.switchPair.success({ from: payload.from, page })
            }
        )
    );

export const getBNBPriceEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(interfaceActions.getBNBPrice.request)),
        switchMap((action) =>
            from(api.toeRouter.getBNBPrice()).pipe(
                map(interfaceActions.getBNBPrice.success),
                catchError((message: string) => of(interfaceActions.getBNBPrice.failure(message)))
            )
        )
    );

export const getNewestBlockEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(interfaceActions.getNewestBlock.request)),
        switchMap((action) =>
            from(api.web3.getNewestBlock()).pipe(
                map(interfaceActions.getNewestBlock.success),
                catchError((message: string) => of(interfaceActions.getNewestBlock.failure(message)))
            )
        )
    );