import * as React from 'react';
import styled from 'styled-components/macro';

const PriceChangeContainer = styled.span<{ up: boolean }>`
    text-align: center;
    background-color: ${({ theme, up }) => theme.colors[up ? 'triggerColor5' : 'triggerColor1']};
    padding: 4px 9px;
    color: ${({ theme }) => theme.colors.uiColor1};
    border-radius: 4px;
    letter-spacing: 0.03em;
    font-size: 0.75rem;
`;

type Props = {
    priceChange: number;
    isRug: boolean;
}

export const PriceChangeIndicator: React.FC<Props> = ({ priceChange, isRug }) => {
    const isDown = priceChange < 1;
    const isRed = isDown || isRug;
    let fixedPriceChange = 'X';
    try {
        fixedPriceChange = priceChange.toFixed(2);
    } catch (error) { }
    return (
        <PriceChangeContainer up={!isRed} >
            {isRug ? 'RUG' : priceChange > 1000 ? '> 1000X' : `${fixedPriceChange}X`}
        </PriceChangeContainer >
    )
}

export default PriceChangeIndicator;