import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { RootAction, RootState, Dependencies } from 'typesafe-actions';
import rootReducer from './root-reducer';
import rootEpic from './root-epic';
import { initialState } from './root-state';
import api from '../services';
import * as addressUtils from '../utils/address';
import history from '../utils/history';

import socketMiddleware from './socket/middleware';


const initStore = (state: RootState = initialState) => {
    const epicMiddleware = createEpicMiddleware<
        RootAction,
        RootAction,
        RootState,
        Dependencies
    >({
        dependencies: {
            api,
            history,
            addressUtils,
        },
    });

    const middlewares = [epicMiddleware, socketMiddleware];

    const store = createStore(
        rootReducer,
        state,
        composeWithDevTools(applyMiddleware(...middlewares))
    );

    epicMiddleware.run(rootEpic);

    return store;
};

export default initStore;