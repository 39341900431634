import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { getType } from 'typesafe-actions';
import { RootState } from 'typesafe-actions';
import * as socketActions from './actions';
import * as pairsActions from '../pairs/actions';
import * as interfaceActions from '../interface/actions';

import { Database, getDatabase, ref, get, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { PairInterface } from "../../types/pairs";
import initializeFirebase from '../../utils/firebase';

class SocketMiddlewareManager {
    private socket?: WebSocket;

    private db: Database;
    private useDevelopment = process.env.NODE_ENV === 'development';

    constructor() {
        initializeFirebase();
        this.db = getDatabase();
    }

    addEmptyArrays = (pair: PairInterface): PairInterface => ({
        ...pair,
        data: {
            ...pair.data,
            swaps: pair.data.swaps ? pair.data.swaps : [],
            locks: pair.data.locks ? pair.data.locks : []
        }
    })

    createSocket = async (store: MiddlewareAPI<Dispatch<AnyAction>, RootState>, tryProduction?: boolean): Promise<void> => {
        get(ref(this.db, `dashboard`)).then(
            (snapshot) => {
                const dashboard = snapshot.val() as { [key: string]: PairInterface };
                const pairs = Object.keys(dashboard).map((key) => dashboard[key]);
                const dashboardPairs = pairs.map(this.addEmptyArrays);
                store.dispatch(pairsActions.initDashboard(dashboardPairs));
            }
        ).finally(
            () => {
                store.dispatch(socketActions.connectSocket.success());
                onChildAdded(
                    ref(this.db, 'dashboard'),
                    (snapshot) => {
                        const dashboardPairs = store.getState().pairs.dashboardPairs;
                        const pair = snapshot.val() as PairInterface;
                        const exists = dashboardPairs.find(({ address }) => address === pair.address);
                        if (!exists) {
                            store.dispatch(pairsActions.addPair(this.addEmptyArrays(pair)));
                        }
                    }
                )
                onChildChanged(
                    ref(this.db, 'dashboard'),
                    (snapshot) => {
                        const pair = snapshot.val() as PairInterface;
                        store.dispatch(pairsActions.updatePair(this.addEmptyArrays(pair)));
                    }
                )

                onChildRemoved(
                    ref(this.db, 'dashboard'),
                    (snapshot) => {
                        const pair = snapshot.val() as PairInterface;
                        store.dispatch(pairsActions.removePair(this.addEmptyArrays(pair)));
                    }
                )
            }
        )
    }

    handleMessage = (store: MiddlewareAPI<Dispatch<AnyAction>, RootState>, type: string, data: any) => {
        switch (type) {
            case 'dashboard':
                return store.dispatch(pairsActions.initDashboard(data));
            case 'dashboard-update':
                return store.dispatch(pairsActions.updateDashboard(data));
            case 'custom-pairs':
                return store.dispatch(pairsActions.updateCustomPairs(data));
            case 'switch-pair':
                return store.dispatch(interfaceActions.switchPair.request(data));
            case 'add-subscription-error':
                return store.dispatch(interfaceActions.updatePage({ pathname: `/pair/${data.address}`, error: data.error }));
            default:
                console.debug(type, data);
                return;
        }
    }

    sendMessage = async (message: any, callback?: () => void): Promise<void> => {
        try {
            this.socket?.send(JSON.stringify(message));
            return Promise.resolve();
        } catch (error) {
            return Promise.reject();
        }
    }

    getMiddleware = (): Middleware => (store) => (next) => async (action) => {
        if (action.type === getType(socketActions.connectSocket.request)) {
            return this.createSocket(store)
        } else if (action.type === getType(socketActions.createSocket.failure)) {
            await new Promise(r => setTimeout(r, 100));
            store.dispatch(socketActions.createSocket.request());
        } else if (action.type === getType(socketActions.sendMessage.request)) {
            return this.sendMessage(action.payload);
        } else if (action.type === getType(socketActions.addSubscription.request)) {
            return this.sendMessage({ type: 'add-subscription', data: action.payload })
                .catch(() => store.dispatch(socketActions.addSubscription.failure(action.payload)));
        } else if (action.type === getType(socketActions.removeSubscription.request)) {
            return this.sendMessage({ type: 'remove-subscription', data: action.payload })
                .catch(() => store.dispatch(socketActions.removeSubscription.failure(action.payload)));
        }
        return next(action);
    }
}

const manager = new SocketMiddlewareManager();

export default manager.getMiddleware();