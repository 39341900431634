import Web3 from 'web3';

import { BlockTimestamp } from '../types/web3';

export const getNewestBlock = async (): Promise<{ blockTimestamp: BlockTimestamp, difference: number }> => {
    const web3 = new Web3('https://bsc-dataseed.binance.org/');
    const blockNumber = await web3.eth.getBlockNumber();
    const { number, timestamp } = await web3.eth.getBlock(blockNumber);
    let usedTimestamp: number;
    if (typeof (timestamp) === 'string') {
        usedTimestamp = parseInt(timestamp);
    } else {
        usedTimestamp = timestamp;
    }
    //const privateBlock = await getNewestBlockPrivate();

    const difference = 0; //privateBlock.blockNumber - number;

    console.log('Difference: ', difference);
    return Promise.resolve({
        blockTimestamp: {
            blockNumber: number,
            timestamp: usedTimestamp,
        },
        difference
    });
}

export const getNewestBlockPrivate = async (): Promise<BlockTimestamp> => {
    const web3 = new Web3('https://dataseed2.breki.io');
    const blockNumber = await web3.eth.getBlockNumber();
    const { number, timestamp } = await web3.eth.getBlock(blockNumber);
    let usedTimestamp: number;
    if (typeof (timestamp) === 'string') {
        usedTimestamp = parseInt(timestamp);
    } else {
        usedTimestamp = timestamp;
    }

    return Promise.resolve({ blockNumber: number, timestamp: usedTimestamp });
}