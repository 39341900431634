import Web3 from 'web3';
import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import BN from 'bn.js';

import {
    estimateOutputBuy,
    estimateOutputSell,
    fromWeiCustomDecimals,
    toWeiCustomDecimals
} from '../../../utils/numbers';

import { ReactComponent as Binance } from '../../svg/binance.svg';
import { BUSDAddress } from '../../../constants/addresses';
import { PairInterface } from '../../../types/pairs';

const TokenInputContainer = styled.div`
    width: 100%;
`;

const SwapAmount = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem 0rem 1.3rem;
    ${({ theme }) => theme.media.extrasmall} {
        padding: 1rem 1.5rem 0rem 0.85rem;
    }
`;

const Amount = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 2rem;
    align-items: center;
    & span {
        font-weight: 500;
        font-size: 0.75rem;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-left: 0.45rem;
        color: ${({ theme }) => theme.colors.uiColor4};
    }
    line-height: 2.2rem;
`;

const AmountRightBuy = styled.div`
    display: flex;
    align-items: center;
`;

const TextInput = styled.input.attrs({
    type: 'text',
}) <{ error: boolean; ruslWidth: boolean; }>` 
    outline: none;
    border: none;
    //border: 2px solid transparent;
    //border-bottom: 2px solid ${({ theme, error }) => error ? theme.colors.lightRed : 'transparent'};
    //width: 100%;
    background: transparent;
    font-size: 1.5rem;
    //width: 20%;
    //width: 25%;
    width: ${({ ruslWidth }) => ruslWidth ? 'auto' : `25%`};
    max-width: 12rem;
    min-width: 4rem;
    font-family: ${({ theme }) => theme.fonts.main};
    color: ${({ theme }) => theme.colors.brandColor1};
    //transition: all 0.3s ${({ theme }) => theme.transitions.main};
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme }) => theme.colors.uiColor4};
        font-family: ${({ theme }) => theme.fonts.main}
      }
    
`;

const TokenType = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 0.875rem;
    justify-content: center;
    color: white;
    background-color: ${({ theme }) => theme.colors.uiColor2};
    border-radius: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2.2rem;
    & svg {
        color: ${({ theme }) => theme.colors.brandColor1};
        margin-top: -0.1rem;
    }
    & span {
        margin-left: 0.6rem;
    }
    margin-left: 0.7rem;
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.brandColor4};
    height: 1.1rem;
    width: 1.1rem;
    color: ${({ theme }) => theme.colors.uiColor1};
    border-radius: 1rem;
    font-weight: 500;
    margin-top: 0.1rem;
`;

const OtherToken = styled.div`
    display: flex;
    align-items: center;
    color: white;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.875rem;
    padding-left: 1.7rem;
    margin-top: 0.5rem;
    & span {
        margin-left: 0.65rem;
        color: ${({ theme }) => theme.colors.uiColor4};
    }
`;

const AmountButtons = styled.div`
    margin-top: 1.2rem;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 0rem 0.5rem; 
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    font-weight: 500;
    font-size: 0.875rem;
`

const AmountButton = styled.div<{ enabled?: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ enabled, theme }) => enabled ? 'none' : `1px solid ${theme.colors.uiColor2}`};
    border-radius: 0.5rem;
    height: 2.8rem;
    cursor: pointer;
    background-color: ${({ enabled, theme }) => enabled ? theme.colors.brandColor1 : 'none'};
    color: ${({ enabled, theme }) => enabled ? theme.colors.uiColor1 : theme.colors.uiColor5};

    &:hover {

        border: ${({ enabled, theme }) => enabled ? 'none' : `1px solid ${theme.colors.uiColor5}`};
    }
`

const dispatchProps = {

}

type OwnProps = {
    amount: string;
    buy: boolean;
    onChange: (amount: string, realAmount: BN) => void;
} & Pick<PairInterface, 'address' | 'token' | 'baseToken' | 'isToken0' | 'currentReserves'>;

type Props = OwnProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

interface State {
    error: boolean;
    level: string;
}

class TokenInput extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            error: false,
            level: '',
        }
    }

    componentDidUpdate = (prevProps: Props) => {
        /*         if (prevProps.amount != this.props.amount) {
                    this.setState({ level: '' });
                } */
    }

    onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const legit = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
        const amount = e.currentTarget.value;
        const chars = amount.split('');

        if (!chars.reduce((isLegit: boolean, char: string) => !legit.includes(char) ? false : isLegit, true)) {
            // Only allow legit characters
            return;
        } else if (chars[chars.length - 1] === '.' && (chars[chars.length - 2] === '.' || chars.filter(c => c === '.').length > 1)) {
            // Only allow one decimal point
            return;
        } else if (amount.indexOf('.') >= 0) {
            // Only allow as many fraction digits as this.props.decimals
            const fractions = amount.split('.')[1];
            if (fractions.length > (this.props.buy ? this.props.baseToken.decimals : this.props.token.decimals)) {
                return;
            }
        }
        this.setState({
            level: '',
        });
        const decimals = this.props.buy ? this.props.baseToken.decimals : this.props.token.decimals;
        this.props.onChange(amount, toWeiCustomDecimals(amount, decimals));
    }

    estimateOutput = (): string => {
        let parsedAmount: BN;
        let amount = '';
        if (this.props.baseToken.address === BUSDAddress && this.props.buy) {
            if (this.props.bnbPrice) {
                amount = (parseFloat(this.props.amount) * this.props.bnbPrice).toString();
            } else {
                amount = '';
            }
        } else {
            amount = this.props.amount;
        }
        if (!amount) {
            parsedAmount = new BN(0);
        } else {
            const parsed = amount[0] === '.' ? '0' + amount : amount;
            parsedAmount = toWeiCustomDecimals(parsed, this.props.buy ? this.props.baseToken.decimals : this.props.token.decimals);
        }
        let estimated = new BN(0);
        if (this.props.buy) {
            estimated = estimateOutputBuy(
                new BN(this.props.isToken0 ? this.props.reserve1 : this.props.reserve0),
                new BN(this.props.isToken0 ? this.props.reserve0 : this.props.reserve1),
                this.props.buyTax,
                parsedAmount,
            );
        } else {
            estimated = estimateOutputSell(
                new BN(this.props.isToken0 ? this.props.reserve0 : this.props.reserve1),
                new BN(this.props.isToken0 ? this.props.reserve1 : this.props.reserve0),
                this.props.sellTax,
                parsedAmount,
            );
            if (this.props.baseToken.address === BUSDAddress && this.props.bnbPrice) {
                estimated = estimated.div(new BN(this.props.bnbPrice));
            }
        }
        return fromWeiCustomDecimals(estimated.toString(), this.props.buy ? this.props.token.decimals : this.props.baseToken.decimals);
    }

    getDollarValue = () => {
        if (!!this.props.bnbPrice) {
            let value = 0;
            if (this.props.buy) {
                value = (parseFloat(this.props.amount || '0') * this.props.bnbPrice);
            } else {
                value = parseFloat(this.estimateOutput()) * this.props.bnbPrice;
            }
            const realValue = Math.min(value, 1000000);
            return `${realValue < value ? '>' : ''} $${realValue.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).replace('$', '')}`
        } else {
            return '$';
        }
    }

    onMax = () => {
        const principal = this.props.bnbBalance.sub(new BN('10000000000000000'));
        const balance = fromWeiCustomDecimals(principal.toString(), this.props.baseToken.decimals);
        const amount = parseFloat(balance) <= 0 ? '' : parseFloat(balance).toFixed(4);
        this.setState({
            level: 'd',
        });
        this.props.onChange(amount, principal);
    }

    triggerLevel = (level: string) => {
        if (this.props.buy) {
            let amount = '';
            let realAmount = new BN(0)
            switch (level) {
                case 'a':
                    amount = '0.01';
                    realAmount = new BN('10000000000000000')
                    break;
                case 'b':
                    amount = '0.05';
                    realAmount = new BN('50000000000000000')
                    break;
                case 'c':
                    realAmount = new BN('100000000000000000')
                    amount = '0.1';
                    break;
                case 'd':
                    return this.onMax();
            }
            this.setState({ level });
            this.props.onChange(amount, realAmount);
        } else {
            const balance = this.props.tokenBalance;
            let amount = '';
            let realAmount = new BN(0);
            if (balance.eq(new BN(0))) {
                amount = '';
            } else {
                switch (level) {
                    case 'a':
                        realAmount = balance.div(new BN(4));
                        amount = fromWeiCustomDecimals(realAmount.toString(), this.props.token.decimals);
                        break;
                    case 'b':
                        realAmount = balance.div(new BN(2));
                        amount = fromWeiCustomDecimals(realAmount.toString(), this.props.token.decimals);
                        break;
                    case 'c':
                        realAmount = balance.div(new BN(4)).mul(new BN(3));
                        amount = fromWeiCustomDecimals(realAmount.toString(), this.props.token.decimals);
                        break;
                    case 'd':
                        realAmount = balance;
                        amount = fromWeiCustomDecimals(realAmount.toString(), this.props.token.decimals);
                        break;
                }
            }
            this.setState({ level });
            this.props.onChange(amount, realAmount);
        }
    }

    render() {
        const {
            error,
        } = this.state;
        return (
            <TokenInputContainer>
                <SwapAmount>
                    <Amount>
                        <TextInput
                            onChange={this.onAmountChange}
                            error={error}
                            placeholder={'0.0'}
                            value={this.props.amount}
                            size={this.props.amount.length}
                            ruslWidth={!!this.props.amount}
                        />
                        {
                            !!this.props.bnbPrice && (
                                <span>{this.getDollarValue()}</span>
                            )
                        }
                    </Amount>
                    <AmountRightBuy>
                        <TokenType>
                            {this.props.buy ? (<Binance />) : (<LogoContainer>?</LogoContainer>)}
                            <span>{this.props.buy ? 'BNB' : this.props.token.symbol}</span>
                        </TokenType>
                    </AmountRightBuy>
                </SwapAmount>
                <OtherToken>
                    {parseFloat(this.estimateOutput()).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: this.props.buy ? 0 : 4,
                        maximumFractionDigits: this.props.buy ? 0 : 4,
                    }).replace('$', '')} <span>{this.props.buy ? this.props.token.symbol : 'BNB'}</span>
                </OtherToken>
                <AmountButtons>
                    <AmountButton
                        onClick={() => this.triggerLevel('a')}
                        enabled={this.state.level === 'a' && !this.props.loading}>
                        {this.props.buy ? '0.01' : '25%'}
                    </AmountButton>
                    <AmountButton
                        onClick={() => this.triggerLevel('b')}
                        enabled={this.state.level === 'b' && !this.props.loading}>
                        {this.props.buy ? '0.05' : '50%'}
                    </AmountButton>
                    <AmountButton
                        onClick={() => this.triggerLevel('c')}
                        enabled={this.state.level === 'c' && !this.props.loading}>
                        {this.props.buy ? '0.1' : '75%'}
                    </AmountButton>
                    <AmountButton
                        onClick={() => this.triggerLevel('d')}
                        enabled={this.state.level === 'd' && !this.props.loading}>
                        MAX
                    </AmountButton>
                </AmountButtons>
            </TokenInputContainer>
        )
    }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
    const tokenBalance = state.wallet.balances[ownProps.address] || new BN(0);
    const account = state.wallet.account;
    const bnbPrice = state.interface.BNBPrice;
    const bnbBalance = state.wallet.balances.bnb || new BN(0);
    const allowance = state.wallet.allowances[ownProps.address] || new BN(0);
    const hasSimulations = Object.keys(state.analyze.tokens).includes(ownProps.address);
    const simulations = hasSimulations ? state.analyze.tokens[ownProps.address].result : undefined;
    const buyTax = simulations ? simulations.buy.success ? simulations.buy.tax : new BN(0) : new BN(0);
    const sellTax = simulations ? simulations.sell.success ? simulations.sell.tax : new BN(0) : new BN(0);
    const buyLoading = state.wallet.buyLoading || !!state.wallet.buyErrorMessage;
    const sellLoading = state.wallet.sellLoading || state.wallet.approveLoading || !!state.wallet.sellErrorMessage;

    return {
        account,
        allowance,
        tokenBalance,
        bnbPrice,
        bnbBalance,
        buyTax,
        reserve0: Web3.utils.toBN(ownProps.currentReserves.reserve0),
        reserve1: Web3.utils.toBN(ownProps.currentReserves.reserve1),
        sellTax,
        loading: ownProps.buy ? buyLoading : sellLoading,
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(TokenInput);