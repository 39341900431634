import * as React from 'react';
import Web3 from 'web3';
import { simplify } from '../../utils/numbers';

type Props = {
    initialLiquidity: string;
    baseSymbol: string;
}

export const Liquidity: React.FunctionComponent<Props> = (props) => {
    const initialLiquidity = Web3.utils.fromWei(props.initialLiquidity);
    const decimals = props.baseSymbol === 'BUSD' ? 0 : 1;
    const liquidity = parseFloat(initialLiquidity).toFixed(decimals);
    return (
        <span>
            {`${simplify(liquidity)} ${props.baseSymbol === 'WBNB' ? 'BNB' : props.baseSymbol}`}
        </span>
    )
}

export default Liquidity;