import { from, merge, of } from 'rxjs';
import { filter, switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { EpicWithTypes, isActionOf } from 'typesafe-actions';
import * as walletActions from './actions';
import * as socketActions from '../socket/actions';

export const connectWalletEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.connectWallet.request)),
        switchMap((action) =>
            from(api.wallet.connectWallet()).pipe(
                map(walletActions.connectWallet.success),
                catchError((message: string) => of(walletActions.connectWallet.failure(message)))
            )
        )
    );

export const initWalletEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.setWallet.request)),
        mergeMap((action) => {
            const balances = state$.value.wallet.balances;
            const bnbBalanceResult = from(api.wallet.getBalance()).pipe(
                map(walletActions.getBalance.success),
                catchError((message: string) => of(walletActions.getBalance.failure(message)))
            );

            const setWalletResult = from(Promise.resolve()).pipe(
                map(() => walletActions.setWallet.success(action.payload.address))
            );

            const token = Object.keys(balances).find((key) => key !== 'bnb');

            if (token) {
                const tokenBalanceResult = from(api.toeRouter.getTokenBalance(token)).pipe(
                    map((amount) => walletActions.getTokenBalance.success({ address: token, amount })),
                    catchError((message: string) => of(walletActions.getTokenBalance.failure(message)))
                );

                const tokenAllowanceResult = from(api.toeRouter.getAllowance(token)).pipe(
                    map((amount) => walletActions.getTokenAllowance.success({ address: token, amount })),
                    catchError((message: string) => of(walletActions.getTokenAllowance.failure(message)))
                );

                return merge(setWalletResult, bnbBalanceResult, tokenBalanceResult, tokenAllowanceResult);
            } else {
                return merge(setWalletResult, bnbBalanceResult);
            }
        })
    );

export const walletLoadedEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.setWallet.success)),
        switchMap(
            (action) => {
                const { connected, requested, requestedPairs} = state$.value.socket;
                const wantsToConnect = requested && !connected;
                if (wantsToConnect) {
                    return from(Promise.resolve()).pipe(
                        map(() => socketActions.connectSocket.request(requestedPairs))
                    )
                } else {
                    return from(Promise.resolve()).pipe(
                        map(walletActions.handleNoConnection)
                    );
                }
            }
        )
    );

export const buyEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.buy.request)),
        switchMap((action) =>
            from(api.toeRouter.buy(action.payload)).pipe(
                map(walletActions.buy.success),
                catchError((message: string) => of(walletActions.buy.failure(message)))
            )
        )
    );

export const sellEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.sell.request)),
        switchMap((action) =>
            from(api.toeRouter.sell(action.payload)).pipe(
                map(walletActions.sell.success),
                catchError((message: string) => of(walletActions.sell.failure(message)))
            )
        )
    );

export const getBalanceEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.getBalance.request)),
        switchMap((action) =>
            from(api.wallet.getBalance()).pipe(
                map(walletActions.getBalance.success),
                catchError((message: string) => of(walletActions.getBalance.failure(message)))
            )
        )
    );

export const getTokenAllowanceEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.getTokenAllowance.request)),
        switchMap(
            (action) => {
                const address = action.payload;
                return from(api.toeRouter.getAllowance(address)).pipe(
                    map((amount) => walletActions.getTokenAllowance.success({ address, amount })),
                    catchError((message: string) => of(walletActions.getTokenAllowance.failure(message)))
                )
            }
        )
    );

export const getTokenBalanceEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.getTokenBalance.request)),
        switchMap(
            (action) => {
                const address = action.payload;
                return from(api.toeRouter.getTokenBalance(address)).pipe(
                    map((amount) => walletActions.getTokenBalance.success({ address, amount })),
                    catchError((message: string) => of(walletActions.getTokenBalance.failure(message)))
                )
            }
        )
    );

export const approveEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.approve.request)),
        switchMap(
            (action) => {
                const address = action.payload;
                return from(api.toeRouter.approve(address)).pipe(
                    map(walletActions.approve.success),
                    catchError((message: string) => of(walletActions.approve.failure(message)))
                )
            }
        )
    );


export const switchNetworkEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.switchNetwork.request)),
        switchMap((action) =>
            from(api.wallet.switchNetwork()).pipe(
                map(walletActions.switchNetwork.success),
                catchError((message: string) => of(walletActions.switchNetwork.failure(message)))
            )
        )
    );

export const disconnectWalletEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.disconnectWallet.request)),
        switchMap((action) =>
            from(api.wallet.disconnectWallet()).pipe(
                map(walletActions.disconnectWallet.success),
                catchError((message: string) => of(walletActions.disconnectWallet.failure(message)))
            )
        )
    );


export const signOwnershipEpic: EpicWithTypes = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(walletActions.signOwnership.request)),
        switchMap((action) =>
            from(api.wallet.signOwnership(action.payload)).pipe(
                map(walletActions.signOwnership.success),
                catchError((message: string) => of(walletActions.signOwnership.failure(message)))
            )
        )
    );