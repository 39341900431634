import * as React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Rocket } from '../svg/rocket.svg';
import { ReactComponent as Zap } from '../svg/zap.svg';
import { ReactComponent as Fire } from '../svg/fire.svg';
import { ReactComponent as Warning } from '../svg/warning.svg';

import colorFromAddress from '../../utils/colors';

const SymbolContainer = styled.div`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.lightBlue};
    & span {
        font-weight: 400;
    }
    display: flex;
    align-items: center;
`;

const MoonButton = styled.div<{ colorHex: string }>`
   
    width: 2.1rem;
    height: 2.1rem;
    border-radius: 50%;
    background-color: ${({ colorHex }) => colorHex};
    margin-right: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.uiColor1};
    font-size: 1.125rem;
    text-align: center;
    font-weight: 500;
    line-height: 1.375rem;
    position: relative;
    & svg {
        height: 0.55rem;
        width: 0.55rem;
    }

`;

interface PillProps {
    enabled: boolean;
}

const WarningPill = styled.div<PillProps>`
   
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.triggerColor3};
    
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.uiColor5};
   
    text-align: center;
    
    line-height: 1.375rem;
    position: absolute;
    left: 0px;
    top: -3px;

`;

const ZapPill = styled.div<PillProps>`
   
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.triggerColor7};
    
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.uiColor5};
   
    text-align: center;
    
    line-height: 1.375rem;
    position: absolute;
    left: 0px;
    top: -3px;

`;

const RocketPill = styled.div<PillProps>`
   
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.triggerColor5};
    
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.uiColor5};
   
    text-align: center;
    
    line-height: 1.375rem;
    position: absolute;
    left: 0px;
    top: -3px;

`;

const FirePill = styled.div<PillProps>`
   
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.triggerColor1};
    
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.uiColor5};
   
    text-align: center;
    
    line-height: 1.375rem;
    position: absolute;
    left: 0px;
    top: -3px;

`;

const SymbolAndAddress = styled.div`
   
    & span {
        font-weight: 400;
    }
    display: flex;
    flex-direction: column;
`;

type Props = {
    symbol: string;
    tokenAddress: string;
};

export const Symbol: React.FunctionComponent<Props> = ({ symbol, tokenAddress }) => {

    const getTokenString = (token: string) => {
        const first = token.slice(0, 5);
        const second = token.slice(-3);

        return `${first}...${second}`;
    }

    return (
        <SymbolContainer>
            <MoonButton colorHex={colorFromAddress(tokenAddress)}>{symbol.trim().slice(0, 1)}
                <FirePill enabled={false}>
                    <Fire></Fire>
                </FirePill>
                <WarningPill enabled={false}>
                    <Warning></Warning>
                </WarningPill>
                <RocketPill enabled={false}>
                    <Rocket></Rocket>
                </RocketPill>
                <ZapPill enabled={false}>
                    <Zap></Zap>
                </ZapPill>
            </MoonButton>
            <SymbolAndAddress>
                {`${symbol.length > 10 ? symbol.slice(0, 10) + '...' : symbol}`}
                {<span>{getTokenString(tokenAddress)}</span>}
            </SymbolAndAddress>

        </SymbolContainer>
    )
}

export default Symbol;