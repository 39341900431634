import Web3 from 'web3';

import { getWeb3 } from "../utils/web3";
import ERC20 from '../constants/contracts/ERC20';

import { TokenInformation } from '../types/token';
import { fromWeiCustomDecimals } from '../utils/numbers';

import BN from 'bn.js';
import ApeSimulator from "../constants/contracts/ape-simulator-reloaded";

import { Simulation, SwapLimit, SimulationResponse } from '../types/simulations';

export const getTokenInfo = async (address: string): Promise<TokenInformation> => {
    const web3: Web3 = await getWeb3();
    const token = new web3.eth.Contract(ERC20.abi, address);

    try {
        const name = await token.methods.name().call();
        const decimals = await token.methods.decimals().call();
        return Promise.resolve({
            name,
            decimals: parseInt(decimals),
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

interface SimulateProps {
    address: string;
    decimals: number;
}

const getNumbers = (numbers: any): { [key: string]: string } => {
    return {
        fiatReserves: numbers[0],
        tokenReserves: numbers[1],
        minFailBuy: numbers[2],
        maxSuccessBuy: numbers[3],
        buyExpected: numbers[4],
        buyActual: numbers[5],
        buyTax: numbers[6],
        buyGasUsage: numbers[7],
        transferExpected: numbers[8],
        transferActual: numbers[9],
        transferTax: numbers[10],
        minFailApprove: numbers[11],
        maxSuccessApprove: numbers[12],
        approveGasUsage: numbers[13],
        minFailSell: numbers[14],
        maxSuccessSell: numbers[15],
        sellExpected: numbers[16],
        sellActual: numbers[17],
        sellTax: numbers[18],
        sellGasUsage: numbers[19],
    }
}

const getStatus = (status: boolean[]): { [key: string]: boolean } => {
    return {
        isWBNB: status[0],
        isBUSD: status[1],
        buyFail: status[2],
        buySuccess: status[3],
        transferFail: status[4],
        transferSuccess: status[5],
        approveFail: status[6],
        approveSuccess: status[7],
        sellFail: status[8],
        sellSuccess: status[9],
    }
}

export const simulate = async (props: SimulateProps): Promise<SimulationResponse> => {
    try {
        const web3 = await getWeb3();
        const simulator = new web3.eth.Contract(ApeSimulator.abi, ApeSimulator.address);
        const { numbers, status } = await simulator.methods.simulateSwaps(props.address).call({
            from: '0x8894e0a0c962cb723c1976a4421c95949be2d4e3',
            value: 10000000000000000000,
            gas: 210000000000000000
        });

        const {
            minFailBuy,
            maxSuccessBuy,
            buyExpected,
            buyActual,
            buyTax,
            buyGasUsage,
            transferExpected,
            transferActual,
            transferTax,
            maxSuccessApprove,
            minFailSell,
            maxSuccessSell,
            sellExpected,
            sellActual,
            sellTax,
            sellGasUsage,
        } = getNumbers(numbers);

        const {
            isWBNB,
            isBUSD,
            buyFail,
            buySuccess,
            transferSuccess,
            approveSuccess,
            sellFail,
            sellSuccess
        } = getStatus(status);

        if (!isWBNB && !isBUSD) {
            return Promise.reject({
                address: props.address,
                error: 'No pool found'
            });
        }

        const buy = {
            type: 'Buy',
            amount: fromWeiCustomDecimals(maxSuccessBuy, 18),
            symbol: 'BNB',
            expected: fromWeiCustomDecimals(buyExpected, props.decimals),
            actual: fromWeiCustomDecimals(buyActual, props.decimals),
            tax: Web3.utils.toBN(buyTax),
            gasUsed: Web3.utils.toBN(buyGasUsage),
            success: buySuccess,
        }

        const approve: Simulation = {
            type: 'Approve',
            amount: fromWeiCustomDecimals(maxSuccessApprove, props.decimals),
            symbol: 'tokens',
            expected: '',
            actual: '',
            tax: new BN(0),
            gasUsed: new BN(0),
            success: approveSuccess,
        }

        const transfer: Simulation = {
            type: 'Transfer',
            amount: fromWeiCustomDecimals(transferExpected, props.decimals),
            symbol: 'tokens',
            expected: fromWeiCustomDecimals(transferExpected, props.decimals),
            actual: fromWeiCustomDecimals(transferActual, props.decimals),
            tax: Web3.utils.toBN(transferTax),
            gasUsed: new BN(0),
            success: transferSuccess,
        }

        const sell: Simulation = {
            type: 'Sell',
            amount: fromWeiCustomDecimals(maxSuccessSell, props.decimals),
            symbol: 'tokens',
            expected: fromWeiCustomDecimals(sellExpected, 18),
            actual: fromWeiCustomDecimals(sellActual, 18),
            tax: Web3.utils.toBN(sellTax),
            gasUsed: Web3.utils.toBN(sellGasUsage),
            success: sellSuccess
        }

        const buyLimit: SwapLimit | undefined = !(buyFail && buySuccess)
            ? undefined
            : {
                type: 'Buy',
                hasLimit: buyFail && buySuccess,
                low: fromWeiCustomDecimals(maxSuccessBuy, 18),
                high: fromWeiCustomDecimals(minFailBuy, 18),
                fiatAmount: fromWeiCustomDecimals(maxSuccessBuy, 18),
            }

        const sellLimit: SwapLimit | undefined = !(sellFail && sellSuccess)
            ? undefined
            : {
                type: 'Sell',
                hasLimit: sellFail && sellSuccess,
                low: fromWeiCustomDecimals(maxSuccessSell, props.decimals),
                high: fromWeiCustomDecimals(minFailSell, props.decimals),
                fiatAmount: fromWeiCustomDecimals(sellActual, 18),
            }

        return Promise.resolve({
            address: props.address,
            result: {
                buy,
                transfer,
                approve,
                sell,
                buyLimit,
                sellLimit
            }
        });
    } catch (error) {
        console.error(`Analyzer error for ${props.address}: ${error}`);
        return Promise.reject({
            address: props.address,
            error: 'Execution reverted'
        })
    }
}