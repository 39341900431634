import BN from 'bn.js';
import { createReducer } from 'typesafe-actions';
import { initialState } from './state';
import * as walletActions from './actions';


/* const failedSimulation = {
    type: '',
    amount: '0',
    symbol: 'tokens',
    expected: '0',
    actual: '0',
    tax: new BN(0),
    gasUsed: new BN(0),
    success: false
}

const failedLimit = {
    type: '',
    hasLimit: false,
    low: '0',
    high: '0',
    fiatAmount: '0',
} */


export default createReducer(initialState)
    .handleAction(
        walletActions.setWallet.request,
        (state, action) => {
            const { address, provider } = action.payload;
            const zeroBalances = Object.keys(state.balances).reduce(
                (acc: { [key: string]: BN }, key) => {
                    acc[key] = new BN(0);
                    return acc;
                },
                {}
            );
            return {
                ...state,
                account: address,
                signedOwnership: undefined,
                provider,
                balances: !address ? zeroBalances : state.balances,
                walletLoaded: true,
            };
        }
    )
    .handleAction(
        walletActions.setNetworkType,
        (state, action) => {
            return {
                ...state,
                networkType: action.payload,
            };
        }
    )
    .handleAction(
        walletActions.getBalance.success,
        (state, action) => ({
            ...state,
            balances: {
                ...state.balances,
                bnb: action.payload,
            }
        })
    )
    .handleAction(
        walletActions.getTokenBalance.request,
        (state, action) => {
            return {
                ...state,
                balances: {
                    ...state.balances,
                    [action.payload]: new BN(0),
                }
            }
        }
    )
    .handleAction(
        walletActions.getTokenBalance.success,
        (state, action) => {
            const { address, amount } = action.payload;
            return {
                ...state,
                balances: {
                    ...state.balances,
                    [address]: amount,
                }
            }
        }
    )
    .handleAction(
        walletActions.getTokenBalance.failure,
        (state, action) => {
            const message = action.payload;
            console.debug(message);
            return state;
        }
    )
    .handleAction(
        walletActions.approve.request,
        (state, action) => ({
            ...state,
            approveLoading: true,
        })
    )
    .handleAction(
        walletActions.approve.success,
        (state, action) => {
            const { address, allowance, gasUsed, gasPrice } = action.payload;
            const gas = gasUsed.mul(gasPrice);
            return {
                ...state,
                approveLoading: false,
                allowances: {
                    ...state.allowances,
                    [address]: allowance,
                },
                balances: {
                    ...state.balances,
                    bnb: state.balances.bnb.sub(gas),
                }
            }
        }
    )
    .handleAction(
        walletActions.approve.failure,
        (state, action) => {
            const message = action.payload;
            console.debug(message);
            return {
                ...state,
                approveLoading: false,
            }
        }
    )
    .handleAction(
        walletActions.getTokenAllowance.request,
        (state, action) => {
            return {
                ...state,
                allowances: {
                    ...state.allowances,
                    [action.payload]: new BN(0),
                }
            }
        }
    )
    .handleAction(
        walletActions.getTokenAllowance.success,
        (state, action) => {
            const { address, amount } = action.payload;
            return {
                ...state,
                allowances: {
                    ...state.balances,
                    [address]: amount,
                }
            }
        }
    )
    .handleAction(
        walletActions.getTokenAllowance.failure,
        (state, action) => {
            const message = action.payload;
            console.debug(message);
            return state;
        }
    )
    .handleAction(
        walletActions.signOwnership.request,
        (state) => ({
            ...state,
            signingPending: true,
        })
    )
    .handleAction(
        walletActions.signOwnership.success,
        (state, action) => ({
            ...state,
            signedOwnership: action.payload,
            signingPending: false,
        })
    )
    .handleAction(
        walletActions.signOwnership.failure,
        (state) => ({
            ...state,
            signingPending: false,
        })
    ).handleAction(
        walletActions.buy.request,
        (state, action) => {
            return {
                ...state,
                buyLoading: true,
            }
        }
    )
    .handleAction(
        walletActions.buy.failure,
        (state, action) => {
            return {
                ...state,
                buyLoading: false,
                buyErrorMessage: action.payload,
            }
        }
    )
    .handleAction(
        walletActions.buy.success,
        (state, action) => {
            const { bnbIn, bnbOut, tokenIn, tokenOut, gasUsed, gasPrice, tokenAddress, } = action.payload;
            const gas = gasUsed.mul(gasPrice);
            try {
                return {
                    ...state,
                    balances: {
                        bnb: state.balances.bnb.add(bnbIn).sub(bnbOut).sub(gas),
                        [tokenAddress]: state.balances[tokenAddress].add(tokenIn).sub(tokenOut)
                    },
                    buyLoading: false,
                }
            } catch (error) {
                console.debug(`buySuccess error`);
                console.debug(error);
                return {
                    ...state,
                    buyLoading: false,
                };
            }
        }
    ).handleAction(
        walletActions.sell.request,
        (state, action) => {
            return {
                ...state,
                sellLoading: true,
            }
        }
    )
    .handleAction(
        walletActions.sell.failure,
        (state, action) => {
            return {
                ...state,
                sellLoading: false,
                sellErrorMessage: action.payload,
            }
        }
    )
    .handleAction(
        walletActions.sell.success,
        (state, action) => {
            const { bnbIn, bnbOut, tokenIn, tokenOut, gasUsed, gasPrice, tokenAddress } = action.payload;
            const gas = gasUsed.mul(gasPrice);
            try {
                return {
                    ...state,
                    balances: {
                        bnb: state.balances.bnb.add(bnbIn).sub(bnbOut).sub(gas),
                        [tokenAddress]: state.balances[tokenAddress].add(tokenIn).sub(tokenOut)
                    },
                    sellLoading: false,
                }
            } catch (error) {
                console.debug(`sellSuccess error`);
                console.debug(error);
                return {
                    ...state,
                    sellLoading: false,
                };
            }
        }
    )
    .handleAction(
        walletActions.clearBuyError,
        (state, action) => {
            return {
                ...state,
                buyLoading: false,
                buyErrorMessage: undefined,
            }
        }
    ).handleAction(
        walletActions.clearSellError,
        (state, action) => {
            return {
                ...state,
                approveLoading: false,
                sellLoading: false,
                sellErrorMessage: undefined,
            }
        }
    ).handleAction(
        walletActions.connectWallet.failure,
        (state, action) => {
            console.error(`connectWallet failure`, action.payload);
            return {
                ...state
            }
        }
    );