import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { setFilter } from '../store/interface/actions';

const SearchContainer = styled.div<{ enabled: boolean }>`
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1.1rem;
    margin-right: 0.625rem;
    ${({ theme }) => theme.media.smallUp} {
        padding: 0 1rem;
        display: none;
    }
`;

const SearchInput = styled.input.attrs({ type: 'text' })`
${({ theme }) => theme.media.extrasmall} {
    padding: 0.65rem 0rem 0.45rem 3rem;

}
    padding: 0.7rem 3rem;
    font-size: 16px;
    width: 100%;
   
    color: ${({ theme }) => theme.colors.uiColor5};
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    box-sizing: border-box;
    border-radius: 0.5rem;
    background-color: transparent;
    background-image: url(/images/search.svg);
    background-repeat: no-repeat;
    background-position: 1rem 0.75rem;
    font-family: ${({ theme }) => theme.fonts.main};

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme }) => theme.colors.uiColor4};
        opacity: 1; /* Firefox */
        font-size: 1rem;
        font-family: ${({ theme }) => theme.fonts.main}
      }
      &:focus { outline: none;
        border: 1px solid ${({ theme }) => theme.colors.uiColor5}; }
`;

interface Props {
    enabled: boolean;
}


export const SearchBar: React.FunctionComponent<Props> = ({ enabled }) => {
    const dispatch = useDispatch();
    const inputContainer = useRef(null);

    useEffect(() => {
        if (inputContainer) {
            //@ts-ignore
            inputContainer.current.value = "";
            const query = "";
            dispatch(setFilter({ filter: 'searchParameter', value: query }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.currentTarget.value;
        dispatch(setFilter({ filter: 'searchParameter', value: query }));
    }

    return (
        <SearchContainer enabled={enabled}>
            <SearchInput
                onChange={handleChange}
                placeholder='Filter by name or symbol'
                ref={inputContainer}
            />
        </SearchContainer>
    )
}

export default SearchBar;