import BN from 'bn.js';

import { SignOwnershipResult } from "../../services/wallet";

export interface WalletState {
    account: string;
    networkType: string;
    allowances: {
        [key: string]: BN;
    }
    balances: {
        [key: string]: BN;
    }
    signingPending: boolean;
    signedOwnership?: SignOwnershipResult;
    approveLoading: boolean;
    buyLoading: boolean;
    sellLoading: boolean;
    buyErrorMessage?: string;
    sellErrorMessage?: string;
    provider: string;
    walletLoaded: boolean;
}

export const initialState: WalletState = {
    account: '',
    networkType: '',
    signingPending: false,
    allowances: {},
    balances: {},
    approveLoading: false,
    buyLoading: false,
    sellLoading: false,
    provider: '',
    walletLoaded: false,
}