// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA4gsqwKY8L9WyiFm6kMyBh9MB-ra1Ty7w",
  authDomain: "tokenexpressio.firebaseapp.com",
  projectId: "tokenexpressio",
  storageBucket: "tokenexpressio.appspot.com",
  messagingSenderId: "329285089227",
  appId: "1:329285089227:web:224c446a7aa080b5d9284e",
  databaseURL: "https://tokenexpressio-default-rtdb.europe-west1.firebasedatabase.app"
};

// Initialize Firebase
const initializeFirebase =  () => initializeApp(firebaseConfig);
export default initializeFirebase;