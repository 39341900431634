import Web3 from 'web3';
import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { RootState } from 'typesafe-actions';
import Layout from '../components/layout/layout';
import PancakeLoader from '../components/animations/pancake_loader_animation.json';
import lottie from 'lottie-web/build/player/lottie_light';
import PairHeader from '../components/pair/header';
import PairInfo from '../components/pair/info';
import Chart from '../components/pair/chart';
import YourTransactions from '../components/pair/your-transactions';
import Swaps from '../components/pair/swaps';
import TradeSimulations from '../components/pair/trade-simulations';
import Swap from '../components/pair/swap/swap';

import * as interfaceActions from '../store/interface/actions';
import * as walletActions from '../store/wallet/actions';
import { PairInterface } from '../types/pairs';

const FrontPageContainer = styled.div`
    ${({ theme }) => theme.layout.desktopWidth}
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 1rem 0rem;

    ${({ theme }) => theme.media.small} {
        padding: 1rem;
        max-width: 100vw;

    }
`;

const LoaderContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
        margin-top: 30vh;
    }

    & h1 {
        font-weight: 500;
    }
`;

const PancakeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12.5rem;
    width: 12.5rem;
    margin-top: 10rem;
`;

const PairContainer = styled.div`
    ${({ theme }) => theme.layout.desktopWidth}
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 5rem;
    padding-right: 5rem;
    ${({ theme }) => theme.media.small} { 
        padding-left: 0rem;
        padding-right: 0rem;
    } 
`;

const MainContainer = styled.div`
    width: 100%;
    display: grid;
    column-gap: 2rem;
    grid-template-columns: 55fr 40fr;
    grid-template-areas:
        "Chart Swap"
        "Chart Swap";

    ${({ theme }) => theme.media.small} {
        grid-template-columns: 1fr;
        grid-template-areas:
            "Chart"
            "Swap";
        row-gap: 2rem;
    }
`;

const ChartContainer = styled.div`
    grid-area: Chart;
    display: flex;
    flex-direction: column;
`;

const SwapContainer = styled.div`
    grid-area: Swap;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const BottomContainer = styled.div`
    width: 100%;
    display: grid;
    column-gap: 2rem;
    grid-template-columns: auto 30rem auto;
    margin: 2rem 0;

    ${({ theme }) => theme.media.small} {
        grid-template-columns: 1fr;
        padding: 0 2rem;
    }
`;

const dispatchProps = {
    openPage: interfaceActions.openPage.request,
    switchTab: interfaceActions.switchTab,
    closePage: interfaceActions.closePage.request,
    getTokenAllowance: walletActions.getTokenAllowance.request,
    getTokenBalance: walletActions.getTokenBalance.request,
    switchPairComplete: interfaceActions.switchPairComplete,
};

type OwnProps = RouteComponentProps

type Props = {

} & OwnProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

interface State {
    trade: boolean;
}

class PairPage extends React.Component<Props, State> {
    private myRef = React.createRef<HTMLDivElement>();
    private anim = React.createRef<HTMLDivElement>();
    constructor(props: Props) {
        super(props);

        this.state = {
            trade: false,
        }
    }

    getPrevPath = (): string | undefined => {
        try {
            const prevPath = (this.props.location.state as any).prevPath as string;
            return prevPath;
        } catch (error) {
            return undefined;
        }
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        const pathname = this.props.location.pathname;
        const isOpen = !!this.props.openPages.find((page) => page.pathname === pathname);
        if (!isOpen) {
            this.props.openPage(pathname);
        }
        if (this.myRef.current) {
            //@ts-ignore
            this.anim.current = lottie.loadAnimation({
                container: this.myRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: PancakeLoader,
            });
        }
        window.addEventListener('keydown', this.handleEscape, false)
    }

    handleEscape = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            const pathname = this.props.location.pathname;
            this.props.closePage(pathname);
        } else if (e.key === 'Tab') {
            e.preventDefault();
            if (e.shiftKey) {
                this.props.switchTab(-1);
            } else {
                this.props.switchTab(1);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleEscape, false);
    }

    scrollTrade = () => {
        //@ts-ignore
        document.querySelector('#swap').scrollIntoView({ behavior: 'smooth' });
        this.setState({ trade: true });
        setTimeout(() => {
            this.setState({ trade: false });
        }, 3000)
    }

    getErrorMessage = (error: string): string => {
        switch (error) {
            case 'invalidAddress':
                return 'Pair not found';
            case 'pairNotFound':
                return 'Pair not found';
            default:
                return 'Pair not found';
        }
    }

    render() {
        const { pair, error } = this.props;
        return (
            <Layout>
                <FrontPageContainer>
                    {
                        error ? (
                            <LoaderContainer><h1>{this.getErrorMessage(error)}</h1></LoaderContainer>
                        ) : !pair ? (
                            <PancakeContainer ref={this.myRef}></PancakeContainer>
                        ) : (
                            <PairContainer>
                                <PairHeader
                                    address={pair.address}
                                    token={pair.token}
                                    baseToken={pair.baseToken}
                                    indicators={pair.indicators}
                                    onTrade={this.scrollTrade}
                                    locks={pair.data.locks}
                                />
                                <PairInfo
                                    address={pair.address}
                                    baseToken={pair.baseToken}
                                    initialReserves={pair.initialReserves}
                                    currentReserves={pair.currentReserves}
                                    indicators={pair.indicators}
                                    timestamp={pair.timestamp}
                                    isToken0={pair.isToken0}
                                />

                                <MainContainer>
                                    <ChartContainer>
                                        <Chart
                                            priceChange={pair.indicators.priceChange}
                                            priceChanges={pair.data.priceChanges}
                                            isRug={pair.indicators.isRug}
                                            toeTransactions={pair.data.toeTransactions || []}
                                            timestamp={pair.timestamp}
                                        />
                                        <YourTransactions transactions={pair.data.toeTransactions || []} />
                                        <Swaps
                                            baseToken={pair.baseToken}
                                            isToken0={pair.isToken0}
                                            swaps={pair.data.swaps}
                                            buys={pair.indicators.buys}
                                            sells={pair.indicators.sells}
                                        />
                                    </ChartContainer>
                                    <SwapContainer>
                                        <TradeSimulations
                                            address={pair.address}
                                            token={pair.token}
                                            isRug={pair.indicators.isRug}
                                            contractAnalysis={pair.indicators.contractAnalysis}
                                        />
                                        <Swap
                                            address={pair.address}
                                            isToken0={pair.isToken0}
                                            token={pair.token}
                                            baseToken={pair.baseToken}
                                            currentReserves={pair.currentReserves}
                                            trigger={this.state.trade}
                                        />
                                    </SwapContainer>
                                </MainContainer>
                                <BottomContainer>

                                </BottomContainer>
                            </PairContainer>
                        )
                    }
                </FrontPageContainer>
            </Layout >
        );
    }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
    const { dashboardPairs, trendingPairs, customPairs } = state.pairs;
    const allPairs = dashboardPairs.concat(trendingPairs).concat(customPairs);

    let pair: PairInterface | undefined = undefined;
    try {
        const { address } = ownProps.match.params as { address: string };
        const pairAddress = Web3.utils.toChecksumAddress(address);
        pair = allPairs.find(({ address }) => address === pairAddress);
    } catch (error) {

    }
    const pathname = ownProps.location.pathname;
    const openPage = state.interface.openPages.find((page) => page.pathname === pathname);

    /* 
        const { dashboardPairs, trendingPairs, customPairs } = state.pairs;
        const allPairs = dashboardPairs.concat(trendingPairs).concat(customPairs);
        const pathname = ownProps.location.pathname;
        const openPage = state.interface.openPages.find((page) => page.pathname === pathname);
        const pair = !!openPage ? allPairs.find(({ address }) => address === openPage.address) : undefined;
    */
    return {
        pair,
        openPages: state.interface.openPages,
        error: openPage?.error,
    }
};

export default withRouter(
    connect(
        mapStateToProps,
        dispatchProps
    )(PairPage)
);