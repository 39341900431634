import { createAction, createAsyncAction } from 'typesafe-actions';
import { BlockTimestamp } from '../../types/web3';
import { DashboardSorters, PageTab } from './state';

export const getBNBPrice = createAsyncAction(
    'GET_BNB_PRICE_REQUEST',
    'GET_BNB_PRICE_SUCCESS',
    'GET_BNB_PRICE_FAILED'
)<undefined, number, string>();

export const getNewestBlock = createAsyncAction(
    'GET_NEWEST_BLOCK_REQUEST',
    'GET_NEWEST_BLOCK_SUCCESS',
    'GET_NEWEST_BLOCK_FAILED'
)<undefined, { blockTimestamp: BlockTimestamp, difference: number }, string>();

export const subscribeToDashboard = createAsyncAction(
    'SUBSCRIBE_DASHBOARD_REQUEST',
    'SUBSCRIBE_DASHBOARD_SUCCESS',
    'SUBSCRIBE_DASHBOARD_FAILED'
)<string[], undefined, string>();

export const openPairPage = createAsyncAction(
    'OPEN_PAIR_PAGE_REQUEST',
    'OPEN_PAIR_PAGE_SUCCESS',
    'OPEN_PAIR_PAGE_FAILED'
)<string, string, string>();

export const closePairPage = createAsyncAction(
    'CLOSE_PAIR_PAGE_REQUEST',
    'CLOSE_PAIR_PAGE_SUCCESS',
    'CLOSE_PAIR_PAGE_FAILED'
)<string, string, string>();

export const closePage = createAsyncAction(
    'CLOSE_PAGE_REQUEST',
    'CLOSE_PAGE_SUCCESS',
    'CLOSE_PAGE_FAILED'
)<string, string, string>();

export const openPage = createAsyncAction(
    'OPEN_PAGE_REQUEST',
    'OPEN_PAGE_SUCCESS',
    'OPEN_PAGE_FAILED'
)<string, PageTab, PageTab>();

export const switchPair = createAsyncAction(
    'SWITCH_PAIR_REQUEST',
    'SWITCH_PAIR_SUCCESS',
    'SWITCH_PAIR_FAILED'
)<{ from: string; to: string; }, { from: string; page: PageTab; }, string>();

export const updatePage = createAction('UPDATE_PAGE')<Partial<PageTab>>();

export const switchTab = createAction('SWITCH_TAB')<number>();

export const setFilter = createAction('SET_FILTER')<{ filter: string; value: boolean | string; }>();

export const setSorting = createAction('SET_SORTING')<DashboardSorters>();

export const setHidden = createAction('SET_HIDDEN')<string>();

export const switchPairComplete = createAction('SWITCH_PAIR_COMPLETE')<{ from: string; to: string; }>();

export const setToken = createAction('SET_TOKEN')<string | undefined>();

export const setError = createAction('SET_ERROR')<string | undefined>();