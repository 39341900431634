import React from 'react';
import styled from 'styled-components/macro';

const AmountButtons = styled.div`
    width: 100%;
    margin-top: 1.2rem;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 0rem 0.5rem; 
    font-weight: 500;
    font-size: 0.875rem;
`

const AmountButton = styled.div<{ enabled?: boolean; special?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ enabled, theme, special }) => enabled ? special ? `2px solid ${theme.colors.brandColor1}` : 'none' : `1px solid ${theme.colors.uiColor2}`};
    border-radius: 0.5rem;
    height: 2.8rem;
    cursor: pointer;
    background-color: ${({ enabled, special, theme }) => (enabled && !special) ? theme.colors.brandColor1 : 'none'};
    color: ${({ enabled, special, theme }) => (enabled && !special) ? theme.colors.uiColor1 : theme.colors.uiColor5};

    &:hover {
        border: ${({ enabled, theme, special }) => (enabled && !special) ? 'none' : `${special ? '2px' : '1px'} solid ${theme.colors.uiColor5}`};
    }
`

const TextInput = styled.input.attrs({
    type: 'text',
})` 
    text-align: center;
    font-weight: 500;
    font-size: 0.875rem;
    outline: none;
    border: none;
    width: 100%;
    background: transparent;
    width: 70%;
    font-family: ${({ theme }) => theme.fonts.main};
    color: ${({ theme }) => theme.colors.offWhite};
    //transition: all 0.3s ${({ theme }) => theme.transitions.main};
`;

interface Props {
    onChange: (slippage: string) => void;
    slippage: string;
}

interface State {
    level: string;
    value: string;
}

export default class SlippageInput extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            level: 'a',
            value: '',
        }
    }

    triggerLevel = (level: string) => {
        this.setState({ value: level === 'd' ? this.state.value : '', level });
        switch (level) {
            case 'a':
                this.props.onChange('auto');
                break;
            case 'b':
                this.props.onChange('1');
                break;
            case 'c':
                this.props.onChange('2');
                break;
        }
    }

    onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const legit = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        let amount = e.currentTarget.value;
        const chars = amount.split('');

        if (!chars.reduce((isLegit: boolean, char: string) => !legit.includes(char) ? false : isLegit, true)) {
            // Only allow legit characters
            return;
        } else if (chars[chars.length - 1] === '.' && (chars[chars.length - 2] === '.' || chars.filter(c => c === '.').length > 1)) {
            // Only allow one decimal point
            return;
        } else if (parseFloat(amount) > 100) {
            amount = '100';
        }
        this.setState({
            value: amount,
        });
        this.props.onChange(amount);
    }

    render() {
        return (
            <AmountButtons>
                <AmountButton
                    onClick={() => this.triggerLevel('a')}
                    enabled={this.props.slippage === 'auto'}>
                    AUTO
                </AmountButton>
                <AmountButton
                    onClick={() => this.triggerLevel('b')}
                    enabled={this.props.slippage === '1' && this.state.level === 'b'}>
                    1 %
                </AmountButton>
                <AmountButton
                    onClick={() => this.triggerLevel('c')}
                    enabled={this.props.slippage === '2' && this.state.level === 'c'}>
                    2 %
                </AmountButton>
                <AmountButton
                    onClick={() => this.triggerLevel('d')}
                    enabled={this.state.level === 'd'}
                    special
                >
                    <TextInput
                        onChange={this.onAmountChange}
                        placeholder={'0.0'}
                        value={this.state.value}
                    />%
                </AmountButton>
            </AmountButtons>
        )
    }
}