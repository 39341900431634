import { BlockTimestamp } from '../../types/web3';

export interface DashboardFilters {
    showRugpulls: boolean;
    showUnverified: boolean;
    showSmallLiquidity: boolean;
    showHidden: boolean;
    searchParameter: string;
};

export type SortableColumns = 'time' | 'symbol' | 'liquidity' | 'swaps' | 'holders' | 'priceChange';

export interface DashboardSorters {
    desc: boolean;
    column: SortableColumns;
};

export interface PageTab {
    pathname: string;
    address?: string;
    tokenAddress?: string;
    symbol?: string;
    error?: string;
    notification?: string;
}

export interface InterfaceState {
    BNBPrice?: number;
    selectedToken?: string;
    newestBlock?: BlockTimestamp;
    difference?: number;
    error?: string;
    openPages: PageTab[];
    filters: DashboardFilters;
    sorters: DashboardSorters;
    hiddenPairs: string[];
}

export const initialState: InterfaceState = {
    openPages: [],
    sorters: {
        desc: true,
        column: 'time',
    },
    filters: {
        showRugpulls: true,
        showUnverified: true,
        showSmallLiquidity: true,
        showHidden: false,
        searchParameter: '',
    },
    hiddenPairs: [],
}