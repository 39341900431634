import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { RootState } from 'typesafe-actions';
import Web3 from 'web3';
import Layout from '../components/layout/layout';
import AnalyzeGrid from '../components/analyze/analyze-grid';

import { resetAnalyzer, getTokenInfo, setAddress, analyzeToken } from '../store/analyze/actions';

const AnalyzePageContainer = styled.div`
    ${({ theme }) => theme.layout.desktopWidth}

    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1rem 5rem;
    ${({ theme }) => theme.media.small} {
        padding: 0 1rem;
    }
`;

const dispatchProps = {
    setAddress: setAddress,
    getTokenInfo: getTokenInfo.request,
    analyzeToken: analyzeToken.request,
    reset: resetAnalyzer,
};

type Props = {

} & ReturnType<typeof mapStateToProps> & typeof dispatchProps & RouteComponentProps;

interface State {
    address: string;
    simulations: any;
}

class AnalyzePage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            address: '',
            simulations: [],
        }
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        try {
            const { address } = this.props.match.params as { address: string };
            const checkSumAddress = Web3.utils.toChecksumAddress(address);
            if (checkSumAddress) {
                this.props.setAddress(checkSumAddress);
            }
        } catch (error) {

        }
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.address !== this.props.address) {
            if (this.props.address) {
                this.props.reset(this.props.address);
                this.props.analyzeToken(this.props.address);
            }
        }
    }

    simulate = async () => {

    }

    render() {

        return (
            <Layout>
                <AnalyzePageContainer>
                    <AnalyzeGrid />
                </AnalyzePageContainer>
            </Layout>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    address: state.analyze.address,
    loading: state.analyze.simulations.loading,
});

export default connect(
    mapStateToProps,
    dispatchProps
)(withRouter(AnalyzePage));