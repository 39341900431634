import { createAction, createAsyncAction } from 'typesafe-actions';

import { TokenInformation } from '../../types/token';
import { SimulationResponse } from '../../types/simulations';

export const setAddress = createAction('SET_ADDRESS')<string>();

export const analyzeToken = createAsyncAction(
    'ANALYZE_REQUEST',
    'ANALYZE_SUCCESS',
    'ANALYZE_FAILED'
)<string, undefined, string>();

export const getTokenInfo = createAsyncAction(
    'GET_TOKEN_INFO_REQUEST',
    'GET_TOKEN_INFO_SUCCESS',
    'GET_TOKEN_INFO_FAILED'
)<string, TokenInformation, string>();

export const resetAnalyzer = createAction('RESET_ANALYZER')<string>();

export const simulate = createAsyncAction(
    'ANALYZER_SIMULATE_REQUEST',
    'ANALYZER_SIMULATE_SUCCESS',
    'ANALYZER_SIMULATE_FAILED'
)<{ address: string; decimals: number }, SimulationResponse, { address: string; error: string; }>();