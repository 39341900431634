import * as analyzer from './analyze';
import * as wallet from './wallet';
import * as web3 from './web3';
import toeRouter from './toe-router';

const api = {
    analyzer,
    wallet,
    web3,
    toeRouter,
};

export default api;