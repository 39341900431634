import React from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Copy } from '../svg/copy.svg';

const AddressContainer = styled.span<{ copied: boolean }>`
    position: relative;
    font-weight: 400;
    font-size: 1rem;

    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }

    transition: all 0.1s ${({ theme }) => theme.transitions.main};

    & >span {
        border-radius: 0.25rem;
       
        display: block;
        z-index: 1;
        left: 0px;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.uiColor2};
        left: 110%;
        top: 0%;
        transition: all 0.1s ${({ theme }) => theme.transitions.main};
        text-decoration: none;
        white-space: nowrap;
        pointer-events: none;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 0.625rem;
        padding: 0.375rem 0.5rem;
        &:after {
            content: 'Link Copied';
        }
        opacity: ${({ copied }) => copied ? 1 : 0};
    }

    & svg {
        margin-left: 0.7rem;
        color: ${({ theme, copied }) => copied ? theme.colors.uiColor5 : theme.colors.uiColor3};
        height: 0.875rem;
        width: 0.75rem;

    }


    &:hover {
        & >span {
            
            transition-delay: 0s;
        }
    }
`;

const WrapperContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    ${({ theme }) => theme.media.small} {
        margin-top: 0.3rem;
    } 
`;

interface Props {
    address: string;
}

interface State {
    copied: boolean;
}

export default class Address extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            copied: false,
        }
    }

    getTokenString = (token: string) => {
        const first = token.slice(0, 6);
        const second = token.slice(-3);

        return `${first}...${second}`;
    }

    copyAddress = () => {
        navigator.clipboard.writeText(this.props.address);
        this.setState({ copied: true }, () => setTimeout(() => this.setState({ copied: false }), 1500))
    }

    render() {
        return (
            <WrapperContainer>
                <AddressContainer
                    copied={this.state.copied}
                    onClick={this.copyAddress}
                >
                    {this.getTokenString(this.props.address)}
                    <span></span>
                    <Copy />
                </AddressContainer>
            </WrapperContainer>
        )
    }
}