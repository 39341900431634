import React from 'react';
import styled from 'styled-components/macro';
import Web3 from 'web3';
import FlipMove from 'react-flip-move';

import { TOETransaction } from '../../types/pairs';

const SwapsContainer = styled.div`
    grid-area: Swaps;
    width: 100%;
    max-height: 50rem;
    min-width: 10rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 2rem;
    margin-bottom: 1rem;
`;

const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.uiColor2};
    margin-bottom: 0.8rem;
`;

const NoTransactions = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.uiColor6};
    border-radius: 0.5rem;
    margin-top: 1rem;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 1.5rem;
`;

const TimePassed = styled.div<{ even: boolean; plus: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    border-radius: 0.25rem;
    width: 5.375rem;
    padding: 6px 8px;
    margin-top: 0.2rem;
    background-color: ${({ theme }) => theme.colors.uiColor1};
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    color: ${({ theme, even, plus }) => theme.colors[even ? 'uiColor5' : plus ? 'triggerColor5' : 'triggerColor1']};

`;

const LeftSwapContainer = styled.div`
    display: grid;
    grid-template-columns: 5.5rem auto;
    align-items: center;
    column-gap: 1rem;
    & span {
        color: white !important;
        line-height: 1.3rem;
        font-family: ${({ theme }) => theme.fonts.numbers};
    }
`;

const BuySellContainer = styled.div<{ up: boolean; even: boolean; plus: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, even, up, plus }) => theme.colors[even ? 'brandColor1' : up ? 'uiColor2' : plus ? 'triggerColor5' : 'triggerColor1']};
    padding: 0.35rem 0.6rem;
    color: ${({ theme, up }) => up ? theme.colors.uiColor5 : theme.colors.uiColor1};
    border-radius: 4px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.875rem;
`;

interface Props {
    transactions: TOETransaction[];
}

interface State {
    changed: boolean;
}

export default class YourTransactions extends React.Component<Props, State> {
    private nodeRef: React.RefObject<HTMLDivElement>;
    constructor(props: Props) {
        super(props);

        this.state = {
            changed: false,
        }

        this.nodeRef = React.createRef();
    }

    componentDidMount = () => {

    }

    render() {
        const { transactions } = this.props;
        //const profit = -0.2;
        //const plus = profit > 0.0;
        //const even = !(profit < 0.0 || profit > 0.0)

        return (
            <SwapsContainer>
                <TitleContainer>
                    <Title>Your transactions</Title>
                    <TimePassed plus={false} even={true}>
                        <span>{transactions.length} TOTAL</span>
                        {/* <span>{plus && '+'}{profit} BNB</span> */}
                    </TimePassed>
                </TitleContainer>
                <FlipMove
                    staggerDurationBy="20"
                    duration={250}
                    enterAnimation="fade" leaveAnimation="fade"
                >
                    {
                        transactions.map((transaction, i) =>
                            <div key={transaction.transactionHash}>
                                <TransactionRow {...transaction} />
                            </div>
                        )
                    }
                    {
                        transactions.length === 0 && (
                            <NoTransactions>
                                You have not made any transactions
                            </NoTransactions>
                        )
                    }
                </FlipMove>
            </SwapsContainer>
        )
    }
}

const SwapRowContainer = styled.a<{ isBuy: boolean, isApprove: boolean }>`
    display: flex;
    font-size: 1rem;
    border-radius: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding: 1rem;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    & span:nth-child(1) {
        margin-right: 2rem;
    }
    & span {
        width: 100%;
        padding: 0.1 1rem;
        font-family: ${({ theme }) => theme.fonts.numbers};
        
    }

    &:hover {
            background-color: ${({ theme }) => theme.colors.uiColor6};
    }
    & span:nth-child(2) {
        color: ${({ theme, isBuy }) => theme.colors[!isBuy ? 'triggerColor5' : 'uiColor5']};
        text-align: right;
    }
`;

const TransactionRow: React.FunctionComponent<TOETransaction> = (transaction) => {
    const baseIn = Web3.utils.toBN(transaction.baseIn);
    const baseOut = Web3.utils.toBN(transaction.baseOut);
    const isApprove = !!transaction.tokenApproved;
    const isBuy = !isApprove && baseOut.gt(baseIn);
    const rawAmount = isBuy ? baseOut : baseIn;
    const amount = Web3.utils.fromWei(rawAmount.abs().toString());

    const profit = 0.1;
    const plus = profit > 0.0;

    const getParsedAmount = () => {
        try {
            const parsed = (amount.match(/^-?\d+(?:\.\d{0,3})?/) as RegExpExecArray)[0];
            return parseFloat(parsed).toFixed(3);
        } catch (error) {
            return parseFloat(amount).toFixed(3);
        }
    }

    const getSwapTime = (timestamp: number): string => {
        const date = new Date(timestamp * 1000);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const hoursString = hours === 0 ? '00' : hours < 10 ? `0${hours}` : hours;
        const minutesString = minutes === 0 ? '00' : minutes < 10 ? `0${minutes}` : minutes;
        const secondsString = seconds === 0 ? '00' : seconds < 10 ? `0${seconds}` : seconds;
        return `${hoursString}:${minutesString}:${secondsString}`;
    }

    return (
        <SwapRowContainer
            href={`https://bscscan.com/tx/${transaction.transactionHash}`}
            target={'_blank'}
            rel={'noreferrer'}
            isApprove={isApprove}
            isBuy={isBuy}
        >
            <LeftSwapContainer>
                <BuySellContainer even={isApprove} up={isBuy} plus={plus}>{isApprove ? 'Approve' : isBuy ? 'Buy' : 'Sell'}</BuySellContainer>
                <span>{getSwapTime(transaction.timestamp)}</span>
            </LeftSwapContainer>
            {
                !isApprove ? (
                    <span>{isBuy ? '-' : '+'}{getParsedAmount()} BNB</span>
                ) : (
                    <span></span>
                )
            }
        </SwapRowContainer>
    )
}