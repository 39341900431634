import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Link, useLocation } from 'react-router-dom';
import * as interfaceActions from '../../store/interface/actions';
import { ReactComponent as Dashboard } from '../svg/dashboard.svg';
import { ReactComponent as Cancel } from '../svg/cancel.svg';
import FlipMove from 'react-flip-move';
import { RootState } from 'typesafe-actions';
import { PageTab } from '../../store/interface/state';

const TabsContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    max-width: 100%;
    overflow-x: auto;
   
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const Tab = styled.div<{ active?: boolean, notification: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.375rem 0.5rem;
    height: 1.625rem;
    border-radius: 0.25rem;

    border: 1px solid ${({ theme, active }) => active ? theme.colors.brandColor1 : theme.colors.uiColor2};
    background-color: ${({ theme, active }) => active ? theme.colors.brandColor1 : theme.colors.uiColor1};
    color: ${({ theme, active }) => active ? theme.colors.uiColor1 : theme.colors.uiColor4};
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 0.875rem;
    margin-right: 0.5rem;
    cursor: pointer;
    &:hover{
        border: 1px solid ${({ active, theme }) => active ? theme.colors.brandColor1 : theme.colors.uiColor6};
        background-color: ${({ active, theme, }) => active ? theme.colors.brandColor1 : theme.colors.uiColor6};
        color: ${({ active, theme }) => active ? theme.colors.uiColor1 : theme.colors.brandColor1};
    } 

    & span {
        margin-right: 0.3rem;
        max-width: 5rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    & svg {
        width: 0.75rem;
        height: 0.75rem;
        color: ${({ theme, active }) => active ? theme.colors.uiColor1 : theme.colors.uiColor4};
        &:hover {
         color: ${({ theme, active }) => !active ? theme.colors.uiColor5 : 'inherit'};
        }
    }

    ${({ theme, notification }) => notification && `
        background-color: ${theme.colors.brandColor1};
        color: ${theme.colors.uiColor1};
        box-shadow: ${theme.colors.shadow1};
        & svg {
            color: ${theme.colors.uiColor1};
        }
    `}
`;

type Props = {
    className?: string;
}

export const Tabs: React.FC<Props> = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const openPages = useSelector<RootState, PageTab[]>(
        (state) => state.interface.openPages
    );

    const closePage = (page: PageTab) => dispatch(interfaceActions.closePage.request(page.pathname));

    const renderTabs = () => {
        const sorted = openPages.map(p => p).sort((a, b) => a.pathname === '/' ? -1 : 1);
        return sorted.map(
            (page) => {
                const handleClose = (e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    closePage(page);
                }
                const symbol = !!page.error ? 'Error' : !page.symbol ? '...' : page.symbol;
                const notification = !!page.notification;
                return page.pathname === '/' ? (
                    <Link key='/' to={'/'}>
                        <Tab notification={false} active={location.pathname === '/'}>
                            <span>Dashboard</span>
                            <Dashboard />
                        </Tab>
                    </Link>
                ) : (
                    <Link key={page.pathname} to={page.pathname}>
                        <Tab notification={notification} active={location.pathname.includes(page.pathname)}>
                            <span>{symbol}</span>
                            <Cancel onClick={handleClose} />
                        </Tab>
                    </Link>
                )
            }
        )
    }

    return (
        <TabsContainer>
            <FlipMove
                maintainContainerHeight={true}
                staggerDurationBy="20"
                duration={200}
                enterAnimation="accordionHorizontal"
                leaveAnimation="accordionHorizontal"
                typeName={null}
            >
                {renderTabs()}
            </FlipMove>
        </TabsContainer>
    )
}

export default Tabs;