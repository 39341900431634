import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import * as walletActions from '../../store/wallet/actions';
import * as interfaceActions from '../../store/interface/actions';


import { ReactComponent as Close } from '../svg/close.svg';
import { ReactComponent as Fox } from '../svg/fox.svg';
import { ReactComponent as WalletLogo } from '../svg/walletlogo.svg';
import { ReactComponent as Copy } from '../svg/copy.svg';
import { ReactComponent as Etherscan } from '../svg/etherscan.svg';
import { ReactComponent as Wallet } from '../svg/wallet.svg';
import { ReactComponent as Cancel } from '../svg/cancel.svg';


const HeroModal = styled.div<{ enabled: boolean }>`
    font-size: 1rem;;
    --animation-duration: 300ms;
    font-weight: 400;
    box-sizing: border-box;
    transition: opacity 0.1s ease-in-out 0s;
    text-align: center;
    position: fixed;
    width: 100vw;;
    height: 100vh;
    margin-left: -50vw;
    top: 0px;
    left: 50%;
    z-index: 10;
    will-change: opacity;
    background: rgba(11, 14, 19, 0.69);
    backdrop-filter: blur(11px);
    opacity: ${({ enabled }) => enabled ? '1' : '0'};
    visibility: visible;
    pointer-events: auto;
    display: ${({ enabled }) => enabled ? 'flex' : 'none'};
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
`

const ModalHitbox = styled.div`
    font-size: 1rem;

    font-weight: 400;

    text-align: center;
    visibility: visible;
    pointer-events: auto;
    box-sizing: border-box !important;
    position: absolute;
    inset: 0px;
`

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 39.5rem;
    height: 20.125rem;
    background-color: ${({ theme }) => theme.colors.uiColor1};
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    border-radius: 1rem;
    z-index: 5;
    ${({ theme }) => theme.media.small} {
        margin: 0 1rem;
        height: 15rem;
    }
`

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & span {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.8rem;
    }
    margin-right: 1.25rem;
    margin-left: 2.125rem;
    padding-top: 1.25rem;
    padding-bottom: 1.5rem;
    ${({ theme }) => theme.media.small} {
        & span {
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.1rem;
        }
        margin-right: 0.625rem;
        margin-left: 1rem;
        padding-top: 0.625;
        padding-bottom: 0.75rem;
    }
`

const ModalMain = styled.div`
    display: flex;
    margin-right: 1.25rem;
    margin-left: 2.125rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    border-radius: 0.5rem;
    height: 7.5rem;
    align-items: center;
    ${({ theme }) => theme.media.small} {
        
        margin-right: 0.625rem;
        margin-left: 1rem;
        height: 5.625rem;
       
    }
`

const ModalBottom = styled.div`
    display: flex;
    margin-right: 1.25rem;
    margin-left: 2.125rem;
    
    margin-top: 1.75rem;
    ${({ theme }) => theme.media.small} {
        
        margin-right: 0.625rem;
        margin-left: 1rem;
        margin-top: 0.9rem;
       
    }
`

const Change = styled.div`
    display: flex;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    height: 3.5rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-right: 0.75rem;
    cursor: pointer;
    text-transform: uppercase;
    & svg {
        width: 1.25rem;
        height: 1.25rem;
        color: ${({ theme }) => theme.colors.brandColor1};
        margin-right: 0.625rem;
    }

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.uiColor5};
    }

    ${({ theme }) => theme.media.small} {
        
        font-size: 0.875rem; 
        & svg {
            width: 1rem;
            height: 1rem;
            margin-right: 0.3rem;
        }
        height: 2.8rem;
    }

`

const Disconnect = styled.div`
    display: flex;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.uiColor1};
    background-color: ${({ theme }) => theme.colors.brandColor1};
    height: 3.5rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0.75rem;
    text-transform: uppercase;
    cursor: pointer;
    & svg {
        width: 1.25rem;
        height: 1.25rem;
        
        margin-right: 0.625rem;
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.uiColor5};
        box-shadow: ${({ theme }) => theme.colors.shadow1};
      }
    
      ${({ theme }) => theme.media.small} {
        
        font-size: 0.875rem;
        & svg {
            width: 1rem;
            height: 1rem;
            margin-right: 0.3rem;
        }
        height: 2.8rem;
       
    }

`

const Icon = styled.div`
    margin-left: 2rem;
    ${({ theme }) => theme.media.small} {
        & svg {
            width: 2.375rem;
            height: 2.25rem;
        }
    }
`

const Connected = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 2rem;
    height: 3rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;
    & span {
        font-size: 1rem;
        font-weight: 400;
    }

    & div {
        & span {
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: 500;
        }
        
    }

    ${({ theme }) => theme.media.small} {
    height: 2.375rem;
    font-size: 0.9375rem;
    line-height: 1rem;
    font-weight: 500;
    & span {
        font-size: 0.75rem;
        font-weight: 400;
    }

    & div {
        & span {
            font-size: 0.9375rem;
            line-height: 1rem;
            font-weight: 500;
        }
        
    }
    }
`

const ButtonContainer = styled.div`
    display: flex;
    justify-self: flex-end;
    margin-right: 1.75rem;
`

const MoonButton = styled.div<{ enabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:active {
        transform: translateY(2px);
    }
    width: 2.8rem;
    height: 2.8rem;
    color:  ${({ theme }) => theme.colors.uiColor5};
    margin-left: 0.625rem;
    border-radius: 3.75rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    transition: all 0.2s, border 0s;

    & svg {
        width: 1rem;
        height: 1rem;
    }

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.uiColor5};
    }

    ${({ theme }) => theme.media.small} {
        width: 2.1rem;
        height: 2.1rem;
        & svg {
            width: 0.85rem;
            height: 0.85rem;
        }
    }
`;

const CopyButton = styled.div<{ enabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:active {
        transform: translateY(2px);
    }
    width: 2.8rem;
    height: 2.8rem;
    
    color: ${({ enabled, theme }) => enabled ? theme.colors.brandColor1 : theme.colors.uiColor5};
    margin-left: 0.625rem;
    border-radius: 3.75rem;
    border: ${({ enabled, theme }) => enabled ? `1px solid ${theme.colors.brandColor1} !important` : `1px solid ${theme.colors.uiColor2}`};
    transition: all 0.2s, border 0s;
    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.uiColor5};
    }
    ${({ theme }) => theme.media.small} {
        width: 2.375rem;
        height: 2.375rem;
        & svg {
            width: 0.9rem;
            height: 0.9rem;
        }
    }
`;

const MoonButtonAnchor = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:active {
        transform: translateY(2px);
    }
    width: 2.8rem;
    height: 2.8rem;
    color:  ${({ theme }) => theme.colors.uiColor5};
    margin-left: 0.625rem;
    border-radius: 3.75rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    transition: all 0.2s, border 0s;
    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.uiColor5};
    }
    ${({ theme }) => theme.media.small} {
        width: 2.375rem;
        height: 2.375rem;
        & svg {
            width: 1rem;
            height: 0.9rem;
        }
    }
`;





const dispatchProps = {
    getBNBPrice: interfaceActions.getBNBPrice.request,
    connectWallet: walletActions.connectWallet.request,
    disconnectWallet: walletActions.disconnectWallet.request,
    switchNetwork: walletActions.switchNetwork.request,
};



type Props = {
    enabled: boolean;
    handleModal: () => void;
} & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Modal: React.FC<Props> = ({ enabled, handleModal, address, disconnectWallet, connectWallet, provider }) => {

    const [copy, setCopy] = useState(false);

    const getAccountString = (account: string) => {
        const first = account.slice(0, 4);
        const second = account.slice(-4);

        return `${first}...${second}`;
    }

    const copyAddress = (address: string) => {
        navigator.clipboard.writeText(address);
        setCopy(true)
        setTimeout(() => {
            setCopy(false)
        }, 1500)
    }

    const handleDisconnect = () => {
        disconnectWallet();
        handleModal();
    }

    const handleChangeWallet = () => {
        disconnectWallet();
        connectWallet();
        handleModal();
    }

    return (
        <HeroModal enabled={enabled}>
            <ModalHitbox onClick={handleModal}>
            </ModalHitbox>
            <ModalContainer>
                <ModalHeader>
                    <span>Account</span>
                    <MoonButton onClick={handleModal}>
                        <Close></Close>
                    </MoonButton>
                </ModalHeader>
                <ModalMain>
                    <Icon>
                        {
                            provider === 'injected'
                                ?
                                <Fox></Fox>
                                :
                                <WalletLogo></WalletLogo>
                        }

                    </Icon>
                    <Connected>
                        <div>Connected with {
                            provider === 'injected'
                                ?
                                <span>MetaMask</span>
                                :
                                <span>WalletConnect</span>
                        }
                        </div>
                        <span>{getAccountString(address)}</span>
                    </Connected>
                    <ButtonContainer>
                        <CopyButton onClick={() => copyAddress(address)} enabled={copy}><Copy></Copy></CopyButton>
                        <MoonButtonAnchor
                            href={`https://bscscan.com/address/${address}`}
                            target={'_blank'}
                            rel={'noreferrer'}>
                            <Etherscan></Etherscan>
                        </MoonButtonAnchor>
                    </ButtonContainer>
                </ModalMain>
                <ModalBottom>
                    <Change onClick={handleChangeWallet}><Wallet></Wallet>Change Wallet</Change>
                    <Disconnect onClick={handleDisconnect}><Cancel></Cancel>Disconnect Wallet</Disconnect>
                </ModalBottom>
            </ModalContainer>
        </HeroModal>
    )
}


const mapStateToProps = (state: RootState) => ({
    address: state.wallet.account,
    balance: state.wallet.balances.bnb,
    networkType: state.wallet.networkType,
    bnbPrice: state.interface.BNBPrice,
    provider: state.wallet.provider
});

export default connect(
    mapStateToProps,
    dispatchProps
)(Modal);