import { Simulation, SwapLimit } from "../../types/simulations"

export interface AnalyzerState {
    address: string;
    error: string;
    info: {
        loading: boolean;
        name: string;
        decimals: number;
    }
    simulations: {
        loading: boolean;
        result?: {
            simulations: Simulation[],
            limits: SwapLimit[],
        };
    },
    tokens: {
        [key: string]: {
            loading: boolean;
            error?: string;
            result?: {
                buy: Simulation;
                transfer: Simulation;
                approve: Simulation;
                sell: Simulation;
                buyLimit?: SwapLimit;
                sellLimit?: SwapLimit;
            };
        }
    }
}

export const initialState: AnalyzerState = {
    address: '',
    error: '',
    info: {
        loading: false,
        name: '',
        decimals: 18,
    },
    simulations: {
        loading: false,
        result: undefined,
    },
    tokens: {}
}