import BN from 'bn.js';

import { createAction, createAsyncAction } from 'typesafe-actions';
import { SignOwnershipProps, SignOwnershipResult } from '../../services/wallet';
import {
    ApproveProps,
    ApproveResult,
    SwapProps,
    SwapResult,
} from '../../services/toe-router';

export const signOwnership = createAsyncAction(
    'SIGN_OWNERSHIP_REQUEST',
    'SIGN_OWNERSHIP_SUCCESS',
    'SIGN_OWNERSHIP_FAILED'
)<SignOwnershipProps, SignOwnershipResult, string>();

export const connectWallet = createAsyncAction(
    'CONNECT_WALLET_REQUEST',
    'CONNECT_WALLET_SUCCESS',
    'CONNECT_WALLET_FAILED'
)<undefined, undefined, string>();

export const getBalance = createAsyncAction(
    'GET_BALANCE_REQUEST',
    'GET_BALANCE_SUCCESS',
    'GET_BALANCE_FAILED'
)<undefined, BN, string>();

export const getTokenAllowance = createAsyncAction(
    'GET_TOKEN_ALLOWANCE_REQUEST',
    'GET_TOKEN_ALLOWANCE_SUCCESS',
    'GET_TOKEN_ALLOWANCE_FAILED'
)<string, { address: string, amount: BN }, string>();

export const approve = createAsyncAction(
    'APPROVE_REQUEST',
    'APPROVE_SUCCESS',
    'APPROVE_FAILED'
)<ApproveProps, ApproveResult, string>();

export const swap = createAsyncAction(
    'SWAP_REQUEST',
    'SWAP_SUCCESS',
    'SWAP_FAILED',
)<SwapProps, SwapResult, string>();

export const buy = createAsyncAction(
    'BUY_REQUEST',
    'BUY_SUCCESS',
    'BUY_FAILED',
)<SwapProps, SwapResult, string>();

export const sell = createAsyncAction(
    'SELL_REQUEST',
    'SELL_SUCCESS',
    'SELL_FAILED',
)<SwapProps, SwapResult, string>();

export const getTokenBalance = createAsyncAction(
    'GET_TOKEN_BALANCE_REQUEST',
    'GET_TOKEN_BALANCE_SUCCESS',
    'GET_TOKEN_BALANCE_FAILED'
)<string, { address: string, amount: BN }, string>();

export const switchNetwork = createAsyncAction(
    'SWITCH_NETWORK_REQUEST',
    'SWITCH_NETWORK_SUCCESS',
    'SWITCH_NETWORK_FAILED'
)<undefined, undefined, string>();


export const disconnectWallet = createAsyncAction(
    'DISCONNECT_WALLET_REQUEST',
    'DISCONNECT_WALLET_SUCCESS',
    'DISCONNECT_WALLET_FAILED'
)<undefined, undefined, string>();

export const setWallet = createAsyncAction(
    'SET_WALLET_REQUEST',
    'SET_WALLET_SUCCESS',
    'SET_WALLET_FAILED'
)<{ address: string; provider: string }, string, string>();

export const handleNoConnection = createAction('HANDLE_NO_CONNECTION')<undefined>();

export const setNetworkType = createAction('SET_NETWORK_TYPE')<string>();

export const clearBuyError = createAction('CLEAR_BUY_ERROR')<undefined>();
export const clearSellError = createAction('CLEAR_SELL_ERROR')<undefined>();