import { PairInterface } from '../../types/pairs';

export interface PairsState {
    dashboardPairs: PairInterface[];
    trendingPairs: PairInterface[];
    customPairs: PairInterface[];
}

export const initialState: PairsState = {
    dashboardPairs: [],
    trendingPairs: [],
    customPairs: [],
}