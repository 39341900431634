import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { preferredNetwork } from '../constants/network';

export class Wallet {
    private onAddress?: (address: string, provider: string) => void;
    private onNetworkType?: (networkType: string) => void;
    private web3Modal: Web3Modal;
    private web3!: Web3;
    public address: string = '';
    public networkType: string = '';
    public provider: string = '';

    constructor() {
        this.web3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions: this.getProviderOptions(),
            theme: {
                background: "#0B0E13",
                main: "#FFFFFF",
                secondary: "#FFFFFF",
                hover: "#29313E",
                border: "none"
            }
        });
    }

    private getProviderOptions = () => {
        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    network: "binance", // here
                    rpc: {
                        56: "https://bsc-dataseed1.binance.org",
                    },
                    chainId: 56,
                }
            }
        };
        return providerOptions;
    };

    private initWeb3 = (provider: any) => {
        const web3: Web3 = new Web3(provider);
        web3.eth.extend({
            methods: [
                {
                    name: "chainId",
                    call: "eth_chainId",
                    outputFormatter: web3.utils.hexToNumber as any
                }
            ]
        });
        return web3;
    }

    public getWeb3 = async () => {
        if (!this.web3 || this.networkType !== preferredNetwork.networkType) {
            return new Web3(preferredNetwork.backupRpc);
        }
        return Promise.resolve(this.web3 as any);
    }

    public switchNetwork = async () => {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: preferredNetwork.chainId }],
            });
            return Promise.resolve();
        } catch (error) {
            return Promise.reject();
        }
    }

    public connect = async (): Promise<void> => {
        try {
            const provider = await this.web3Modal.connect();
            await this.subscribeProvider(provider);

            this.web3 = this.initWeb3(provider);
            const newProvider = this.web3Modal.cachedProvider;
            const networkType = await this.web3.eth.net.getNetworkType();
            const accounts = await this.web3.eth.getAccounts();
            const address = accounts[0];

            this.address = address;
            this.networkType = networkType;
            this.provider = newProvider;

            if (this.onAddress) {
                this.onAddress(address, newProvider);
            }
            if (this.onNetworkType) {
                this.onNetworkType(networkType);
            }

            // TO-DO epics

            return Promise.resolve();
        } catch (error) {
            return Promise.reject('Web3 not ready');
        }
    }

    public disconnect = async () => {
        this.web3Modal.clearCachedProvider();
        this.address = '';
        this.provider = '';
        if (this.onAddress) {
            this.onAddress('', '');
        }
    }

    public subscribeAddress = async (onAddress: (address: string, provider: string) => void) => {
        this.onAddress = onAddress;
        if (this.web3Modal.cachedProvider) {
            return this.connect();
        } else {
            this.onAddress('', '');
        }
    }

    public subscribeNetworkType = (onNetworkType: (networkType: string) => void) => {
        this.onNetworkType = onNetworkType;
    }

    private subscribeProvider = async (provider: any) => {
        if (!provider.on) return;

        provider.on('accountsChanged', async (accounts: string[]) => {
            const address = accounts[0];
            this.address = address;
            this.onAddress && this.onAddress(address, this.provider);
            !address && this.web3Modal.clearCachedProvider();
        });

        provider.on("chainChanged", async (chainId: number) => {
            const networkType = await this.web3.eth.net.getNetworkType();
            this.networkType = networkType;
            if (this.onNetworkType) {
                this.onNetworkType(networkType);
            }
        });

        provider.on('disconnect', (code: number, reason: string) => {
            this.web3Modal.clearCachedProvider();
            this.address = '';
            this.provider = '';
            this.onAddress && this.onAddress('', '');
        });

        provider.on('close', () => {
            this.web3Modal.clearCachedProvider();
            this.address = '';
            this.provider = '';
            this.onAddress && this.onAddress('', '');
        });
    };
}

let instance: Wallet;

const getWallet = (): Wallet => {
    if (instance) {
        return instance;
    } else {
        instance = new Wallet();
        return instance;
    }
}

export default getWallet;