import { createReducer } from 'typesafe-actions';
import { initialState, PageTab } from './state';
import * as interfaceActions from './actions';

export default createReducer(initialState)
    .handleAction(
        interfaceActions.subscribeToDashboard.request,
        (state, action) => {
            console.debug('subscribeToDashboard request');
            return {
                ...state
            };
        }
    )
    .handleAction(
        interfaceActions.subscribeToDashboard.success,
        (state, action) => {
            console.debug('subscribeToDashboard success');
            return {
                ...state,
            };
        }
    )
    .handleAction(
        interfaceActions.subscribeToDashboard.failure,
        (state, action) => {
            console.debug('subscribeToDashboard failure');
            return {
                ...state,
            };
        }
    )
    .handleAction(
        interfaceActions.openPage.success,
        (state, action) => {
            const pageTab = action.payload;
            return {
                ...state,
                openPages: [pageTab].reduce(
                    (acc: PageTab[], page) => !acc.find(({ pathname }) => pathname === pageTab.pathname) ? acc.concat(page) : acc.map((p) => p.pathname === page.pathname ? { ...p, ...page } : p), state.openPages
                )
            };
        }
    )
    .handleAction(
        interfaceActions.updatePage,
        (state, action) => {
            const pageTab = action.payload;
            return {
                ...state,
                openPages: state.openPages.map((p) => p.pathname === pageTab.pathname ? { ...p, ...pageTab } : p),
            };
        }
    )
    .handleAction(
        interfaceActions.openPage.failure,
        (state, action) => {
            const pageTab = action.payload;
            return {
                ...state,
                openPages: [pageTab].reduce(
                    (acc: PageTab[], page) => !acc.find(({ pathname }) => pathname === page.pathname) ? acc.concat(page) : acc.map((p) => p.pathname === page.pathname ? { ...p, ...page } : p), state.openPages
                )
            }
        }
    )
    .handleAction(
        interfaceActions.closePage.success,
        (state, action) => {
            return {
                ...state,
                openPages: state.openPages.filter(({ pathname }) => pathname !== action.payload)
            };
        }
    )
    .handleAction(
        interfaceActions.getBNBPrice.success,
        (state, action) => {
            return {
                ...state,
                BNBPrice: action.payload
            };
        }
    )
    .handleAction(
        interfaceActions.getNewestBlock.success,
        (state, action) => {
            const { blockTimestamp, difference } = action.payload;
            return {
                ...state,
                newestBlock: blockTimestamp,
                difference
            };
        }
    )
    .handleAction(
        interfaceActions.switchPair.success,
        (state, action) => {
            const { from, page } = action.payload;
            const fromPath = `/pair/${from}`;
            return {
                ...state,
                openPages: state.openPages.map((prevPage) => fromPath === prevPage.pathname ? page : prevPage),
            };
        }
    )
    .handleAction(
        interfaceActions.setFilter,
        (state, action) => {
            const { filter, value } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [filter]: value
                }
            };
        }
    )
    .handleAction(
        interfaceActions.setSorting,
        (state, action) => {
            return {
                ...state,
                sorters: action.payload
            };
        }
    )
    .handleAction(
        interfaceActions.setHidden,
        (state, action) => {
            return {
                ...state,
                hiddenPairs: [action.payload].reduce(
                    (acc: string[], pair) => acc.includes(pair) ? acc.filter((address) => address !== pair) : acc.concat(pair), state.hiddenPairs
                )
            };
        }
    )
    .handleAction(
        interfaceActions.setError,
        (state, action) => {
            console.log(action);
            return {
                ...state,
                error: action.payload
            };
        }
    );