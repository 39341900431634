import React, { MutableRefObject } from 'react';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import BN from 'bn.js';
import styled, { css, keyframes } from 'styled-components/macro';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import lottie from 'lottie-web/build/player/lottie_light';

import { ReactComponent as Attention } from '../svg/attention.svg';
import { ReactComponent as Cancel } from '../svg/cancel.svg';
import { ReactComponent as Check } from '../svg/check.svg';
import { ReactComponent as Warning } from '../svg/warning.svg';
import { simplify } from '../../utils/numbers';
import * as analyzeActions from '../../store/analyze/actions';
import { SwapLimit } from '../../types/simulations';
import isMalicious from '../../selectors/is-malicious';
import animationData from '../animations/simulations.json';
import { PairInterface } from '../../types/pairs';

const TopContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    align-items: center;
    margin-bottom: 1.5rem;
`;

const TokenInfoContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.75rem;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    border-radius: 1rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
`;

const CheckBoxContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 400;
    height: 1.5rem;
`;

const CheckBox = styled.div<{ warning?: boolean; success: boolean; }>`
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme, warning, success }) => warning ? theme.colors.triggerColor3 : success ? theme.colors.triggerColor5 : theme.colors.triggerColor1};
    border-radius: 0.5rem;
    height: 1.5rem;

    width: 1.4rem;
    margin-left: 1rem;

    & svg  {
        height: 1.5rem;
        width: 1.5rem;
    }
`;

const SimulationContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const HeaderItem = styled.span`
    text-align: left;
    font-weight: 500;
    font-size: 1rem;
`;

const Item = styled.span<{ minSized?: boolean }>`
    text-align: right;
    font-weight: 400;
    ${({ minSized }) => minSized && `
        min-width: 7rem;
    `}
    height: 1.5rem;
`;

const TitleContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

const Title = styled.div`
    font-weight: 500;
    font-size: 1.5rem;
`;

const spinAni = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const MoonButton = styled.div<{ spin: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    width: 2.8rem;
    height: 2.8rem;
    color:  ${({ theme }) => theme.colors.uiColor5};
    border-radius: 3.75rem;
   
    transition: all 0.2s ${({ theme }) => theme.transitions.main};

    & svg {
        width: 1rem;
        height: 1rem;
    }
    animation: ${({ spin }) => spin ? css`${spinAni} 0.5s ease forwards` : 'none'};
    will-change: transform;

   /*  transform-origin: center;
    transform: ${({ spin }) => spin ? 'rotate(360deg)' : 'rotate(0deg)'};
    transition: transform 0.7s ${({ theme }) => theme.transitions.main}; */
`;

const WarningContainer = styled.div`
    display: flex;
    align-items: center;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    justify-content: center;
    border-radius: 0.5rem;
    transition: border-bottom 0.2s ease;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colors.uiColor6};

`;

const LimitContainer = styled.div`
   display: flex;
   width: 100%;
   justify-content: space-between;
   & span {
    color: ${({ theme }) => theme.colors.brandColor1};
   }
`;

const IconContainer = styled.div<{ enabled: boolean; red?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme, enabled, red }) => !enabled ? 'gray' : red ? theme.colors.triggerColor3 : 'inherit'};
    height: 1.35rem;
    width: 1.35rem;
    margin-right: 0.5rem;
`;

const dispatchProps = {
    simulate: analyzeActions.simulate.request,
};

type OwnProps = {
    contractAnalysis?: string[];
    isRug: boolean;
} & Pick<PairInterface, 'address' | 'token'>;

type Props = OwnProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

interface State {
    spin: boolean;
}

class TradeSimulations extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            spin: false,
        }
    }

    private animationContainer = React.createRef<HTMLDivElement>();
    private anim = React.createRef<MutableRefObject<null>>();
    private interval: any;
    private spinTimeout!: NodeJS.Timeout;

    componentDidMount = () => {
        if (this.animationContainer.current) {
            //@ts-ignore
            this.anim.current = lottie.loadAnimation({
                container: this.animationContainer.current,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData,
                name: 'reload'
            });
        }
        if (!this.props.isRug) {
            this.simulate();
            this.interval = setInterval(this.simulate, 5200);
        }
    }

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.address !== this.props.address) {
            this.simulate();
            if (!this.interval) {
                this.interval = setInterval(this.simulate, 5200);
            }
        }
    }

    componentWillUnmount = () => {
        //@ts-ignore
        this.anim.current?.destroy();
        clearInterval(this.interval);
        clearTimeout(this.spinTimeout);
    }

    simulate = () => {
        lottie.stop('reload')
        if (this.state.spin) {
            this.setState({ spin: false });
        }
        else {
            this.setState({ spin: true });
            this.spinTimeout = setTimeout(() => {
                this.setState({ spin: false });
            }, 700)
        }
        const { token } = this.props;
        this.props.simulate({
            address: token.address,
            decimals: token.decimals,
        });
        if (this.props.isRug === false) {
            lottie.play('reload')
        }
        if (this.props.isRug === true) {
            clearInterval(this.interval)
        }
    }

    handleSimulate = () => {
        this.simulate()
        clearInterval(this.interval);
        if (!this.props.isRug) {
            this.interval = setInterval(this.simulate, 5200);
        }
    }

    getContractMessage = (maliciousType: string) => {
        switch (maliciousType) {
            case 'blacklistable':
                return 'Owner may blacklist wallets';
            case 'mintable':
                return 'Owner may mint tokens';
            case 'unsellable':
                return 'Contract may be unsellable'
            case 'unrestrictedTax':
                return 'Owner may set tax to 100%';
            case 'taxable':
                return 'Owner may set a high tax';
            case 'extremelyMalicious':
                return 'Extremely malicious contract';
            case 'pausable':
                return 'Owner can disable transfers';
            default:
                return "No malicious functions detected";
        }
    }

    render() {
        const { loading, buy, sell, maxBuy, maxSell, contractAnalysis } = this.props;
        const maliciousTypes = (contractAnalysis || []).reduce(
            (acc: string[], type) => {
                return isMalicious(type) ? acc.concat(type) : acc;
            }
            ,
            []
        );
        return (
            <TopContainer>
                <TitleContainer>
                    <Title>Trade</Title>
                    <MoonButton spin={this.state.spin} onClick={this.handleSimulate} ref={this.animationContainer}></MoonButton>
                </TitleContainer>
                <TokenInfoContainer>
                    <SimulationRow
                        type={'Buy'}
                        loading={loading}
                        result={!!buy}
                        success={buy?.success || false}
                        tax={buy?.tax || new BN(0)}
                        limit={maxBuy}
                    />
                    <SimulationRow
                        type={'Sell'}
                        loading={loading}
                        result={!!sell}
                        success={sell?.success || false}
                        tax={sell?.tax || new BN(0)}
                        limit={maxSell}
                    />
                    {
                        maliciousTypes.map(
                            (type) => (
                                <WarningContainer key={type}>
                                    <IconContainer enabled red>
                                        <Warning />
                                    </IconContainer>
                                    <span>
                                        {this.getContractMessage(type)}
                                    </span>
                                </WarningContainer>
                            )
                        )
                    }
                </TokenInfoContainer>
            </TopContainer>
        )
    }
}


const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
    const tokenAddress = ownProps.token.address;
    const hasSimulations = Object.keys(state.analyze.tokens).includes(tokenAddress);
    const simulations = hasSimulations ? state.analyze.tokens[tokenAddress] : undefined;

    return {
        loading: simulations?.loading || false,
        error: simulations?.error || '',
        buy: simulations?.result?.buy,
        sell: simulations?.result?.sell,
        maxBuy: simulations?.result?.buyLimit,
        maxSell: simulations?.result?.sellLimit,
    }
};

export default connect(
    mapStateToProps,
    dispatchProps,
)(TradeSimulations);

interface SimProps {
    loading: boolean,
    type: string;
    success: boolean;
    result: boolean;
    tax: BN;
    limit?: SwapLimit;
}

const SimulationRow: React.FunctionComponent<SimProps> = ({
    loading,
    type,
    success,
    result,
    tax,
    limit
}) => {
    const warning = (tax.toNumber() / 100 > 20 && tax.toNumber() / 100 < 100);
    const hasLimit = limit?.hasLimit || false;
    return (
        <React.Fragment>
            <SimulationContainer>
                <HeaderItem>
                    {type}
                </HeaderItem>
                <Item minSized>
                    {result ? (
                        <CheckBoxContainer>
                            {
                                !success ? (
                                    <>
                                        Fail
                                    </>
                                ) : (
                                    <span>
                                        {tax.toNumber() / 100}% tax
                                    </span>
                                )
                            }
                            <CheckBox warning={warning} success={success && tax.toNumber() / 100 < 50}>
                                {
                                    loading ? (
                                        <Loader
                                            type="Oval"
                                            color="#ffffff"
                                            height={'1.2rem'}
                                            width={'1.2rem'}
                                            visible={true}
                                        />
                                    ) : (

                                        <>{warning ? <Attention /> : success ? <Check /> : <Cancel />}</>

                                    )
                                }
                            </CheckBox>
                        </CheckBoxContainer>
                    ) : '-'}
                </Item>
            </SimulationContainer>
            {
                (limit?.hasLimit || false) &&
                (
                    limit.type === 'Buy' ?
                        <LimitContainer>
                            <div>
                                Max buy limit between <span>{simplify(limit.low, 2)}</span> and <span>{simplify(limit.high, 2)}</span> {type === 'Sell' ? 'tokens' : 'BNB'} {type === 'Sell' && (<>(<span>{parseFloat(limit.fiatAmount).toFixed(3)} BNB</span>)</>)}
                            </div>
                            <CheckBox warning={warning || hasLimit} success={success && tax.toNumber() / 100 < 50}>
                                <Attention></Attention>
                            </CheckBox>
                        </LimitContainer>
                        :
                        <LimitContainer>
                            <div>
                                Max sell limit near {type === 'Sell' && (<>(<span>{parseFloat(limit.fiatAmount).toFixed(2)} BNB</span>)</>)}
                            </div>
                            <CheckBox warning={warning || hasLimit} success={success && tax.toNumber() / 100 < 50}>
                                <Attention></Attention>
                            </CheckBox>
                        </LimitContainer>
                )
            }
        </React.Fragment >
    );
}