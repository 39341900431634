export const httpEndpoints = [
    'https://bsc-dataseed.binance.org/',
    'https://bsc-dataseed1.defibit.io/',
    'https://bsc-dataseed1.ninicoin.io/',
    'https://bsc-dataseed2.defibit.io/',
    'https://bsc-dataseed3.defibit.io/',
    'https://bsc-dataseed4.defibit.io/',
    'https://bsc-dataseed2.ninicoin.io/',
    'https://bsc-dataseed3.ninicoin.io/',
    'https://bsc-dataseed4.ninicoin.io/',
    'https://bsc-dataseed1.binance.org/',
    'https://bsc-dataseed2.binance.org/',
    'https://bsc-dataseed3.binance.org/',
    'https://bsc-dataseed4.binance.org/',
    //'https://dataseed.breki.io'
    //'http://192.168.1.17:8545'
];

export const wsEndpoints = [
    //'wss://ws-dataseed.breki.io'
    //'ws://192.168.1.17:8546'
    //'wss://speedy-nodes-nyc.moralis.io/0f071ac00c7db7ebfe09ec52/bsc/mainnet/ws/',
    'wss://bsc-ws-node.nariox.org:443',
    //'wss://rough-damp-water.bsc.quiknode.pro/a920965803fbdd0057a1aeae2533edcc1f96f231'
];

export const archiveEndpoints = [
    'https://speedy-nodes-nyc.moralis.io/3f8f187750bf06dc49b77a24/bsc/mainnet/archive'
]