import { createReducer } from 'typesafe-actions';
import { initialState } from './state';
import * as socketActions from './actions';

export default createReducer(initialState)
    .handleAction(
        socketActions.createSocket.request,
        (state, action) => {
            //console.debug('createSocket request');
            return {
                ...state,
            };
        }
    )
    .handleAction(
        socketActions.createSocket.success,
        (state, action) => {
            //console.debug('createSocket success');
            return {
                ...state,
            }
        }
    )
    .handleAction(
        socketActions.createSocket.failure,
        (state, action) => {
            //console.debug('createSocket failure');
            return {
                ...state,
            };
        }
    )
    .handleAction(
        socketActions.connectSocket.request,
        (state, action) => {
            return {
                ...state,
                requested: true,
                requestedPairs: action.payload,
            };
        }
    )
    .handleAction(
        socketActions.connectSocket.success,
        (state) => {
            return {
                ...state,
                connected: true,
            };
        }
    )
    .handleAction(
        socketActions.connectSocket.failure,
        (state, action) => {
            return {
                ...state,
                lastError: action.payload,
            };
        }
    )
    .handleAction(
        socketActions.logMessage,
        (state, action) => {
            action.payload.forEach(console.log);
            return state;
        }
    )