import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { withRouter, RouteComponentProps } from 'react-router';
import * as interfaceActions from '../../store/interface/actions';
import * as walletActions from '../../store/wallet/actions';
import { RootState } from 'typesafe-actions';
import { connect } from 'react-redux';
import { preferredNetwork } from '../../constants/network';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { ReactComponent as Dashboard } from '../svg/dashboard.svg';
import { ReactComponent as Analyze } from '../svg/analyze.svg';
import { ReactComponent as User } from '../svg/user.svg';
import { ReactComponent as Search } from '../svg/search.svg';
import { ReactComponent as Rocket } from '../svg/rocket.svg';
import { ReactComponent as Chart } from '../svg/chart.svg';
import { ReactComponent as MetaMask } from '../svg/metamask.svg';
import { ReactComponent as WalletConnect } from '../svg/walletconnect.svg';
import { ReactComponent as Binance } from '../svg/chainbnb.svg';
import { ReactComponent as Ethereum } from '../svg/ethereumchain.svg';
import { ReactComponent as Polygon } from '../svg/polygonchain.svg';
import { ReactComponent as Avalanche } from '../svg/avalanchechain.svg';
import { ReactComponent as TriangleUp } from '../svg/triangleup.svg';
import { ReactComponent as TriangleDown } from '../svg/triangledown.svg';

interface NavProps {
    enabled: boolean;
}

/* const fadeInRight = keyframes`
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
`; */

/* const closeFade = keyframes`
    0% {
        opacity : 0;
    }
    100% {
        opacity: 1;
    }
`; */


const NavigationContainer = styled.div<NavProps>`
    display: block;
    width: 100%;
    height: 100%;
    max-width: ${({ enabled }) => enabled ? '100%' : '0%'};
    position: fixed;
    top: 9.275rem;
    overflow-y: scroll;
    right: 0;
    bottom: 0;
    z-index: 3;
    background-color: ${({ theme }) => theme.colors.uiColor1};
    border-top: 1px solid ${({ theme }) => theme.colors.uiColor2};
    transition: all .6s ease, background-color 0.2s, height 0s;
`;

const NavigationHero = styled.div<NavProps>`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

`;

const LinkContainer1 = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    margin-top: 2rem;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.uiColor2};

`;

const LinkContainer3 = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2rem;
    padding-bottom: 10rem;

`;

const LinkContainer2 = styled.div<{ open: boolean }>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    margin-bottom: 1rem;
    pointer-events: ${({ open }) => open ? 'none' : 'auto'};

`;

const BasicLink = styled.span`
    font-weight: 400;
    font-size: 0.875rem;
`;

const BasicLinkVersion = styled.span`
    font-weight: 400;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.uiColor4};

`;

const BNBspan = styled.span<{ sidebar?: boolean; }>`
    display: ${({ sidebar }) => sidebar ? 'flex' : 'none'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
`;


/* const Title = styled.a<NavProps>`
    font-weight: 400;
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
    cursor: pointer;
    width: min-content;
    color: ${({theme}) => theme.colors.uiColor7};
    &:hover {
        color: ${({theme}) => theme.colors.triggerColor4};
    }
    opacity: ${({ enabled }) => enabled ? '1' : '0'};
    transition: ${({ enabled }) => enabled ? 'opacity 0.2s linear 0.6s' : 'none'};
    & span {
        display: ${({ enabled }) => enabled ? 'block' : 'none'};
    }

`; */

const NavButton = styled.div<{ selected?: boolean }>`
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: transparent;
    padding: 0.9rem 0rem;
    margin-bottom: 0.75rem;
    color: ${({ selected, theme }) => selected ? theme.colors.uiColor5 : theme.colors.uiColor4};
    font-weight: 500;
    font-size: 1.25rem;

    & svg {
        height: 1.25rem;
        width: 1.25rem;
        color: ${({ selected, theme }) => selected ? theme.colors.brandColor1 : theme.colors.uiColor4};
    }

    & a{
        display: flex;
        justify-content: center;
        line-height: 1.15rem;
        & svg {
            margin-right: 0.75rem;
        }
        &:hover {
            & svg {
                
                color: ${({ theme }) => theme.colors.uiColor5};
            }   
            
        }
    }
    
`;

const Wallet = styled.div<{ selected?: boolean; sidebar: boolean; open: boolean; }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 0.875rem 1.5rem;
    margin-bottom: 0.75rem;
    color: ${({ theme }) => theme.colors.uiColor5};
    font-weight: 500;
    font-size: 0.875rem;
    height: 3rem;
    text-transform: uppercase;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    border-radius: 0.5rem;
    opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
    pointer-events: ${({ open }) => open ? 'none' : 'auto'};


    & svg  {
        margin-right: 0.625rem;
        color: ${({ theme }) => theme.colors.brandColor1};
        //filter: drop-shadow(2px 2px 0px black);
    }

    & a{
        display: flex;
        justify-content: center;
        line-height: 1.15rem;
        & svg {
            margin-right: 0.75rem;
        }
        &:hover {
            & svg {
                
                color: ${({ theme }) => theme.colors.uiColor5};
            }   
            
        }
    }
    
`;

const ComingNavButton = styled.div<{ sidebar?: boolean; message: string; }>`
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: transparent;
    padding: 0.9rem 0rem;
    margin-bottom: 0.75rem;
    color: ${({ theme }) => theme.colors.uiColor2};
    font-weight: 500;
    font-size: 1.25rem;
    position: relative;

    & svg {
        height: 1.25rem;
        width: 1.25rem;
        color: ${({ theme }) => theme.colors.uiColor2};
    }

    & div {
        cursor: pointer;
        
        display: flex;
        justify-content: center;
        line-height: 1.15rem;
        & svg {
            margin-right: 0.75rem;
        }
       
    
`;

const TextSpan = styled.div<{ sidebar?: boolean }>`
opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};

`

const ComingSoonSpan = styled.div`
font-size: 0.5rem;
font-weight: 500;
border-radius: 3rem;
display: flex;
align-items: center;
z-index: 99;
background-color: ${({ theme }) => theme.colors.uiColor3};
width: min-content;
color: white;
text-decoration: none;
white-space: nowrap;
transition: all 0.15s ${({ theme }) => theme.transitions.main};
pointer-events: none;
text-transform: uppercase;
padding: 0.375rem 0.5rem;
height: 1.25rem;
margin-left: 0.875rem;
`

const Chainspan = styled.span<{ sidebar?: boolean; }>`
    opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
    font-weight: 500;
`;

const ChainContainer = styled.div<{ sidebar?: boolean; active: boolean; }>`
    display:  ${({ active }) => active ? 'flex' : 'none'};
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    padding-bottom: 0rem;
    background-color: ${({ theme }) => theme.colors.uiColor1};
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    border-left: ${({ sidebar, theme }) => sidebar ? `1px solid ${theme.colors.uiColor2}` : 'none'};
    border-right: ${({ sidebar, theme }) => sidebar ? `1px solid ${theme.colors.uiColor2}` : 'none'};
    
    border-radius: ${({ sidebar }) => sidebar ? '0.5rem' : '0rem'};
    position: absolute;
    top: -13.5rem;
    width: 100%;
    pointer-events: none;
`;

const ChooseChain = styled.div<{ sidebar?: boolean; }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    margin-left: 0.4rem;
    margin-left: ${({ sidebar }) => sidebar ? '0.4rem' : '0.7rem'};
    & svg {
        width: 1.6rem !important;
        height: 1.9rem !important;
        color: ${({ theme }) => theme.colors.brandColor4} !important;
        
    }
    & div {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.875rem;
        line-height: 1rem;
    }
    color: ${({ theme }) => theme.colors.uiColor5};
    
    pointer-events: none;
    &:active {
        pointer-events: none;
    }
    

`;

const ChooseChainGrey = styled.div<{ sidebar?: boolean; message: string; }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    width: 7.5rem;
    margin-left: ${({ sidebar }) => sidebar ? '0.4rem' : '0.7rem'};
    & svg {
        width: 1.6rem !important;
        height: 1.9rem !important;
        color: ${({ theme }) => theme.colors.uiColor3} !important;
        pointer-events: auto !important;
        &:active {
            pointer-events: none !important;
        }
    }
    & div {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.875rem;
        line-height: 1rem;
    }
    color: ${({ theme }) => theme.colors.uiColor3};
    &:active {
        pointer-events: none;
    }
    pointer-events: ${({ sidebar }) => sidebar ? 'auto' : 'none'};
    cursor: pointer;

    & >span {
        font-size: 0.5rem;
        font-weight: 500;
        border-radius: 3rem;
        display: flex;
        align-items: center;
        z-index: 99;
        position: absolute;
        background-color: ${({ theme }) => theme.colors.uiColor2};
        left: 8rem;
        color: white;
        text-decoration: none;
        white-space: nowrap;
        transition: all 0.15s ${({ theme }) => theme.transitions.main};
        
        pointer-events: none;
        text-transform: uppercase;
        padding: 0.375rem 0.5rem;
        height: 1.125rem;
    }

    &:hover {
        & >span {
            opacity: 1;
            transition: all 0.15s ${({ theme }) => theme.transitions.main};
            transition-delay: 0;
        }
    }


`;

/* const LaunchButton = styled.a<NavProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ enabled }) => enabled ? '1rem 1.25rem 1rem 1.25rem' : '0'};
  margin-bottom: 8.6rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.triggerColor11};
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.triggerColor12};
  width: 100%;
  max-width: ${({ enabled }) => enabled ? '100%' : '0%'};
  opacity: ${({ enabled }) => enabled ? '1' : '0'};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.uiColor10};
    background-color: ${({ theme }) => theme.colors.triggerColor14};
    box-shadow: ${({ theme }) => theme.colors.shadow1};
  }
  & span {
    font-weight: 400;
    display: ${({ enabled }) => enabled ? 'block' : 'none'};
  }
  transition: ${({ enabled }) => enabled ? 'opacity 0.2s linear 0.6s' : 'none'};
  & div {
    display: ${({ enabled }) => enabled ? 'block' : 'none'};
  }
`; */

/* const NavBar = styled.nav`
    position: relative;
    height: 70%;
    top: 60%;
    transform: translateY(-50%);
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    z-index: 100;
`;

const UnorderedList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%; 
`;

const ListItem = styled.li<NavProps>`
    display:  ${({ enabled }) => enabled ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    opacity: 0;
    animation: ${fadeInRight} .5s ease forwards;
    animation-delay: .6s;
    cursor: pointer;
    :hover, focus{
        -webkit-text-stroke: 2px #b2b2b2;
    }

    :nth-of-type(2) {
        animation-delay: .8s;
      }
    
    :nth-of-type(3) {
        animation-delay: 1s;
      }
    
    :nth-of-type(4) {
    animation-delay: 1.2s;
    }
`; */

const TopPriceContainer = styled.div<{ sidebar?: boolean; active: boolean; }>`
    display: flex;
    align-self: center;
    position: relative;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 0.4rem;
    opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.6s' : 'none'};
    & svg {
        margin-right: ${({ sidebar }) => sidebar ? '0.5rem' : '0rem'};
        color: ${({ active, theme }) => active ? theme.colors.uiColor1 : theme.colors.brandColor4};
    }
    background-color: ${({ theme }) => theme.colors.uiColor6};
    border-radius: ${({ sidebar }) => sidebar ? '0.5rem' : '0rem'};
    background-color: ${({ active, theme }) => active ? theme.colors.brandColor1 : theme.colors.uiColor6};
    color: ${({ active, theme }) => active ? theme.colors.uiColor1 : theme.colors.uiColor5};
    box-shadow: ${({ active }) => active ? '0px 8px 30px rgba(251, 248, 204, 0.3)' : 'none'};
    
    
    width: 100%;
    transition: ${({ sidebar }) => sidebar ? 'none' : 'width 0.1s linear 0.1s'};

    height: 3.125rem;
    cursor: pointer;
    pointer-events: ${({ sidebar }) => sidebar ? 'auto' : 'none'};
   
`;

const BinanceContainer = styled.div<{ sidebar?: boolean; }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
   
    margin-left: ${({ sidebar }) => sidebar ? '1.5rem' : '1.8rem'};
`;

const ChainLogoContainer = styled.div`
display: flex;
    justify-content: flex-start;
    align-items: center;
& svg {
    width: 1.6rem !important;
    height: 1.9rem !important;

}
cursor: pointer;
pointer-events: auto !important;
    
`;

const PillContainer = styled.div<{ sidebar?: boolean; }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    & svg {
        color: inherit;
    }
    cursor: pointer;
    opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
    pointer-events: ${({ sidebar }) => sidebar ? 'auto' : 'none'};
    
`;


const PriceContainer = styled.div<{ sidebar?: boolean; }>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    margin-right: 1.2rem;
    color: ${({ sidebar, theme }) => sidebar ? theme.colors.uiColor1 : theme.colors.brandColor4};

    &>* {
        font-family: ${({ theme }) => theme.fonts.main};
        
        
        line-height: 1.15rem;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
            margin-top: 0.25rem;
        }
    }

   
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const ChainText = styled.div<{ sidebar?: boolean; }>`
    
    opacity: ${({ sidebar }) => sidebar ? '1' : '0'};
    transition: ${({ sidebar }) => sidebar ? 'opacity 0.2s linear 0.1s' : 'none'};
`;

const dispatchProps = {
    getBNBPrice: interfaceActions.getBNBPrice.request,
    connectWallet: walletActions.connectWallet.request,
    disconnectWallet: walletActions.disconnectWallet.request,
    switchNetwork: walletActions.switchNetwork.request,

};

type Props = {
    enabled: boolean;
    handleClose: () => void;
    handleModal: () => void;
} & RouteComponentProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Navigation: React.FC<Props> = ({ enabled, handleClose, bnbPrice, match, location, networkType, switchNetwork, connectWallet, handleModal, address, provider }) => {


    const [chain, setChain] = useState(false);

    const handleConnect = (disconnect?: boolean) => {
        if (disconnect) {
            if (networkType !== preferredNetwork.networkType) {
                switchNetwork();
            } else {
                handleModal()
            }
        } else {
            connectWallet();
        }
    }

    const handleChain = () => {
        setChain(!chain);

    }
    const getAccountString = (account: string) => {
        const first = account.slice(0, 4);
        const second = account.slice(-4);

        return `${first}...${second}`;
    }

    return (
        <NavigationContainer enabled={enabled}>
            <NavigationHero enabled={enabled}>
                <LinkContainer1>
                    <NavButton selected={match.path === '/' || match.path.startsWith('/pair')}>
                        <a href="/">
                            <Dashboard />
                            <TextSpan sidebar={true}>
                                Dashboard
                            </TextSpan>
                        </a>
                    </NavButton>
                    <NavButton selected={match.path.startsWith('/analyze')}>
                        <a href="/analyze" >
                            <Analyze />
                            <TextSpan sidebar={true}>
                                Analyze
                            </TextSpan>
                        </a>
                    </NavButton>

                    <ComingNavButton message="Coming Soon" sidebar={true}>
                        <div>
                            <User />
                            <TextSpan sidebar={true}>
                                Profile
                            </TextSpan>
                            <ComingSoonSpan>Coming Soon</ComingSoonSpan>
                        </div>
                    </ComingNavButton>

                    <ComingNavButton message="Coming Soon" sidebar={true}>
                        <div>
                            <Chart />
                            <TextSpan sidebar={true}>
                                Presales
                            </TextSpan>
                            <ComingSoonSpan>Coming Soon</ComingSoonSpan>
                        </div>
                    </ComingNavButton>

                    <ComingNavButton message="Coming Soon" sidebar={true}>
                        <div>
                            <Rocket />
                            <TextSpan sidebar={true}>
                                Launchpad
                            </TextSpan>
                            <ComingSoonSpan>Coming Soon</ComingSoonSpan>

                        </div>
                    </ComingNavButton>

                    <ComingNavButton message="Coming Soon" sidebar={true}>
                        <div>
                            <Search />
                            <TextSpan sidebar={true}>
                                Auditing
                            </TextSpan>
                            <ComingSoonSpan>Coming Soon</ComingSoonSpan>
                        </div>
                    </ComingNavButton>

                </LinkContainer1>
                <LinkContainer1>
                    <LinkContainer2 open={chain}>
                        <BasicLink >
                            <a href="https://docs.tokenexpress.io" target="_blank" rel={'noreferrer'}>

                                <TextSpan sidebar={enabled}>
                                    Docs
                                </TextSpan>
                            </a>
                        </BasicLink>
                        <BasicLink >
                            <a href="https://t.me/TokenExpress" target="_blank" rel={'noreferrer'}>

                                <TextSpan sidebar={enabled}>
                                    Telegram
                                </TextSpan>
                            </a>
                        </BasicLink>

                        <BasicLink>
                            <a href='/disclaimer'>
                                <TextSpan sidebar={enabled}>
                                    Disclaimer
                                </TextSpan>
                            </a>
                        </BasicLink>

                        <BasicLink >
                            <a href="https://twitter.com/thetokenexpress" target="_blank" rel={'noreferrer'}>

                                <TextSpan sidebar={enabled}>
                                    Twitter
                                </TextSpan>
                            </a>
                        </BasicLink>
                        <BasicLinkVersion>
                            <div>
                                <TextSpan sidebar={enabled}>
                                    Version 1.0
                                </TextSpan>
                            </div>
                        </BasicLinkVersion>


                    </LinkContainer2>
                </LinkContainer1>
                <LinkContainer3>
                    <Wallet open={chain}
                        sidebar={enabled}
                        onClick={() => handleConnect(!!address)}
                    >
                        {!!address && (
                            provider === 'injected'
                                ?
                                <MetaMask></MetaMask>
                                :
                                <WalletConnect></WalletConnect>
                        )
                        }

                        {!address ? 'Connect Wallet' : networkType !== preferredNetwork.networkType ? 'Switch Network' : getAccountString(address)}
                    </Wallet>
                    <TopPriceContainer onClick={() => handleChain()} active={chain} sidebar={enabled}>
                        <ChainContainer sidebar={enabled} active={chain}>
                            <ChooseChain sidebar={enabled}>
                                <Binance></Binance>
                                <ChainText sidebar={enabled}>BNB CHAIN</ChainText>
                            </ChooseChain>
                            <ChooseChainGrey message='Coming Soon' sidebar={enabled}>
                                <Ethereum></Ethereum>
                                <ChainText sidebar={enabled}>Ethereum</ChainText>
                                <span>Coming Soon</span>
                            </ChooseChainGrey>
                            <ChooseChainGrey message='Coming Soon' sidebar={enabled}>
                                <Polygon></Polygon>
                                <ChainText sidebar={enabled}>Polygon</ChainText>
                                <span>Coming Soon</span>
                            </ChooseChainGrey>
                            <ChooseChainGrey message='Coming Soon' sidebar={enabled}>
                                <Avalanche></Avalanche>
                                <ChainText sidebar={enabled}>Avalanche</ChainText>
                                <span>Coming Soon</span>
                            </ChooseChainGrey>
                        </ChainContainer>

                        <BinanceContainer sidebar={enabled}>
                            <ChainLogoContainer onClick={() => handleChain()}>

                                <Binance />
                            </ChainLogoContainer>
                            <Chainspan sidebar={enabled}>BNB CHAIN</Chainspan>
                        </BinanceContainer>
                        <PillContainer sidebar={enabled}>
                            <PriceContainer sidebar={chain}>
                                <BNBspan sidebar={enabled}>
                                    BNB:&nbsp;
                                </BNBspan>
                                {
                                    !!bnbPrice ? (
                                        <span>${bnbPrice.toFixed(0)}</span>
                                    ) : (
                                        <span>
                                            <Loader
                                                type="Oval"
                                                color="#0B0E13"
                                                height={'0.9rem'}
                                                width={'0.9rem'}
                                                visible={true}
                                            />
                                        </span>
                                    )
                                }
                            </PriceContainer>
                            {
                                chain ?
                                    <TriangleDown></TriangleDown>
                                    :
                                    <TriangleUp></TriangleUp>
                            }
                        </PillContainer>
                    </TopPriceContainer>

                </LinkContainer3>
            </NavigationHero>

        </NavigationContainer >
    )
}

const mapStateToProps = (state: RootState) => ({
    selectedToken: state.interface.selectedToken,
    bnbPrice: state.interface.BNBPrice,
    networkType: state.wallet.networkType,
    provider: state.wallet.provider,
    address: state.wallet.account,
    balance: state.wallet.balances.bnb,


});

export default connect(
    mapStateToProps,
    dispatchProps
)(withRouter(Navigation));