import { createReducer } from 'typesafe-actions';
import { initialState } from './state';
import * as pairsActions from './actions';
import { PairInterface } from '../../types/pairs';

const extractTrending = (pairs: PairInterface[]): PairInterface[] =>
    pairs.filter((pair) => !pair.indicators.isRug).sort(
        (a, b) => {
            const aSwaps = a.indicators.buys + a.indicators.sells;
            const bSwaps = b.indicators.buys + b.indicators.sells;
            return aSwaps > bSwaps ? -1 : 1;
        }
    ).slice(0, 3);

export default createReducer(initialState)
    .handleAction(
        pairsActions.initDashboard,
        (state, action) => {
            const dashboardPairs = action.payload;
            const trendingPairs = extractTrending(dashboardPairs);
            return {
                ...state,
                dashboardPairs,
                trendingPairs
            }
        }
    ).handleAction(
        pairsActions.addPair,
        (state, action) => {
            return {
                ...state,
                dashboardPairs: state.dashboardPairs.concat(action.payload)
            };
        }
    ).handleAction(
        pairsActions.updatePair,
        (state, action) => {
            const newDashboardPairs = state.dashboardPairs.reduce(
                (acc: PairInterface[], pair) => {
                    return pair.address === action.payload.address ? acc.concat(action.payload) : acc.concat(pair);
                },
                []
            );
            const newTrendingPairs = extractTrending(newDashboardPairs);
            return {
                ...state,
                dashboardPairs: newDashboardPairs,
                trendingPairs: newTrendingPairs
            };
        }
    ).handleAction(
        pairsActions.removePair,
        (state, action) => {
            const pair = action.payload;
            const newDashboardPairs = state.dashboardPairs.filter(({ address }) => address !== pair.address);
            const newTrendingPairs = extractTrending(newDashboardPairs);
            return {
                ...state,
                dashboardPairs: newDashboardPairs,
                trendingPairs: newTrendingPairs
            };
        }
    )
    .handleAction(
        pairsActions.updateDashboard,
        (state, action) => {
            const { newPairs, updatedPairs, removedPairs, trending } = action.payload;
            const removed = removedPairs.map(({ address }) => address);
            const updated = updatedPairs.reduce(
                (acc: PairInterface[], pair) => {
                    return acc.map(
                        (op) => op.address === pair.address ? {
                            ...op,
                            ...pair
                        } : op
                    );
                },
                state.dashboardPairs
            );

            const dashboardPairs = newPairs.concat(updated).filter(({ address }) => !removed.includes(address));

            const trendingPairs = (trending || []).reduce(
                (acc: PairInterface[], pair) => {
                    return acc.map(
                        (op) => op.address === pair.address ? {
                            ...op,
                            ...pair
                        } : op
                    );
                },
                state.trendingPairs
            );

            return {
                ...state,
                dashboardPairs,
                trendingPairs,
            }
        }
    ).handleAction(
        pairsActions.updateCustomPairs,
        (state, action) => {
            const updatedPairs = action.payload;
            const updatedCustomPairs = updatedPairs.reduce(
                (acc: PairInterface[], pair: PairInterface) => {
                    if (!acc.find(({ address }) => address === pair.address)) {
                        return acc.concat({ ...pair });
                    } else {
                        return acc.map((oldPair) => oldPair.address === pair.address ? { ...oldPair, ...pair } : oldPair);
                    }
                },
                state.customPairs
            );
            return {
                ...state,
                customPairs: updatedCustomPairs,
            }
        }
    );