import { createAction } from 'typesafe-actions';

import { PairInterface } from '../../types/pairs';
import { DashboardUpdate } from '../../types/updates';

export const handleMessage = createAction('HANDLE_MESSAGE')<{ type: string, data: any }>();

export const initDashboard = createAction('INIT_DASHBOARD')<PairInterface[]>();

export const updatePair = createAction('UPDATE_PAIR')<PairInterface>();

export const addPair = createAction('ADD_PAIR')<PairInterface>();

export const removePair = createAction('REMOVE_PAIR')<PairInterface>();

export const updateDashboard = createAction('UPDATE_DASHBOARD')<DashboardUpdate>();

export const updateCustomPairs = createAction('UPDATE_CUSTOM_PAIRS')<PairInterface[]>();