import React from 'react';
import styled from 'styled-components/macro';

//import DropdownArrowIcon from './ui/icons/drowdown-arrow';
import ShowMore from './ui/animated/show-more';
import { ReactComponent as TriangleDown } from './svg/triangledown.svg';

const QuestionContainer = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.uiColor6};
    cursor: pointer;
    border-radius: 0.5rem;
    margin-top: 1rem;
    ${({ theme }) => theme.media.smallUp} {
        display: none;
    } 
    
`;

export const NoSelectDiv = styled.div`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const TitleContainer = styled(NoSelectDiv)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;
`;

const AlignLeftContainer = styled(NoSelectDiv)`
    display: flex;
    justify-content: left;
`;

interface ReadMoreProps {
    active: boolean;
}

const ReadMoreContainer = styled(ShowMore)<ReadMoreProps>`
    padding: 0 1rem;
    
`;


/* const Arrow = styled(DropdownArrowIcon)<ReadMoreProps>`
    margin-left: 1rem;
    
`; */

const ArrowDiv = styled(NoSelectDiv)<ReadMoreProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: bottom-right;
    transform: rotate(${({ active }) => (active ? '180deg' : '0deg')});
    transition: transform 0.3s ${({ theme }) => theme.transitions.main};
    margin-left: 0.5rem;
`;

interface Props {
    children?: React.ReactNode;
    className?: string;
    ref?: React.Ref<HTMLDivElement>;
    title: string;
}

export const Question: React.FC<Props> = ({
    children,
    className,
    ref,
    title,
}) => {
    const [readMore, setReadMore] = React.useState(false);

    const handleClick = () => {
        setReadMore(!readMore);
    };

    return (
        <QuestionContainer onClick={handleClick} className={className} ref={ref}>
            <TitleContainer>
                <AlignLeftContainer>
                    <span>{title}</span>
                </AlignLeftContainer>
                <ArrowDiv active={readMore}>
                    <TriangleDown />
                </ArrowDiv>
            </TitleContainer>
            <ReadMoreContainer active={readMore} calculate>{children}</ReadMoreContainer>
        </QuestionContainer>
    );
};

export default React.forwardRef(
    (props: Props, ref: React.Ref<HTMLDivElement>) => (
        <Question {...props} ref={ref as any} />
    )
);
