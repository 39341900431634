import BN from 'bn.js';

const denominatorFromDecimals = (decimals: number): string => {
    return Array(decimals).fill('0').reduce((d, v) => d + v, '1');
}

export const toWeiCustomDecimals = (amount: string, decimals: number) => {
    const newAmount = toWei(amount, denominatorFromDecimals(decimals));
    return newAmount;
}

function toWei(ether: string, baseUnit: string) {
    var negative = new BN(ether).lt(zero);

    const baseLength = baseUnit.length - 1 || 1;

    const base = new BN(baseUnit, 10);

    // Is it negative?
    var negative = (ether.substring(0, 1) === '-'); // eslint-disable-line
    if (negative) {
        ether = ether.substring(1);
    }

    // Split it into a whole and fractional part
    var comps = ether.split('.');
    if (comps.length > 2) { throw new Error(`[ethjs-unit] while converting number ${ether} to wei,  too many decimal points`); }

    let whole = comps[0];
    let fraction = comps[1];

    if (!whole) { whole = '0'; }
    if (!fraction) { fraction = '0'; }
    if (fraction.length > baseLength) { throw new Error(`[ethjs-unit] while converting number ${ether} to wei, too many decimal places`); }

    while (fraction.length < baseLength) {
        fraction += '0';
    }

    let newWhole = new BN(whole);
    let newFraction = new BN(fraction);
    var wei = (newWhole.mul(base)).add(newFraction); // eslint-disable-line

    if (negative) {
        wei = wei.mul(negative1);
    }

    return new BN(wei.toString(10), 10);
}

export const fromWeiCustomDecimals = (amount: string, decimals: number) => {
    const newAmount = fromWei(new BN(amount), denominatorFromDecimals(decimals));
    return newAmount;
}

const zero = new BN(0);
const negative1 = new BN(-1);

function fromWei(wei: BN, baseUnit: string): string {
    var negative = wei.lt(zero); // eslint-disable-line

    const baseLength = baseUnit.length - 1 || 1;

    const base = new BN(baseUnit, 10);

    if (negative) {
        wei = wei.mul(negative1);
    }

    var fraction = wei.mod(base).toString(10); // eslint-disable-line

    while (fraction.length < baseLength) {
        fraction = `0${fraction}`;
    }

    var whole = wei.div(base).toString(10); // eslint-disable-line

    var value = `${whole}${fraction == '0' ? '' : `.${fraction}`}`; // eslint-disable-line

    if (negative) {
        value = `-${value}`;
    }

    return value;
}

export const getDays = (amount: number = 5): number[] => {
    const lastMidnight = new Date().setUTCHours(0, 0, 0, 0) / 1000;
    return Array(amount).fill(0).map((_, i: number) => lastMidnight + i * 86400)
}

export const simplify = (amount: string, decimals?: number) => {
    const dot = amount.indexOf('.')
    const newAmount = dot >= 0 ? amount.substring(0, amount.indexOf('.')) : amount;
    const shorten = newAmount.length - 1;
    let symbol = '';
    let value = 0;
    if (shorten >= 18) {
        value = 18;
        symbol = ' quintill';
    } else if (shorten >= 15) {
        value = 15;
        symbol = ' quadrill';
    } else if (shorten >= 12) {
        value = 12;
        symbol = ' trill';
    } else if (shorten >= 9) {
        value = 9;
        symbol = ' bill';
    } else if (shorten >= 6) {
        value = 6;
        symbol = ' mill';
    } else if (shorten >= 3) {
        value = 3;
        symbol = 'K';
    }
    if (value === 0) {
        if (dot >= 0) {
            let add = '.';
            if (decimals) {
                add += amount.slice(dot + 1, dot + 1 + decimals);    
            } else {
                add += `${amount[dot + 1]}`;
            }
            return newAmount + add;
        }
        return amount;
    }
    const chars = newAmount.split('');
    //const adds = dot >= 0 ? amount[dot + 1] !== '0' ? `.${amount[dot + 1]}` : '' : '';
    const add = value > 0 ? `.${chars.slice(newAmount.length - value, newAmount.length - value + (decimals ? decimals : 1)).join('')}` : '';
    const used = chars.slice(0, newAmount.length - value);
    const refined = used.reduce((a, c) => a + c) + add + symbol;
    return refined;
}

export const estimateInputBuy = (fromReserve: BN, toReserve: BN, buyTax: BN, toAmount: BN) => {
    const R = new BN(9975);
    const Rbt = new BN(10000).sub(buyTax);
    const num = fromReserve.mul(toAmount);
    const den = R.mul((Rbt.mul(toReserve)).sub(toAmount)).div(new BN(10000000));
    return num.div(den);
}

export const estimateInputSell = (fromReserve: BN, toReserve: BN, sellTax: BN, toAmount: BN) => {
    const R = new BN(9975);
    const Rst = new BN(10000).sub(sellTax);
    const num = fromReserve.mul(toAmount);
    const den = R.mul(Rst).mul(toReserve.sub(toAmount)).div(new BN(10000000));
    return num.div(den);
}

export const estimateOutputBuy = (fromReserve: BN, toReserve: BN, buyTax: BN, fromAmount: BN) => {
    const R = new BN(9875); // 1% PCE & 0.25% LP
    const Rbt = new BN(10000).sub(buyTax);
    const num = Rbt.mul(R).mul(fromAmount).mul(toReserve);
    const den = new BN(100000000).mul(fromReserve).add(R.mul(fromAmount).mul(new BN(10000)));
    return num.div(den);
}

export const estimateOutputSell = (fromReserve: BN, toReserve: BN, sellTax: BN, fromAmount: BN) => {
    const R = new BN(9875); // 1% PCE & 0.25% LP
    const Rst = new BN(10000).sub(sellTax);
    const num = R.mul(Rst).mul(fromAmount).mul(toReserve);
    const den = new BN(100000000).mul(fromReserve).add(R.mul(Rst).mul(fromAmount));
    return num.div(den);
}