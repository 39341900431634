import { WalletState, initialState as walletState } from './wallet/state';
import { PairsState, initialState as pairsState } from './pairs/state';
import { InterfaceState, initialState as interfaceState } from './interface/state';
import { AnalyzerState, initialState as analyzerState } from './analyze/state';
import { SocketState, initialState as socketState } from './socket/state';

export default interface RootState {
    wallet: WalletState;
    pairs: PairsState;
    interface: InterfaceState;
    analyze: AnalyzerState;
    socket: SocketState;
}

export const initialState: RootState = {
    wallet: walletState,
    pairs: pairsState,
    interface: interfaceState,
    analyze: analyzerState,
    socket: socketState,
}