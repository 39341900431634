import { createGlobalStyle } from 'styled-components/macro';
import { Theme } from './theme';
import GraphikBlack from "../fonts/Graphik-Black.otf";
import GraphikBlackItalic from "../fonts/Graphik-BlackItalic.otf";
import GraphikBold from "../fonts/Graphik-Bold.otf";
import GraphikBoldItalic from "../fonts/Graphik-BoldItalic.otf";
import GraphikExtraLight from "../fonts/Graphik-Extralight.otf";
import GraphikExtraLightItalic from "../fonts/Graphik-ExtralightItalic.otf";
import GraphikLight from "../fonts/Graphik-Light.otf";
import GraphikLightItalic from "../fonts/Graphik-LightItalic.otf";
import GraphikMedium from "../fonts/Graphik-Medium.otf";
import GraphikMediumItalic from "../fonts/Graphik-MediumItalic.otf";
import GraphikRegular from "../fonts/Graphik-Regular.otf";
import GraphikRegularItalic from "../fonts/Graphik-RegularItalic.otf";
import GraphikSemiBold from "../fonts/Graphik-Semibold.otf";
import GraphikSemiBoldItalic from "../fonts/Graphik-SemiboldItalic.otf";
import GraphikThin from "../fonts/Graphik-Thin.otf";
import GraphikThinItalic from "../fonts/Graphik-ThinItalic.otf";



export interface ThemeWrapper {
    theme: Theme;
}



// Defining global styles which are injected in _app.tsx
export const GlobalStyle = createGlobalStyle<ThemeWrapper>`

@font-face {
    font-family: Graphik;
    src: url(${GraphikRegular}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikRegularItalic}) format('opentype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikBlack}) format('opentype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikBlackItalic}) format('opentype');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikBold}) format('opentype');
    font-weight: 500;
    font-style: normak;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikBoldItalic}) format('opentype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikExtraLight}) format('opentype');
    font-weight: 250;
    font-style: normal;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikExtraLightItalic}) format('opentype');
    font-weight: 250;
    font-style: italic;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikLight}) format('opentype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikLightItalic}) format('opentype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikMedium}) format('opentype');
    font-weight: 350;
    font-style: normal;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikMediumItalic}) format('opentype');
    font-weight: 350;
    font-style: italic;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikSemiBold}) format('opentype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikSemiBoldItalic}) format('opentype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikThin}) format('opentype');
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: Graphik;
    src: url(${GraphikThinItalic}) format('opentype');
    font-weight: 200;
    font-style: italic;
  }

    * {
        box-sizing: border-box;
    }

    html {
        scroll-behavior: smooth;
        font-size: calc(6px + 1.5vmin);
        ${({ theme }) => theme.media.smallUp} {
            font-size: 16px;
        }
    }
    
    body {
        position: relative;
        overflow-x: hidden;
        margin: 0;
        padding: 0;
        font-family: ${({ theme }) => theme.fonts.main};
        font-weight: 400;
        background-color: ${({ theme }) => theme.colors.uiColor1};
        color: ${({ theme }) => theme.colors.offWhite};
    }

    button {
        font-size: inherit;
        font-family: inherit;
        border: 0;
        padding: 0;
    }

    a, a: visited, a: hover, a: focus {
        text-decoration: none;
        color: inherit;
    }

    .web3modal-provider-container {
        font-family: ${({ theme }) => theme.fonts.main} !important;
        background-color: #161C24;
        position: relative;
        
        
    }

    .walletconnect-modal__base {
      font-family: ${({ theme }) => theme.fonts.main} !important;
      background-color: #161C24 !important;
      position: relative;
  }

  .walletconnect-modal__header{

    & p {
      font-weight: 500 !important;
  }
}
    
    .web3modal-provider-icon {
      position: absolute;
      right: 2rem;
  }

  .web3modal-provider-name {
    text-align: left;
    margin-left: 1rem;
    font-weight: 500;
    font-size: 1.25rem;
}

.web3modal-provider-description {
  text-align: left;
  margin-left: 1rem;
  font-size: 1rem;
}

#walletconnect-qrcode-modal > div > div.walletconnect-modal__mobile__toggle > a:nth-child(2){
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 0.75rem;
}

#walletconnect-qrcode-modal > div > div.walletconnect-modal__mobile__toggle > a:nth-child(3){
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 0.75rem;
}

#walletconnect-qrcode-modal > div > div.walletconnect-modal__mobile__toggle > div{
  background: ${({ theme }) => theme.colors.brandColor1};
}

#walletconnect-qrcode-modal > div > div.walletconnect-modal__mobile__toggle{
  background: ${({ theme }) => theme.colors.uiColor3};
}

#walletconnect-qrcode-modal > div > div:nth-child(3) > div > div.walletconnect-qrcode__notification{
  background-color: #161C24 !important;
  color: white;
}

#walletconnect-qrcode-text{
  color: white;
  font-weight: 500;
}

#walletconnect-qrcode-modal > div > div:nth-child(3) > div > div{
  & a {
    & div {
      color: white;
      font-weight: 500;
    }
  }
}



#walletconnect-qrcode-modal > div > div:nth-child(3) > div > div.walletconnect-modal__footer > a{
  color: white;
}

#walletconnect-qrcode-modal {
  background: ${({ theme }) => theme.colors.uiColor1};
}

#walletconnect-qrcode-modal {
  background: ${({ theme }) => theme.colors.uiColor1};
  
}

#walletconnect-qrcode-modal > div > div.walletconnect-modal__header > div {
  background: ${({ theme }) => theme.colors.uiColor1};
  border: 1px solid ${({ theme }) => theme.colors.uiColor2};
  width: 2rem;
  height: 2rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

#walletconnect-qrcode-close{
  background: ${({ theme }) => theme.colors.uiColor1};
  height: 100%;
  width: 100%;
  margin-bottom: 0.9rem;

  color: white;
}

#walletconnect-qrcode-close > div.walletconnect-modal__close__line1{
  border: 1px solid white;
}
#walletconnect-qrcode-close > div.walletconnect-modal__close__line2{
  border: 1px solid white;
}





    .web3modal-modal-card {
      border: 1px solid #29313E;
      padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1.75rem;
    padding-top: 1.75rem;
    &::before {
      content: 'Connect a wallet';
      font-size: 1.5rem;
      font-weight: 500;
      text-align: left;
      margin-top: 0.1rem;
      margin-bottom: 1.5rem;
      margin-left: 0.8rem;
    }
  }

  .web3modal-provider-wrapper {
    width: 631px;
    max-width: 85vw;
}

    .web3modal-modal-hitbox {
      background-color: transparent;
  }

  .web3modal-modal-lightbox {
    background-color: transparent;
    background: rgba(11, 14, 19, 0.69);
    backdrop-filter: blur(11px);
    z-index: 7;
}

.web3modal-modal-container {
  background-color: transparent;
}
    #walletconnect-wrapper {
        color: black;
    }
`