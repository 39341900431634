import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
//import Spline from '@splinetool/react-spline';

import * as walletActions from '../../store/wallet/actions';
import * as interfaceActions from '../../store/interface/actions';

import { ReactComponent as Logo} from '../svg/popuplogo.svg';
import { ReactComponent as Close} from '../svg/close.svg';
import SignupComponent from '../signup';



const HeroModal = styled.div<{enabled:boolean}>`
font-size: 1rem;;
--animation-duration: 300ms;
font-weight: 400;
box-sizing: border-box;
transition: opacity 0.1s ease-in-out 0s;
text-align: center;
position: fixed;
width: 100vw;;
height: 100vh;
margin-left: -50vw;
top: 0px;
left: 50%;
z-index: 10;
will-change: opacity;
background: rgba(11, 14, 19, 0.69);
backdrop-filter: blur(11px);
opacity: ${({ enabled }) => enabled ? '1' : '0'};
visibility: visible;
pointer-events: auto;
display: ${({ enabled }) => enabled ? 'flex' : 'none'};
-webkit-box-pack: center;
justify-content: center;
-webkit-box-align: center;
align-items: center;
`

const ModalHitbox = styled.div`
font-size: 1rem;

font-weight: 400;

text-align: center;
visibility: visible;
pointer-events: auto;
box-sizing: border-box !important;
position: absolute;
inset: 0px;
`

const ModalContainer= styled.div`
    display: flex;
    position: relative;
    
    max-width: 68.125rem;
    background-color: ${({theme}) => theme.colors.uiColor1};
    border: 1px solid ${({theme}) => theme.colors.uiColor2};
    border-radius: 1rem;
    z-index: 5;
    ${({ theme }) => theme.media.small} {
        height: min-content;
        width: 90vw;
        flex-direction: column;
    }
`

const MoonButton = styled.div`
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:active {
        transform: translateY(2px);
    }
    width: 2.8rem;
    height: 2.8rem;
    color:  ${({theme}) => theme.colors.uiColor5};
    margin-left: 0.625rem;
    border-radius: 3.75rem;
    border: 1px solid ${({theme}) => theme.colors.uiColor2};
    transition: all 0.2s, border 0s;

    & svg {
        width: 1rem;
        height: 1rem;
    }

    &:hover {
        border: 1px solid ${({theme}) => theme.colors.uiColor5};
    }

    ${({ theme }) => theme.media.small} {
        width: 2.1rem;
        height: 2.1rem;
        & svg {
            width: 0.85rem;
            height: 0.85rem;
        }
    }
`;

/* const SplineContainer= styled.div`
    
    margin-top: 3.625rem;
    & div {
        & canvas {
            width: 250px !important;
            height: 250px !important;
        }
    }
    margin-left: 2rem;
    ${({ theme }) => theme.media.small} {
       margin-left: 0rem;
       align-self: center;
       margin-top: 2rem;
       & div {
        & canvas {
            width: 160px !important;
            height: 160px !important;
        }
    }
    }
` */

const RightText = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 4rem;
    margin-top: 3.625rem;
    margin-right: 3rem;
    ${({ theme }) => theme.media.small} {
       
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: 1rem;
        margin-right: 1rem;

     }
    
`

const Header = styled.div`
    font-size: 2rem;
    font-weight: 500;
    text-align: left;
    ${({ theme }) => theme.media.small} {
       
       text-align: center;

     }
`

const Paragraphcontainer = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 170%;
    text-align: left;
    margin-top: 1.6rem;
    width: 100%;
   
    ${({ theme }) => theme.media.small} {
       
        width: 100%;
        text-align: center;

     }
`

const Paragraphcontainer2 = styled.div`
    font-weight: 500;
    font-size: 1rem;
    line-height: 170%;
    text-align: left;
    margin-top: 1.6rem;
    width: 100%;
    ${({ theme }) => theme.media.small} {
       
        width: 100%;
        text-align: center;

     }
`

const LogoContainer = styled.div`

    margin-left: 4rem;
    margin-top: 5rem;
    
    ${({ theme }) => theme.media.small} {
        margin-left: 0rem;
        margin-top: 2rem;
    
    }
`

const ButtonContainer = styled.div`
    display: flex;
    margin-bottom: 4rem;
`

const ExpressButton = styled.div`
    margin-top: 2rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    margin-right: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.uiColor1};

    & svg {
        width: 1rem;
        height: 1rem;
        margin-top: 0.1rem;
    }

    background-color: ${({ theme }) => theme.colors.brandColor1};
    width: 16rem;
    height: 3.4rem;
    ${({ theme }) => theme.media.small} {
        width: 100%;
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.uiColor5};
        box-shadow: ${({ theme }) => theme.colors.shadow1};
      } 

    
`;

const SignupButton = styled.div`
    margin-top: 2rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    
    border: 1px solid ${({theme}) => theme.colors.uiColor2};


    & svg {
        width: 1rem;
        height: 1rem;
        margin-top: 0.1rem;
    }

    background-color: ${({ theme }) => theme.colors.uiColor1};
    width: 16rem;
    height: 3.4rem;
    ${({ theme }) => theme.media.small} {
        width: 100%;
    }
    &:hover {
        border: 1px solid ${({theme}) => theme.colors.brandColor1};
        color: ${({ theme }) => theme.colors.brandColor1};
      } 

    
`;






const dispatchProps = {
    getBNBPrice: interfaceActions.getBNBPrice.request,
    connectWallet: walletActions.connectWallet.request,
    disconnectWallet: walletActions.disconnectWallet.request,
    switchNetwork: walletActions.switchNetwork.request,
};



type Props = {
   enabled: boolean;
   handlePopup: () => void;
} & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Popup: React.FC<Props> = ({enabled, handlePopup, address, disconnectWallet, connectWallet, provider}) => {


        const [signUp, setSignup] = useState(false);
    
        return (
            <HeroModal enabled={enabled}>
                <ModalHitbox onClick={handlePopup}>
                </ModalHitbox>
                <ModalContainer>
                <MoonButton onClick={handlePopup}><Close></Close></MoonButton>
                <LogoContainer>
                    <Logo></Logo>
                </LogoContainer>
                {
                    signUp ?
                    <RightText>
                    <Header>Stay informed! </Header>
                    <Paragraphcontainer>Sign up for our newsletter and get early access to new features, information about our upcoming presale and much more. We promise not to spam you nor sell your email to some evil corporation.</Paragraphcontainer>
                    <SignupComponent enabled={true}></SignupComponent>
                    </RightText>
                    :
                    <RightText>
                    <Header>Welcome to our Beta</Header>
                    <Paragraphcontainer>This is the Beta version of our app. There might be bugs, errors or something else that could effect your experience. Please use our feedback button in the right corner to report bugs and/or give us feedback.</Paragraphcontainer>
                    <Paragraphcontainer>We do not take responsibility with faulty scams, since there are new kind of scams coming in every day, so invest with care and caution.</Paragraphcontainer>
                    <Paragraphcontainer2>Thank you for your patience!</Paragraphcontainer2>
                    <ButtonContainer>
                    <ExpressButton onClick={handlePopup}>LET'S GO</ExpressButton>
                    <SignupButton  onClick={() => {
                            setSignup(!signUp);
                        }}>Sign up for updates</SignupButton>
                    </ButtonContainer>
                    </RightText>
                }
               
                
                
                
                </ModalContainer>
            </HeroModal>
        )
    }


const mapStateToProps = (state: RootState) => ({
    address: state.wallet.account,
    balance: state.wallet.balances.bnb,
    networkType: state.wallet.networkType,
    bnbPrice: state.interface.BNBPrice,
    provider: state.wallet.provider
});

export default connect(
    mapStateToProps,
    dispatchProps
)(Popup);