import Web3 from 'web3';
import { AbiInput, AbiItem } from "web3-utils";

import { Events, InputsAndSignature } from '../types/contract';

const web3 = new Web3();

export const getInputsAndSignature = (abi: AbiItem[]): Events => {
    let events: Events = {}

    abi.filter(v => v.type === 'event').forEach((item) => {
        const inputs = item.inputs;
        const signature = web3.eth.abi.encodeEventSignature(item);
        events[item.name as string] = {
            inputs,
            signature
        }
    })

    return events;
}

export const decodeRawEventLog = (log: { topics: string[], data: string }, event: InputsAndSignature) => {
    const filteredTopics = log.topics.filter((t) => !t.startsWith(event.signature));
    const result = web3.eth.abi.decodeLog(
        event.inputs as AbiInput[],
        log.data,
        filteredTopics,
    );
    return result;
}