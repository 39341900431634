import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { setFilter } from '../store/interface/actions';

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    ${({ theme }) => theme.media.small} {
        padding: 0 1rem;
        display: none;
    }
`;

const SearchInput = styled.input.attrs({ type: 'text' })`
    height: 2.8rem;
    padding: 0.7rem 3rem;
    font-size: 0.875rem;
    width: 75%;
   
    color: ${({ theme }) => theme.colors.uiColor5};
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    box-sizing: border-box;
    border-radius: 0.5rem;
    background-color: transparent;
    background-image: url(/images/search.svg);
    background-repeat: no-repeat;
    background-position: 1rem 0.9rem;
    font-family: ${({ theme }) => theme.fonts.main};

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme }) => theme.colors.uiColor4};
        opacity: 1; /* Firefox */
        font-size: 0.875rem;
        font-family: ${({ theme }) => theme.fonts.main}
      }
      &:focus { outline: none;
        border: 1px solid ${({ theme }) => theme.colors.uiColor5}; }
`;

interface Props {
}


export const SearchBar: React.FunctionComponent<Props> = () => {
    const dispatch = useDispatch();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.currentTarget.value;
        dispatch(setFilter({ filter: 'searchParameter', value: query }));
    }

    return (
        <SearchContainer>
            <SearchInput
                onChange={handleChange}
                placeholder='Filter by name or symbol'
            />
        </SearchContainer>
    )
}

export default SearchBar;