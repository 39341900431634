import { combineReducers } from 'redux';

import walletReducer from './wallet/reducer';
import pairsReducer from './pairs/reducer';
import interfaceReducer from './interface/reducer';
import analyzerReducer from './analyze/reducer';
import socketReducer from './socket/reducer';

export default combineReducers({
    wallet: walletReducer,
    pairs: pairsReducer,
    interface: interfaceReducer,
    analyze: analyzerReducer,
    socket: socketReducer,
});
