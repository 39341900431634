import React from 'react';
import styled from 'styled-components/macro';
import Layout from '../components/layout/layout';


const FrontPageContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0rem 0rem;
    color: ${({theme}) => theme.colors.uiColor5};
    margin-top: 2rem;
    padding-bottom: 10rem;
`;

const Title = styled.div`
    font-weight: 400;
    font-size: 5rem;
    margin-left: 5rem;
    margin-top: 2rem;
    ${({ theme }) => theme.media.small} {
        font-size: 3.5rem;
        margin-left: 2rem;
        margin-top: 0rem;
  }
`;

const SubTitle = styled.div`
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color:  ${({theme}) => theme.colors.brandColor1};
    margin-left: 5.35rem; 
    margin-top: 4rem;
    ${({ theme }) => theme.media.small} {
        margin-left: 2rem;
        margin-top: 3rem;
  }
`;

const Advisors = styled.div`
    font-weight: 500;
    font-size: 2rem;
    margin-left: 5.35rem; 
    margin-top: 4rem;
    ${({ theme }) => theme.media.small} {
        font-size: 1.5rem;
        margin-left: 2rem;
        margin-top: 3rem;
  }
`;

const Paragraphcontainer = styled.p`
font-weight: 400;
font-size: 1.5rem;
line-height: 150%;
margin-left: 5.35rem;
width: 62.5rem;
${({ theme }) => theme.media.small} {
    font-size: 1.2rem;
    margin-left: 2rem;
    margin-top: 3rem;
    width: 26rem;
}
`;

interface Props {

}

interface State {

}

export default class DisclaimerPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {

        }
    }

    render() {
        return (
            <Layout>
                <FrontPageContainer>
                <Title>Disclaimer</Title>
          <SubTitle>Updated: April 7, 2022</SubTitle>
          <Advisors>We are not financial advisors.</Advisors>
          <Paragraphcontainer>The content on this website is for informational purposes only, you should not
                            construe any such information or other material as legal, tax, investment,
                            financial, or other advice.All content on this site is information of a general nature and does not address
the circumstances of any particular individual or entity. Nothing in the site
constitutes professional and/or financial advice, nor does any information on the
site constitute a comprehensive or complete statement of the matters discussed
or the law relating thereto.
</Paragraphcontainer>
          <Paragraphcontainer>In order to make the best financial decision that suits your own needs, you must
conduct your own research and seek the advice of a licensed financial advisor if
necessary.
          Know that there are risks associated with investing in cryptocurrency. Loss of
principal is possible. Foreign investing involves special risks, including a greater
volatility and political, economic and currency risks and differences in accounting
methods.
</Paragraphcontainer>
          <Paragraphcontainer>There is no guarantee that you will be successful in making, saving, or investing
money nor is there any guarantee that you won&apos;t experience any loss when
investing. Always remember to make smart decisions and do your own research!
</Paragraphcontainer>
          <Advisors>Token Express team</Advisors>
        
          </FrontPageContainer>

            </Layout>
        );
    }
}