import * as React from 'react';

import styled from 'styled-components/macro';



const SearchContainer = styled.div`
    display: flex;
    align-items: center;
`;

interface SubmitProps {
    enabled: boolean;
  }

const SearchInput = styled.input.attrs({ type: 'text' })<{dark: boolean}>`
    width: 25rem;
    height: 3rem;
    padding: 0.7rem 1rem;
    font-size: 16px;
    
    color: ${({ theme }) => theme.colors.uiColor5};
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    box-sizing: border-box;
    border-radius: 0.5rem;
    background-color: transparent;
    font-family: ${({ theme }) => theme.fonts.main};

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${({ theme }) => theme.colors.uiColor4};
        opacity: 1; /* Firefox */
        font-size: 0.875rem;
        font-family: ${({ theme }) => theme.fonts.main}
      }

      &:focus { outline: none;
        border: 2px solid ${({ theme }) => theme.colors.uiColor5}; }
`;

const SignupContainer = styled.div<SubmitProps>`
  display: ${({ enabled }) => enabled ? 'none' : 'flex'};
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 4rem;
  ${({ theme }) => theme.media.small} {
    flex-direction: column;
    justify-content: center;                             
}
`;

const SignupButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.25rem 1rem 1.25rem;
  border-radius: 0.5rem;
  background-color: ${({theme}) => theme.colors.brandColor1};
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  color:${({ theme }) => theme.colors.uiColor1};
  width: 14rem;
  height: 3rem;
  cursor: pointer;
  margin-right: 3.75rem;
  margin-left: 1rem;
  &:hover {
    background-color: ${({ theme }) => theme.colors.uiColor5};
    box-shadow: ${({ theme }) => theme.colors.shadow1};
  } 
  ${({ theme }) => theme.media.small} {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.5rem;
}
  
`;

const ThanksTitle = styled.span<SubmitProps>`
  display: ${({ enabled }) => enabled ? 'block' : 'none'};
  font-weight: 500;
  font-size: 2.5rem;
  color: white;
  margin-left: 3.75rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
  width: 29rem;
  
  color: ${({ theme }) => theme.colors.uiColor7};
  ${({ theme }) => theme.media.small} {
    font-size: 1.75rem;
    width: 16rem;
    text-align: center;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 2rem;
}
`;





const Signup = styled.div`
   
  `;

interface Props {
    enabled: boolean;

}

interface State {
    email: string;
    submitted: boolean;
}



export default class SignupComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        
        this.state = {
            email: '',
            submitted: false,
        }
    }


    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.currentTarget.value;
        this.setState({
            email: query
        })
        
    }

    handleSubmit = () => {
        if(this.state.email.length > 0){
            const urlstring = 'https://rest.pancake-express.com/newsletter?email=' + this.state.email
            fetch(urlstring, {
            method: 'POST',
        });
        this.setState({
            submitted: true
        })
        }
    }

    handleKeyDown = (e: React.KeyboardEvent) => {
        if( e.key === 'Enter'){
            if(this.state.email.length > 0){
                const urlstring = 'https://rest.pancake-express.com/newsletter?email=' + this.state.email
                fetch(urlstring, {
                method: 'POST',
            });
            this.setState({
                submitted: true
            })
            }
        }
    }

    render () {
        return (
            <Signup>
                <ThanksTitle enabled={this.state.submitted}>Thanks for signing up!</ThanksTitle>
                <SignupContainer enabled={this.state.submitted}>
                <SearchContainer>
                    <SearchInput
                        dark = {this.props.enabled}
                        onChange={this.handleChange}
                        placeholder='Your Email'
                        onKeyDown={this.handleKeyDown}
                    />
                </SearchContainer>
                <SignupButton onClick={this.handleSubmit}>Signup</SignupButton>
                </SignupContainer>
                

            </Signup>
        )
}
}