import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import hamburgerLight from '../animations/white.json';
import { preferredNetwork } from '../../constants/network';
import * as walletActions from '../../store/wallet/actions';
import lottie from 'lottie-web/build/player/lottie_light';
import { ReactComponent as MetaMask } from '../svg/metamask.svg';
import { ReactComponent as WalletConnect } from '../svg/walletconnect.svg';
import { ReactComponent as Logo } from '../svg/mobilelogo.svg';
import Tabs from './tabs';

const HeaderContainer = styled.div`
    height: ${({ theme }) => theme.layout.headerHeight};
    width: 100%;
    max-width: min(1400px, 100vw);
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.uiColor5};
    padding: 1.5rem 5rem;
    font-size: 1.5rem;
    transition: all 0.45s ${({ theme }) => theme.transitions.main};
    margin-top: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ theme }) => theme.media.small} {
        padding: 0 1rem;
        height: 8.375rem;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;

    ${({ theme }) => theme.media.small} {
        width: 100%;
        justify-content: space-between;
        display: none;
    }
`;

const MobileButtonContainer = styled.div`
    display: none;
    align-items: flex-end;
    height: 100%;
    white-space: nowrap;
    flex-direction: row-reverse;
    margin-bottom: 2rem;

    ${({ theme }) => theme.media.small} {
        width: 100%;
        justify-content: space-between;
        display: flex;
        
    }
`;

const MobileMoonButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:active {
        transform: translateY(2px);
    }
    width: 2.8rem;
    height: 2.8rem;
    color:  ${({ theme }) => theme.colors.brandColor1};
    border-radius: 3.75rem;
    border: 1px solid ${({ theme }) => theme.colors.uiColor2};
    transition: all 0.2s ${({ theme }) => theme.transitions.main};

    & svg {
        width: 1rem;
        height: 1rem;
    }

    ${({ theme }) => theme.media.smallUp} {
        padding: 0 1rem;
        display: none;
    }

    ${({ theme }) => theme.media.extrasmall} {
        height: 3.5rem;
        width: 3.5rem;
    }


`;

interface ButtonProps {
    mainButton?: boolean;
}

const NavButton = styled.div<ButtonProps>`
    margin: 0 0.25rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.1rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;


    & svg  {
        margin-right: 0.625rem;
        color: ${({ theme }) => theme.colors.brandColor1};
        //filter: drop-shadow(2px 2px 0px black);
    }
    ${({ mainButton, theme }) => mainButton && `
        border: 1px solid ${theme.colors.uiColor2};
        width: 13.75rem;
        height: 2.8rem;
    `}

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.uiColor5};
    }

    &:active {
        transform: translateY(2px);
    }

    & svg {
        width: 1.1rem;
        height: 1.1rem;
    }

    transition: all 0.2s ${({ theme }) => theme.transitions.main};
`;

const LeftSideHeader = styled.div`
    display: flex;
    align-items: center;
    width: calc(100% - 14rem);
    max-width: 60vw;
    ${({ theme }) => theme.media.small} {
        display: none;
    }
`;

const AnimationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
   
    width: 1rem;
    height: 1rem;
    ${({ theme }) => theme.media.extrasmall} {
        width: 1.5rem;
        height: 1.5rem;
    }
    
`;

const RightSide = styled.div`
    display: flex;
    width: min-content;
    
`;

const dispatchProps = {
    connectWallet: walletActions.connectWallet.request,
    disconnectWallet: walletActions.disconnectWallet.request,
    switchNetwork: walletActions.switchNetwork.request,
};

type Props = {
    className?: string;
    enabled: boolean;
    handleNavigation: () => void;
    handleModal: () => void;
} & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Header: React.FC<Props> = (props) => {
    const { className, address, enabled, handleNavigation, handleModal, connectWallet, switchNetwork, networkType, provider } = props;
    const [open, setOpen] = useState(false);
    const animationContainer = useRef(null);
    const anim = useRef(null);

    useEffect(() => {
        if (animationContainer.current) {
            //@ts-ignore
            anim.current = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: 'svg',
                loop: false,
                autoplay: false,
                animationData: hamburgerLight,

            });
            //@ts-ignore
            return () => anim.current?.destroy();
        }
    }, []);

    useEffect(() => {
        //@ts-ignore
        anim.current?.setDirection(enabled ? 1 : -1);
        //@ts-ignore<
        anim.current?.play();
    }, [enabled]);

    const getAccountString = (account: string) => {
        const first = account.slice(0, 4);
        const second = account.slice(-4);

        return `${first}...${second}`;
    }

    const handleConnect = (disconnect?: boolean) => {
        if (disconnect) {
            if (networkType !== preferredNetwork.networkType) {
                switchNetwork();
            } else {
                handleModal()
            }
        } else {
            connectWallet();
        }
    }

    return (
        <HeaderContainer className={className}>
            <LeftSideHeader>
                <Tabs />
            </LeftSideHeader>
            <ButtonContainer>
                <RightSide>
                    <NavButton
                        mainButton
                        onClick={() => handleConnect(!!address)}
                    >
                        {!!address && (
                            provider === 'injected'
                                ?
                                <MetaMask></MetaMask>
                                :
                                <WalletConnect></WalletConnect>
                        )
                        }

                        {!address ? 'Connect Wallet' : networkType !== preferredNetwork.networkType ? 'Switch Network' : getAccountString(address)}
                    </NavButton>
                </RightSide>
            </ButtonContainer>
            <MobileButtonContainer>
                <div>
                    <MobileMoonButton
                        onClick={() => {
                            setOpen(!open);
                            //@ts-ignore
                            anim.current?.setDirection(open ? -1 : 1);
                            //@ts-ignore
                            anim.current?.play();
                            handleNavigation()
                        }}>
                        <AnimationContainer ref={animationContainer} />
                    </MobileMoonButton>
                </div>
                <RightSide>
                    <Logo></Logo>
                </RightSide>
            </MobileButtonContainer>
        </HeaderContainer >
    )
}


const mapStateToProps = (state: RootState) => ({
    address: state.wallet.account,
    balance: state.wallet.balances.bnb,
    networkType: state.wallet.networkType,
    provider: state.wallet.provider,
});

export default connect(
    mapStateToProps,
    dispatchProps
)(Header);