import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { RootState } from 'typesafe-actions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import FlipMove from 'react-flip-move';

import Liquidity from './liquidity';
import Symbol from './symbol';
import Timestamp from './timestamp';
import MiniChart from './mini-chart';
import Indicators from './indicators';
import Malicious from './warning';
import * as interfaceActions from '../../store/interface/actions';
import Actions from './actions';

import { ReactComponent as Sort } from '../svg/sort.svg';
import { filterDashboard } from '../../selectors/dashboard-filters';
import { SortableColumns } from '../../store/interface/state';
import { PairInterface } from '../../types/pairs';


const TokenListContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 1rem;
    padding-left: 5rem;
    padding-right: 5rem;
    ${({ theme }) => theme.media.small} {
        padding-left: 0rem;
        padding-right: 0rem;
    }
`;

interface ItemProps {
    noMobile?: boolean;
    last?: boolean;
    sort?: boolean;
    reverse?: boolean;
}



const ActionsHeader = styled.div<ItemProps>`
    display: flex;
    //background-color:  ${({ theme }) => theme.colors.darkerBackgroundGray};
    color: ${({ theme }) => theme.colors.uiColor4};
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    white-space: nowrap;
    font-size: 0.625rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 500;
    text-transform: uppercase;

    ${({ theme }) => theme.media.small} {
        display: ${({ noMobile }) => noMobile ? 'none' : 'flex'};
    }
`;

const ActionsItem = styled.div<ItemProps>`
    display: flex;
    align-items: center
    justify-content: center;
    white-space: nowrap;
    font-size: 0.875rem;
    font-weight: 500;
    border-bottom: ${({ last }) => last ? '0px' : '1px'} solid ${({ theme }) => theme.colors.uiColor6};
    ${({ theme }) => theme.media.small} {
        display: ${({ noMobile }) => noMobile ? 'none' : 'flex'};
    }
    width: 100%;
`;

const HeaderItem = styled.div<ItemProps>`
    display: flex;
    color: ${({ theme }) => theme.colors.uiColor4};
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    white-space: nowrap;
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    & svg > path:nth-of-type(1) {
        fill: ${({ theme, sort, reverse }) => sort ? reverse ? theme.colors.uiColor5 : theme.colors.uiColor3 : theme.colors.uiColor3};
    }
    & svg > path:nth-of-type(2) {
        fill: ${({ theme, sort, reverse }) => sort ? reverse ? theme.colors.uiColor3 : theme.colors.uiColor5 : theme.colors.uiColor3};
    }
    & svg {
        margin-left: 3px;
        width: 0.625rem;
        height: 0.625rem;
        align-self: center;
        color: ${({ theme }) => theme.colors.uiColor3}
    }

    ${({ theme }) => theme.media.small} {
        display: ${({ noMobile }) => noMobile ? 'none' : 'flex'};
    }
`;

const TimeHeader = styled(HeaderItem) <ItemProps>`
    padding: 1rem;

    ${({ theme }) => theme.media.small} {
        padding-left: 0rem;
        padding-right: 0rem;
    }
`;

const TokenRow = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr 2fr 2fr 2fr 4fr 1.5fr 1fr 1.3fr;
    justify-items: start;

    ${({ theme }) => theme.media.small} {
        grid-template-columns: 1.5fr 3fr 1.5fr 3fr;
    }

    border-bottom: 1px solid ${({ theme }) => theme.colors.uiColor6};

    &:hover {
        & > * {
            background-color: transparent;
        }
    }
`;

const TokenRowLink = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr 2fr 2fr 2fr 4fr 1.5fr 1fr 1.3fr;
    cursor: pointer;

    ${({ theme }) => theme.media.small} {
        grid-template-columns: 1.5fr 3fr 1.5fr 3fr;
    }

    &:hover {
        & > * {
            background-color: ${({ theme }) => theme.colors.uiColor6};
        }
    }
`;

const TokenItem = styled.div<ItemProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    padding-top: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    padding-bottom: 1rem;
    border-bottom: ${({ last }) => last ? '0px' : '1px'} solid ${({ theme }) => theme.colors.uiColor6};
    ${({ theme }) => theme.media.small} {
        display: ${({ noMobile }) => noMobile ? 'none' : 'flex'};
    }
`;

const TimeItem = styled.div<ItemProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    white-space: nowrap;
    padding-top: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: ${({ last }) => last ? '0px' : '1px'} solid ${({ theme }) => theme.colors.uiColor6};
    ${({ theme }) => theme.media.small} {
        display: ${({ noMobile }) => noMobile ? 'none' : 'flex'};
        padding-left: 0rem;
        padding-right: 0rem;
    }
`;

const dispatchProps = {
    openPage: interfaceActions.openPage.request,
    setSorting: interfaceActions.setSorting,
};

type Props = {

} & ReturnType<typeof mapStateToProps> & typeof dispatchProps & RouteComponentProps;

interface State {

}

class TokenList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {

        }
    }

    handleSort = (column: SortableColumns) => {
        const { sorters } = this.props;
        this.props.setSorting({
            desc: sorters.column === column ? !sorters.desc : true,
            column
        });
    }

    handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, pair: PairInterface) => {
        if (e.button === 0 && e.buttons === 1) {
            e.preventDefault();
            e.stopPropagation();
            return;
        } else if (e.button === 2 && e.buttons === 2) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        const pathname = `/pair/${pair.address}`;
        if (e.metaKey || e.shiftKey || (e.button === 1 && e.buttons === 4)) {
            e.preventDefault();
            e.stopPropagation();
            this.props.openPage(pathname);
        } else {
            this.props.history.push({
                pathname,
                state: { prevPath: '/' }
            });
        }
    }

    render() {
        const { dashboardPairs, searchParameter, sorters } = this.props;
        const loading = dashboardPairs.length === 0;
        return (
            <TokenListContainer key={searchParameter}>
                <TokenRow>
                    <TimeHeader onClick={() => this.handleSort('time')} sort={sorters.column === 'time'} reverse={!sorters.desc} last={loading}>
                        <span>Time</span>
                        <Sort />
                    </TimeHeader>
                    <HeaderItem onClick={() => this.handleSort('symbol')} sort={sorters.column === 'symbol'} reverse={!sorters.desc} last={loading}>
                        <span>Symbol</span>
                        <Sort />
                    </HeaderItem>
                    <HeaderItem onClick={() => this.handleSort('liquidity')} sort={sorters.column === 'liquidity'} reverse={!sorters.desc} last={loading}>
                        <span>Liquidity</span>
                        <Sort />
                    </HeaderItem>
                    <HeaderItem onClick={() => this.handleSort('swaps')} sort={sorters.column === 'swaps'} reverse={!sorters.desc} last={loading} noMobile>
                        <span>Buys / Sells</span>
                        <Sort />
                    </HeaderItem>
                    <HeaderItem onClick={() => this.handleSort('holders')} sort={sorters.column === 'holders'} reverse={!sorters.desc} last={loading} noMobile>
                        <span>Holders</span>
                        <Sort />
                    </HeaderItem>
                    <HeaderItem onClick={() => this.handleSort('priceChange')} sort={sorters.column === 'priceChange'} reverse={!sorters.desc} last={loading}>
                        <span>Price Change</span>
                        <Sort />
                    </HeaderItem>
                    <HeaderItem last={loading} noMobile>
                        <span>Info</span>
                    </HeaderItem>
                    <HeaderItem last={loading} noMobile>
                        <span></span>
                    </HeaderItem>
                    <ActionsHeader last={loading} noMobile>
                        <span>Actions</span>
                    </ActionsHeader>
                </TokenRow>
                <FlipMove
                    //maintainContainerHeight={true}
                    staggerDurationBy="5"
                    duration={250}
                    appearAnimation="fade"
                    enterAnimation="fade"
                    leaveAnimation="fade"
                >
                    {
                        dashboardPairs.map((pair, i) => {
                            const last = i === dashboardPairs.length - 1;
                            const address = pair.address;
                            const { holders } = pair.indicators;
/*                             const { indicators, token } = pair;
                            const { contractAnalysis } = indicators;
                            if (contractAnalysis && contractAnalysis.length > 0) {
                                console.log(token.symbol, contractAnalysis);
                            } */
                            return (
                                <TokenRowLink
                                    key={address}
                                    onClick={(e) => this.handleClick(e, pair)}
                                    onMouseDown={(e) => this.handleClick(e, pair)}
                                >
                                    <TimeItem last={last}>
                                        <Timestamp timestamp={pair.timestamp} />
                                    </TimeItem>
                                    <TokenItem last={last}>
                                        <Symbol
                                            symbol={pair.token.symbol}
                                            tokenAddress={pair.token.address}
                                        />
                                    </TokenItem>
                                    <TokenItem last={last}>
                                        <Liquidity
                                            initialLiquidity={pair.initialReserves[pair.isToken0 ? 'reserve1' : 'reserve0']}
                                            baseSymbol={pair.baseToken.symbol}
                                        />
                                    </TokenItem>
                                    <TokenItem last={last} noMobile>
                                        {pair.indicators.buys} / {pair.indicators.sells}
                                    </TokenItem>
                                    <TokenItem last={last} noMobile>
                                        {
                                            holders ? (
                                                <div>
                                                    <span>
                                                        {`${holders.growth >= 0 ? '+' : '-'}${Math.abs(holders.growth)}`}
                                                    </span>
                                                </div>
                                            ) : (
                                                <span>
                                                    -
                                                </span>
                                            )
                                        }
                                    </TokenItem>
                                    <TokenItem last={last}>
                                        <MiniChart
                                            priceChanges={pair.data.priceChanges}
                                            isRed={pair.indicators.isRug || pair.indicators.priceChange < 1}
                                            priceChange={pair.indicators.priceChange}
                                            isRug={pair.indicators.isRug}
                                        />
                                    </TokenItem>
                                    <TokenItem last={last} noMobile>
                                        <Indicators
                                            contractVerified={!!pair.indicators.contractVerified}
                                            locked={pair.indicators.locked}
                                            taxable={!!pair.indicators.taxable}
                                            socials={!!pair.token.socials}
                                        />
                                    </TokenItem>
                                    <TokenItem last={last} noMobile>
                                        <Malicious
                                            contractAnalysis={pair.indicators.contractAnalysis}
                                        />
                                    </TokenItem>
                                    <ActionsItem last={last} noMobile>
                                        <Actions
                                            address={pair.address}
                                            tokenAddress={pair.token.address}
                                        />
                                    </ActionsItem>
                                </TokenRowLink>
                            );
                        })
                    }
                </FlipMove>
            </TokenListContainer>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    const { dashboardPairs } = state.pairs;
    const { filters, sorters, BNBPrice, hiddenPairs } = state.interface;
    return {
        dashboardPairs: filterDashboard(dashboardPairs, filters, sorters, hiddenPairs, BNBPrice),
        searchParameter: filters.searchParameter,
        sorters,
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(withRouter(TokenList));