import { AbiItem } from 'web3-utils';

import { SmartContract } from '../../types/contract';

const abi: AbiItem[] = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "token",
				"type": "address"
			}
		],
		"name": "simulateSwaps",
		"outputs": [
			{
				"internalType": "uint256[20]",
				"name": "numbers",
				"type": "uint256[20]"
			},
			{
				"internalType": "bool[10]",
				"name": "status",
				"type": "bool[10]"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
]

export const ApeSimulator: SmartContract = {
    address: '0x7a387FcA39D5f9DdDDa15cfc7b11b49b72E3d4f4',
    abi,
	events: {} 
}

export default ApeSimulator;