import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { Provider } from 'react-redux';
import { Store } from 'typesafe-actions';
import initStore from './store';

import { GlobalStyle } from './styles/global';
import { theme } from './styles/theme';

import getWallet, { Wallet } from './utils/wallet';
import { setWallet, setNetworkType, getBalance } from './store/wallet/actions';

import FrontPage from './pages/index';
import DisclaimerPage from './pages/disclaimer';
import AnalyzePage from './pages/analyze';
import NotFoundPage from './pages/404';
import PairPage from './pages/pair'
import history from './utils/history';


import { sendMessage } from './store/socket/actions';

interface Props {

}

interface State {
    address: string;
}

export default class App extends React.Component<Props, State> {
    private wallet: Wallet;
    private store: Store;
    constructor(props: Props) {
        super(props);

        this.state = {
            address: '',
        }

        this.store = initStore();
        this.wallet = getWallet();
        this.subscribeToWallet();
    }

    subscribeToWallet = async () => {
        this.wallet.subscribeNetworkType(this.handleNetworkType);
        Promise.race([
            this.wallet.subscribeAddress(this.handleWallet),
            new Promise<void>((_, reject) => setTimeout(reject, 1500)),
        ]).catch(() => {
            console.debug(`Susbcribe to wallet timed out`);
            this.handleWallet('', '')
        })
    }

    handleWallet = (address: string, provider: string) => {
        this.store.dispatch(setWallet.request({ address, provider }));
        this.store.dispatch(getBalance.request());
        this.store.dispatch(sendMessage.request([{ type: 'account', data: address }]));
    }

    handleNetworkType = (networkType: string) => {
        this.store.dispatch(setNetworkType(networkType))
    }

    render() {

        return (
            <Provider store={this.store}>
                <ThemeProvider theme={theme} >
                    <GlobalStyle />
                    <Router history={history}>
                        <Switch>
                            <Route path="/pair/:address">
                                <PairPage />
                            </Route>
                            <Route path="/analyze/:address">
                                <AnalyzePage />
                            </Route>
                            <Route path="/analyze">
                                <AnalyzePage />
                            </Route>
                            <Route path="/disclaimer">
                                <DisclaimerPage />
                            </Route>
                            <Route path="/" exact>
                                <FrontPage />
                            </Route>
                            <Route>
                                <NotFoundPage />
                            </Route>
                        </Switch>
                    </Router>
                </ThemeProvider>
            </Provider>
        );
    }
}